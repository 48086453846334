import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_white.svg";
import * as routes from "../../constants/routes";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4" xs={12} sm={4} md={4}>
            <div style={{ marginTop: 24, marginBottom: 16 }}>
              <Link to="/">
                <img
                  src={logo}
                  className="app-logo-header"
                  alt="okie weed finder"
                  draggable="false"
                />
              </Link>
            </div>
            <p>
              Discovering Oklahoma's premier marijuana dispensaries, doctors,
              processors, and growers has never been easier with our reliable
              network of top-rated establishments.
            </p>
          </div>
          <div className="col-md-2" xs={12} sm={4} md={2}>
            <h3>Follow Us</h3>
            <a href="https://www.facebook.com/OkieWeedFinder" target="new">
              <p>Facebook</p>
            </a>
            <a
              href="https://www.youtube.com/channel/UCZUyC8TnDlOdAU-ba1XIq6w"
              target="new"
            >
              <p>YouTube</p>
            </a>
          </div>
          <div className="col-md-2" xs={12} sm={4} md={2}>
            <h3>Search</h3>
            <Link to="/dispensaries">
              <p>Dispensaries</p>
            </Link>
            {/* <Link to="/doctors">
              <p>Doctors</p>
            </Link>
            <Link to="/processors">
              <p>Processors</p>
            </Link>
            <Link to="/growers">
              <p>Growers</p>
            </Link>
            <Link to="/testing-labs">
              <p>Testing Labs</p>
            </Link> */}
          </div>
          <div className="col-md-2" xs={12} sm={4} md={2}>
            <h3>News</h3>
            <Link to="/blog">
              <p>Blog</p>
            </Link>
            <Link to="/careers">
              <p>Careers</p>
            </Link>
            <Link to="/legal/about">
              <p>About</p>
            </Link>
          </div>
          <div className="col-md-2" xs={12} sm={4} md={2}>
            <h3>Support</h3>
            <a href="mailto:assist@okieweedfinder.com">
              <p>Email</p>
            </a>
            <a href="/contact">
              <p>Contact</p>
            </a>
            <p>Call: (580) 678-5654</p>
          </div>
        </div>
        <div>
          <p>
            © 2021 Okie Weed Finder All Rights Reserved &middot;{" "}
            <Link to={routes.TERMS} style={{ color: "#32bf25" }}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link to={routes.PRIVACY} style={{ color: "#32bf25" }}>
              Privacy Policy
            </Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
