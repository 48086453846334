import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUserProfileProps } from '../../actions/profile';
import moment from "moment";
import Layout from "../layout/Layout";
import _ from "lodash";
import "../../styles/App.css";
import { Grid, Col, Button } from "react-bootstrap";
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom'

var order_status_type = [
  { type: 'Select Status', product_code: 'DEFAULT' },
  { type: 'Completed', product_code: 'com' },
  { type: 'Pending', product_code: 'pen' },
  { type: 'Ready for pick up', product_code: 'rp' },
  { type: 'Cancelled', product_code: 'cd' },
];

class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productCategories: order_status_type,
      type: '',
      patientUID: '',
      error: ''
    };
  }

  // Product Type
  onCategoryChange = (e) => {
    const type = e.target.value;
    this.setState(() => ({ type }));
  };
  
  onPatientDisplayNameChange = (patientUID) => {
    this.setState(() => ({ patientUID }));
    this.onSubmit()
  };

  
  onSubmit = () => {

    this.setState(() => ({ error: '' }))

    let updates ={
      order_status: this.state.type,
    }

    const id = this.props.match.params.id
    const { uid } = this.props.user
    const patientUID = this.state.patientUID

    firebase.database().ref(`users/${patientUID}/orders/${id}`).update(updates)
    firebase.database().ref(`users/${uid}/orders/${id}`).update(updates)
  }


  render() {
    const { orders, admin } = this.props.user;
    const id = this.props.match.params.id
    const ordersArr = _.toArray(orders);
    const order_item = ordersArr.find(order_item => order_item.id === id);
    const orderObj = _.toPlainObject(order_item);
    const { displayName, createdAt, registered_patient, subtotal, order, order_status, photoURL, uid} = orderObj
    const patientUID = uid;
    

    var status_color = (order_status) => {
      switch (order_status) {
        case 'Completed':
          return '#4CAF50';
        case 'Pending':
          return '#2196F3';
        case 'Ready for pick up':
          return '#4CAF50';
        case 'Cancelled':
          return '#ff1744';
        default:
          return '#212121' 
        }
      }
   
    return (
      <Layout>
        <Grid fluid>
          <div style={{marginTop: 90, minHeight: 800}}>
            <Col md={3}></Col>
            <Col md={6} sm={12} xs={12}>
              
                  <div className="list-card-style">
                    <div className="list-card" style={{flexDirection: 'row'}}>

                       <div style={{display: 'flex', alignItems: 'center', width: 300}}>
                            <div className="list-profile-avatar-wrapper">
                              <Link to={`/u/${displayName}`}>
                                <img src={photoURL} className="list-profile-avatar" alt="profile img" />
                              </Link>
                            </div>
                            
                            <div style={{width: 130, padding: 4}}>
                              <Link to={`/u/${displayName}`}>
                                <p style={{fontSize: 14, fontWeight: '900'}}>{displayName}</p> 
                              </Link>
                              {/* <p><i className="fas fa-check nav-bar-icon"></i> <span>{registered_patient ? 'Registered' : 'Not Registered'}</span></p> */}
                            </div>
                          </div>


                      <div style={{width: '100%'}}>
                        <div style={{padding: 4}}>
                          <p style={{fontSize: 14, fontWeight: '900'}}>Date</p>
                          <p className="timestamp">{moment(createdAt).format("MMM Do YY")}</p>
                        </div>
                      </div>

                      <div style={{width: '100%'}}>
                        <div style={{padding: 4}}>
                          <p style={{fontSize: 14, fontWeight: '900'}}>Quantity</p>
                          <p><i className="fas fa-shopping-bag nav-bar-icon"></i> {_.toArray(order).length}</p>
                        </div>
                      </div>

                      <div style={{width: '100%'}}>
                        <div style={{padding: 4}}>
                          <p style={{fontSize: 14, fontWeight: '900'}}>Subtotal</p>
                          <p><i className="fas fa-dollar-sign nav-bar-icon"></i> {subtotal}</p>
                        </div>
                      </div>
                      
                    </div>

                    
                    <div style={{flexDirection: 'row'}}>
                      {_.toArray(order).map(o => (
                        <div className="list-card" style={{flexDirection: 'row'}}>
                          
                          <div className="list-profile-avatar-wrapper">
                            <img src={o.img_url} className="list-profile-avatar" alt="profile img" />
                          </div>  
                          

                          <div style={{width: '100%', padding: 4}}>
                            <div>
                              <p style={{fontSize: 16, fontWeight: '900'}}>{o.productName}</p>
                              <p style={{fontSize: 12 }}>{o.productCategories[0]}</p>
                            </div>
                          </div>

                          <div style={{width: '100%', padding: 4}}>
                            <div>
                              <p style={{fontSize: 16, fontWeight: '900'}}></p>
                              <p style={{fontSize: 12 }}>{o.description}</p>
                            </div>
                          </div>

                          <div style={{width: '100%', padding: 4}}>
                            <div>
                              <p style={{fontSize: 16, fontWeight: '900'}}>{o.quantity}</p>
                              <p style={{fontSize: 12 }}>{o.description}</p>
                            </div>
                          </div>

                          <div style={{width: '100%', padding: 4}}>
                            <div>
                              <p style={{fontSize: 12}}><i className="fas fa-dollar-sign nav-bar-icon"></i> {o.price}</p>
                            </div>
                          </div>

                        </div>
                      ))}
                    </div>

                </div>

            </Col>

            <Col md={3}>
              <div className="list-card-style" style={{padding: 16}} onSubmit={this.onSubmit}>
                <p>Status</p>
                <p style={{fontSize:18, fontWeight: '500', color: status_color(order_status)}}>{order_status} </p>
              </div>
              <div className="list-card-style">
                <div style={{padding: 16}}>
                  <div style={{width: '100%'}}>
                    <div style={{padding: 4}}>
                      <p><span style={{fontSize: 14, fontWeight: '900'}}>Subtotal</span></p>
                      <p><i className="fas fa-dollar-sign nav-bar-icon"></i> {subtotal}</p>
                    </div>
                  </div>

                  {admin ? (
                    <div>
                    <div className="input-group" style={{marginTop: 12}}>
                      <div className="form-group">
                        <select className="select-css" value={this.state.type} onChange={this.onCategoryChange}>
                          {this.state.productCategories.map(item => (
                            <option key={item.product_code} value={item.type}>
                            {item.type}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
  
                    <div>
                      <button className="btn btn-primary" onClick={() => this.onPatientDisplayNameChange(patientUID)}>Update Order</button>
                    </div>
                    </div>
                  ) : (
                    <div/>
                  )}

                </div>
              </div>

            </Col>

          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  profile: state.profile,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  fetchUserProfileProps: () => dispatch(fetchUserProfileProps())
});



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetail);
