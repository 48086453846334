import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
// import { getAuth } from 'firebase/auth'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const prodConfig = {
  apiKey: "AIzaSyBTZoxaTi6C79jabHWO4SygJd8CVu3tQ5k",
  authDomain: "okieweedfinder-060786.firebaseapp.com",
  databaseURL: "https://okieweedfinder-060786.firebaseio.com",
  projectId: "okieweedfinder-060786",
  storageBucket: "okieweedfinder-060786.appspot.com",
  messagingSenderId: "156702163770",
  appId: "1:156702163770:web:fa3c711b32cdf5001c4603",
  measurementId: "G-Q2381SPJ8G"
};

const devConfig = {
  apiKey: "AIzaSyBTZoxaTi6C79jabHWO4SygJd8CVu3tQ5k",
  authDomain: "okieweedfinder-060786.firebaseapp.com",
  databaseURL: "https://okieweedfinder-060786.firebaseio.com",
  projectId: "okieweedfinder-060786",
  storageBucket: "okieweedfinder-060786.appspot.com",
  messagingSenderId: "156702163770",
  appId: "1:156702163770:web:fa3c711b32cdf5001c4603",
  measurementId: "G-Q2381SPJ8G"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  // const app = initializeApp(config);
  firebase.initializeApp(config);
}

// const app = initializeApp(config);
// const dbstore = getFirestore(config);
const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();


export {
  db,
  auth,
  storage,
  firebase,
  googleAuthProvider,
  // dbstore,
  database as default 
};



// import * as firebase from 'firebase';

// const config = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DATABASE_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
// };

// firebase.initializeApp(config);

// const database = firebase.database();
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// export { firebase, googleAuthProvider, database as default };
