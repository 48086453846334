import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import { auth } from "../../firebase";

const styles = {
  sidebar: {
    width: 256,
    height: "100%",
    zIndex: 100,
  },
  sidebarLink: {
    display: "block",
    padding: "16px 0px",
    color: "#757575",
    textDecoration: "none",
  },
  divider: {
    margin: "8px 0",
    height: 2,
    backgroundColor: "#d7d7d7",
  },
  content: {
    // padding: "16px",
    height: "100%",
    // backgroundColor: "white"
  },
};

const SidebarContent = (props) => {
  const style = props.style
    ? { ...styles.sidebar, ...props.style }
    : styles.sidebar;

  return (
    <>
      <div style={styles.content}>
        {props.authUser ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={props.user.photoURL}
                className="mobile-nav-profile-img"
                alt="user avatar"
              />
            </div>
            <div>
              <span style={styles.sidebarLink}>
                <a
                  href={`/dispensaries/${props.user.city}/${props.user.slug}`}
                  style={{ fontWeight: "bold", marginLeft: 8 }}
                >
                  {props.user.store_name
                    ? props.user.store_name
                    : props.user.displayName}
                </a>
              </span>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.DASHBOARD} style={{ fontWeight: "bold" }}>
            {" "}
            <i className="fas fa-bars"></i> Dashboard
          </Link>
        </span>

        {/* <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.TIMELINE} style={{ fontWeight: "bold" }}>
            <i className="fas fa-home"></i> Homefeed
          </Link>
        </span> */}

        {props.authUser ? (
          <div>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={routes.REVIEWS} style={{ fontWeight: "bold" }}>
                <i className="fas fa-comment-alt"></i> Reviews
              </Link>
            </span>
          </div>
        ) : (
          <div></div>
        )}

        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.EXPLORE} style={{ fontWeight: "bold" }}>
            <i className="fas fa-search" style={{ marginRight: "6px" }}></i>
            Explore
          </Link>
        </span>

        {props.user.package_level === "business" ? (
          <>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link
                to={`/dispensaries/${props.user.city}/${props.user.slug}`}
                style={{ fontWeight: "bold" }}
              >
                <i className="fas fa-prescription-bottle-alt"></i> Store
              </Link>
            </span>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={routes.PRODUCT_NEW} style={{ fontWeight: "bold" }}>
                <i className="fas fa-plus-circle"></i> Post deal
              </Link>
            </span>
          </>
        ) : (
          <></>
        )}

        {props.user.package_level === "premium" ? (
          <>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link
                to={`/dispensaries/${props.user.city}/${props.user.slug}`}
                style={{ fontWeight: "bold" }}
              >
                <i className="fas fa-prescription-bottle-alt"></i> Storefront
              </Link>
            </span>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={routes.PRODUCT_NEW} style={{ fontWeight: "bold" }}>
                <i className="fas fa-plus-circle"></i> Post deal
              </Link>
            </span>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={routes.NOTIFICATION_NEW} style={{ fontWeight: "bold" }}>
                <i className="fas fa-plus-circle"></i> Send push notification
              </Link>
            </span>
            {/* <span style={styles.sidebarLink}>
                <Link to={routes.ROOM} style={{fontWeight: 'bold'}}><i className="fas fa-plus-circle"></i> Start Live Stream</Link>
              </span> */}
          </>
        ) : (
          <></>
        )}

        {props.user.superadmin ? (
          <>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={routes.BUSINESS_NEW} style={{ fontWeight: "bold" }}>
                <i className="fas fa-plus-circle"></i> Add business
              </Link>
            </span>
          </>
        ) : (
          <></>
        )}

        {props.authUser ? (
          <div>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link
                to={routes.DISPENSARIES_DASHBOARD}
                style={{ fontWeight: "bold" }}
              >
                <i className="fas fa-address-card"></i> Dispensaries
              </Link>
            </span>
          </div>
        ) : (
          <div>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={routes.DISPENSARIES} style={{ fontWeight: "bold" }}>
                <i className="fas fa-address-card"></i> Dispensaries
              </Link>
            </span>
          </div>
        )}

        {/* <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.DOCTORS} style={{ fontWeight: "bold" }}>
            <i className="fas fa-address-card"></i> Doctors
          </Link>
        </span>
        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.PROCESSORS} style={{ fontWeight: "bold" }}>
            <i className="fas fa-address-card"></i> Processors
          </Link>
        </span>
        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.GROWERS} style={{ fontWeight: "bold" }}>
            <i className="fas fa-address-card"></i> Growers
          </Link>
        </span>
        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.TESTING_LABS} style={{ fontWeight: "bold" }}>
            <i className="fas fa-address-card"></i> Testing Labs
          </Link>
        </span>
        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.BLOG} style={{ fontWeight: "bold" }}>
            <i className="fas fa-bullhorn"></i> Blog
          </Link>
        </span> */}
        {props.authUser ? (
          <div>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={routes.ACCOUNT} style={{ fontWeight: "bold" }}>
                <i className="fas fa-cogs"></i> Account settings
              </Link>
            </span>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link onClick={auth.doSignOut} style={{ fontWeight: "bold" }}>
                Sign Out
              </Link>
            </span>
          </div>
        ) : (
          <div></div>
        )}
        <div style={styles.divider} />
        <h3>Follow Us</h3>
        <span style={styles.sidebarLink}>
          <a href="https://www.facebook.com/OkieWeedFinder" target="new">
            <p style={{ fontWeight: "bold" }}>Facebook</p>
          </a>
          <a
            href="https://www.youtube.com/channel/UCZUyC8TnDlOdAU-ba1XIq6w"
            target="new"
          >
            <p style={{ fontWeight: "bold" }}>YouTube</p>
          </a>
        </span>
        <div style={styles.divider} />
        <h3>Support</h3>
        <span style={styles.sidebarLink}>
          <a href="mailto:assist@okieweedfinder.com">
            <p style={{ fontWeight: "bold" }}>Email</p>
          </a>
          <span style={styles.sidebarLink} style={{ fontWeight: "bold" }}>
            Call: (580) 678-5654
          </span>
        </span>
      </div>
    </>
  );
};

SidebarContent.propTypes = {
  style: PropTypes.object,
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  userProps: state.userProps,
  user: state.user,
});

export default connect(mapStateToProps, undefined)(SidebarContent);
