import React, { useState, Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Helmet } from "react-helmet";
import HeroHeader from "./HeroHeader";
import FeaturedProfiles from "./FeaturedProfiles";
import firebase from "firebase/compat/app";
import FullWidthCTA from "./FullWidthCTA";
// import firebase from "firebase";
import "../../styles/App.css";
import Layout from "../layout/Layout";
import { Modal, Button } from "react-bootstrap";
import logo from "../../assets/images/over_age_img@2x.png";
import MidNavBar from "./MidNavBar";
import DownloadAppFullWidthCTA from "./DownloadAppFullWidthCTA";
import Footer from "../layout/Footer";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>
      Okie Weed Finder - Connect With The Medical Cannabis Community
    </title>
    <link rel="canonical" href="https://okieweedfinder.com/" />
  </Helmet>
);

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      landingData: {},
      isChecked: false,
      show: false,
    };
  }
  state = {
    isLoading: false,
    landingData: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.setState({
      show: !localStorage.checkbox,
    });
    if (this.mounted) {
      // this.props.fetchRecentNews();
      firebase
        .database()
        .ref(`/landing`)
        .once("value")
        .then((snap) => {
          if (snap.val()) {
            this.setState({ isEmpty: false });

            this.setState({
              landingData: snap.val(),
            });
          } else {
            this.setState({ isEmpty: true });
          }
          this.setState({ isLoading: false });
        });
    }
  }

  handleClose = () => {
    this.setState({
      show: false,
    });

    const { show } = this.state;
    if (show !== "") {
      localStorage.checkbox = show;
    }
  };

  handleShow = () => {
    this.setState({
      isChecked: !false,
      show: !false,
    });
  };

  onChangeCheckbox = (event) => {
    this.setState({
      isChecked: event.target.checked,
    });
  };

  loginSubmit = () => {
    const { show } = this.state;
    if (show !== "") {
      localStorage.checkbox = show;
    }
  };

  render() {
    const { featured_profiles, news } = this.state.landingData;
    const { show } = this.state;
    return (
      <div>
        <div style={{ backgroundColor: "#000000" }}>
          <HeaderTags />
          <HeroHeader />
          <div>
            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              animation={false}
              backdrop={"static"}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ padding: 12 }}>
                  <img style={{ width: "100%" }} src={logo} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingBottom: 16,
                }}
              >
                <div>
                  <span>
                    By clicking Enter you verify that you are 21 years of age or
                    older.
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingBottom: 32,
                }}
              >
                <div style={{ marginLeft: 8, borderColor: "#212121" }}>
                  <span onClick={this.handleClose}>
                    <div>
                      <span>
                        <form>
                          <span>
                            <input
                              type="checkbox"
                              checked={show}
                              name="lsRememberMe"
                              onChange={this.onChangeCheckbox}
                            />
                          </span>
                          <span
                            style={{ marginLeft: 8, borderColor: "#212121" }}
                          >
                            <strong>Yes, I'm over 21 years old.</strong>
                          </span>
                        </form>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingBottom: 32,
                }}
              >
                <div>
                  <span onClick={this.handleShow}>
                    <Button variant="primary">Close</Button>
                  </span>
                </div>
              </div>
            </Modal>
          </div>
          {/* <MidNavBar /> */}
          {/* <FeaturedProfiles {...featured_profiles} /> */}
          <DownloadAppFullWidthCTA />
          {/* <RecentArticles {...this.props.recent_news} /> */}
          {/* <FullWidthCTA /> */}
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  recent_news: state.recent_news,
});

export default compose(connect(mapStateToProps, undefined))(LandingPage);
