import database from "../firebase/firebase";
import _ from 'lodash';

export const SET_CITIES = "SET_CITIES";

// SET LISTS
export const setCities = (cities) => ({
  type: SET_CITIES,
  cities
});

export const startSetCity = (user) => {
  return (dispatch, getState) => {
    // const user = getState().auth.user;
    return database.ref(`/cities`).once("value").then(snapshot => {
      let cities = snapshot.val();
      dispatch(setCities(cities));
    })
  };
}
