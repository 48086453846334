import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import Layout from "../layout/Layout";
import _ from "lodash";
import ProfileSidebar from "./ProfileSidebar";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { fetchProducts } from "../../services/shelf/actions";
import { fetchReviews } from "../../actions/reviews";
import { startAddReview } from "../../actions/reviews";
import LoadingPage from "../utilities/LoadingPage";
import ProductList from "../Shelf/ProductList";
import "../Shelf/style.scss";
import ReviewsView from "../reviews/ReviewsView";
import { Button, Modal } from "react-bootstrap";
import sign_up_img_modal from "../../assets/images/sign_up_modal_img@2x.png";
import * as routes from "../../constants/routes";
import ReviewForm from "../reviews/ReviewForm";

class PublicProfile extends Component {
  static propTypes = {
    fetchProducts: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    filters: PropTypes.array,
    sort: PropTypes.string,
  };

  state = {
    isLoading: false,
    profileData: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      profileData: {},
      profileProducts: {},
      show: false,
      profileDataListing: [],
    };
    this.handleShowReviewForm = this.handleShowReviewForm.bind(this);
    this.handleHideReviewForm = this.handleHideReviewForm.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  getBusinessProfile = async () => {
    const slug = this.props.match.params.slug;
    try {
      const citiesRef = firebase.firestore().collection("dispensariesDB");
      const snapshot = await citiesRef.where("slug", "==", slug).get();

      let profiles = [];

      snapshot.forEach((doc) => {
        let dispoData = doc.data();
        profiles.push(dispoData);
      });
      this.setState({ profileData: profiles[0] });
      this.getBusinessProducts(profiles[0].uid);
    } catch (error) {
      console.log(error, "error");
    }
  };

  getBusinessProducts = async (uid) => {
    try {
      const db = firebase.firestore().collection("usersDB");
      let productArr = await db.doc(`${uid}`).collection("product").get();

      let productsArrayTemp = [];

      productArr.forEach((doc) => {
        let dispoData = doc.data();
        productsArrayTemp.push(dispoData);
      });

      this.setState({ profileProducts: productsArrayTemp });
      this.setState({ isLoading: false });
    } catch (error) {
      console.log(error, "error");
    }
  };

  componentDidMount() {
    const slug = this.props.match.params.slug;
    // const city = this.props.match.params.city;
    this.mounted = true;

    if (this.mounted) {
      this.getBusinessProfile();
      this.props.fetchReviews(slug);
    }
  }

  handleShowReviewForm() {
    this.setState({ show: true });
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleHideReviewForm() {
    this.setState({ show: false });
  }
  handleHide() {
    this.setState({ show: false });
  }
  onSubmit = (reviewBody) => {
    this.props.startAddReview(reviewBody);
  };

  // componentWillReceiveProps(nextProps) {
  //   const { filters: nextFilters, sort: nextSort } = nextProps;
  //   const { filters } = this.props;

  //   if (nextFilters.length !== filters.length) {
  //     this.handleFetchProducts(nextFilters, undefined);
  //     this.setState({ isLoading: false });
  //   }

  //   if (nextSort !== this.props.sort) {
  //     this.handleFetchProducts(undefined, nextSort);
  //     this.setState({ isLoading: false });
  //   }
  // }

  // handleFetchProducts = (
  //   filters = this.props.filters,
  //   sort = this.props.sort
  // ) => {
  //   this.setState({ isLoading: true });
  //   // const slug = this.props.match.params.uid;
  //   // const city = this.props.match.params.id;
  //   // const { products } = _.toPlainObject(this.state.profileData);

  //   // this.props.fetchProducts(filters, sort, products, () => {
  //   //   this.setState({ isLoading: false });
  //   // });
  // };

  render() {
    const slug = this.props.match.params.uid;
    const city = this.props.match.params.id;
    let objectState = this.state.profileData;
    const {
      displayName = "not set",
      type,
      state,
      uid,
      package_level,
      store_name,
    } = _.toPlainObject(this.state.profileData);

    const products = _.toPlainObject(this.state.profileProducts);
    // const { products } = this.props

    var filteredProduts = _.map(products, function (o) {
      if (o.hidden === false) return o;
    });

    filteredProduts = _.without(filteredProduts, undefined);

    const { isLoading } = this.state;
    let calcMargin = this.props.authUser ? 30 : 80;
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {" "}
            {/* {`${store_name} ${type} in ${this.state.profileData.city}`} */}
          </title>
        </Helmet>

        <div className="container-fluid" style={{ marginTop: calcMargin }}>
          <div className="app-wrapper">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <ProfileSidebar profileData={this.state.profileData} />
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12">
              <div>
                {package_level === "free" ? (
                  <div className="col-md-2 col-sm-12 col-xs-12"></div>
                ) : (
                  <div className="col-md-2 col-sm-12 col-xs-12">
                    {/* <Filter/> */}
                  </div>
                )}

                <div className="col-md-8 col-sm-12 col-xs-12">
                  {this.props.authUser ? (
                    <div>
                      <Button
                        className="btn-lg add-new-post-btn"
                        onClick={this.handleShow}
                        style={{ width: "100%", marginBottom: 8 }}
                      >
                        <p style={{ color: "#FFFFFF" }}>
                          <i
                            className="fas fa-pencil-alt"
                            style={{ marginRight: "6px" }}
                          ></i>
                          <strong>Write a review</strong>
                        </p>
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        className="btn-lg add-new-post-btn"
                        onClick={this.handleShow}
                        style={{ width: "100%", marginBottom: 8 }}
                      >
                        <p style={{ color: "#FFFFFF" }}>
                          <i
                            className="fas fa-pencil-alt"
                            style={{ marginRight: "6px" }}
                          ></i>
                          <strong>Write a review</strong>
                        </p>
                      </Button>
                    </div>
                  )}

                  {package_level === "free" ? (
                    <div style={{ marginBottom: 32 }}>
                      <span class="badge badge-secondary">
                        <p>
                          No products to display{" "}
                          <span role="img" aria-label="sad emoji">
                            🍁
                          </span>
                        </p>
                      </span>
                      <ReviewsView
                        profileData={_.toPlainObject(this.state.profileData)}
                      />
                    </div>
                  ) : (
                    <div style={{ marginTop: 20 }}>
                      {_.toArray(this.state.profileProducts).length === 0 ? (
                        <></>
                      ) : (
                        <React.Fragment>
                          {isLoading && <LoadingPage />}

                          <div className="shelf-container">
                            <ProductList products={filteredProduts} />
                          </div>
                        </React.Fragment>
                      )}
                      <ReviewsView
                        profileData={_.toPlainObject(this.state.profileData)}
                      />
                    </div>
                  )}
                </div>

                {/* <div className="col-md-2 col-sm-12 col-xs-12"></div> */}
              </div>
            </div>

            {/* {this.props.authUser ? (
                  <FloatCart dispensaryProps={objectState}/>
                  ) : (
                  <div/>
                )} */}
          </div>
        </div>

        {this.props.authUser ? (
          <div>
            <Modal
              {...this.props}
              show={this.state.show}
              onHide={this.handleHideReviewForm}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                  <h3>{displayName} Review</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
                <ReviewForm
                  reviewBody={this.props.reviewBody}
                  onSubmit={(reviewBody) => {
                    this.props.startAddReview(slug, reviewBody);
                  }}
                />
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <div>
            <Modal
              {...this.props}
              show={this.state.show}
              onHide={this.handleHide}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg"></Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
                <img src={sign_up_img_modal} style={{ width: "100%" }} />
                <Link to={routes.SIGN_UP}>
                  <Button
                    className="btn-lg add-new-post-btn"
                    onClick={this.handleShow}
                    style={{ width: "100%", marginBottom: 8 }}
                  >
                    <p style={{ color: "#FFFFFF" }}>
                      <strong>Sign Up</strong>
                    </p>
                  </Button>
                </Link>
                <Link to={routes.SIGN_IN}>
                  <Button
                    className="btn-lg transparent"
                    onClick={this.handleShow}
                    style={{ width: "100%", marginBottom: 8 }}
                  >
                    <div>
                      <strong>Sign in</strong>
                    </div>
                  </Button>
                </Link>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
  products: state.shelf.products,
  filters: state.filters.items,
  sort: state.sort.type,
});

const mapDispatchToProps = (dispatch, props) => ({
  startAddReview: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
  fetchProducts: (filters, sort, products) =>
    dispatch(fetchProducts(filters, sort, products)),
  fetchReviews: (data) => dispatch(fetchReviews(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PublicProfile
);
// export default compose(connect(mapStateToProps, {startAddReview, fetchProducts, fetchReviews }))(PublicProfile);
