import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash'
import { addProduct } from '../../../../services/cart/actions';
import { Link } from 'react-router-dom'
import  defaultPhotoURL from '../../../../assets/images/default_avatar.jpg';

const Product = ({ product, addProduct }) => {
  product.quantity = 1;
  const { city, slug, id, img_url, productName, productThc, productCbd, currencyFormat, priceRange, type, hidden } = product;
  
  const productImage = img_url ? (
    <div style={{ height: 200, overflow: 'hidden', borderTopRightRadius: 22, borderTopLeftRadius: 22,}}>
      <img src={img_url} alt="product" className="card-img-container" />
    </div>
    ) : (
    <div style={{ height: 200, overflow: 'hidden', borderTopRightRadius: 22, borderTopLeftRadius: 22,}}>
      <img src={defaultPhotoURL} alt="product" className="card-img-container" />
    </div>
  )
  return (
  <Link to={`/dispensaries/${city}/${slug}/products/${id}`}>
    <div className="list-card-style">

        <div>

          {productImage}

        </div>

        <div className="list-card" style={{flexDirection: 'row', minHeight: 120}}>
          <div style={{width: '100%'}}>
            <div style={{padding: 4}}>
              {hidden ? (
                  <>
                    <strong style={{fontSize: 12, color: 'red' }}>HIDDEN</strong>
                  </>
                ) : (<></>) }
              <p style={{fontSize: 18, fontWeight: '900'}}>{productName}</p>
              <p style={{fontSize: 12, lineHeight: 1.5}}>
                {productThc ? (
                  <>
                    <strong style={{color: '#999999', fontWeight: 900}}>THC </strong> 
                    <strong style={{ fontWeight: 900}}>{productThc}</strong>  
                  </>
                ) : (<></>)}

                {productCbd ? (
                  <>
                    <strong style={{color: '#aca3a3'}}> | CBD </strong> 
                    <strong style={{ fontWeight: 900 }}>{productCbd}</strong>
                  </>
                ) : (<></>) }
                
                
              </p>
              <div>
                <div className="val">
                  <p style={{ fontSize: 12, fontWeight: 900 }}> {currencyFormat} {priceRange}</p>
                </div>
              </div>

              <div className="badge badge-secondary" style={{marginTop: 8}}>
                <p style={{fontSize: 12, fontWeight: 'bold'}}>#{_.toUpper(type)}</p>
              </div>
            </div>
          </div>
        </div>

    </div>
  </Link>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired
};

export default connect(
  null,
  { addProduct }
)(Product);
