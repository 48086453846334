import React, {Component} from "react";
import { Grid, Col, FormGroup, FormControl, Button } from "react-bootstrap";
import UserCard from "./UserCard";
import Layout from "../layout/Layout";
import firebase from 'firebase/compat/app';
import _ from 'lodash';

class SearchUser extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName: '',
      user_search: {},
    }
  }
  
  componentWillUnmount(){
    firebase.database().ref("users").off()
  }

  findUser = () => {
    const displayName = this.state.displayName;

    if (displayName){
      firebase.database().ref().child("users").orderByChild("username").startAt(displayName).on("value", (snapshot) => {
        if (snapshot.exists()) {
            let user_search = snapshot.val()
            this.setState({user_search})
            this.setState({ isEmpty: false });
          }else{
            this.setState({usernameError: 'Username is available!'})
          }
        });
    }
  }


  onDisplayNameChange = (event) => {
    const displayName = event.target.value.toLowerCase().trim()
    displayName.replace(/\s/g, '')
    this.setState({displayName})
    if(displayName === ''){
      this.setState({user_search: {}})
    }
  }

  render(){
    const user_search_arr = _.toArray(this.state.user_search)
    return(
        <>
          <Col md={12} sm={12} xs={12} className="no-margin">
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div style={{marginTop: 12}}>
                <FormGroup>
                  <FormControl
                    style={{width: 200, backgroundColor: '#1f212e'}}
                    type="text"
                    placeholder={'Search users'}
                    value={this.state.displayName}
                    onChange={this.onDisplayNameChange}
                    onKeyUp={this.findUser}
                  />
                </FormGroup>
              </div>
            </div>
            <div style={{position: 'absolute',width: '100%', backgroundColor: '#FFFFFF', borderRadius: 12, zIndex: 3}}>
              {user_search_arr.map(list => {
                return <UserCard key={list.id} {...list} />;
              })}
            </div>
          </Col>
        </>
    )
  }
}

export default SearchUser;
