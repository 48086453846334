import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, FormControl } from "react-bootstrap";
import { setTextFilter } from '../../actions/filtersText';

class DispensariesListFilters extends Component {
  render(){
    return (
      <div>
        <FormGroup bsSize="large">
        <FormControl
          type="text"
          placeholder={'Fliter results by name'}
          style={{backgroundColor: '#FFFFFF'}} 
          value={this.props.filtersText.text}
          onChange={(e) => {
           this.props.dispatch(setTextFilter(e.target.value));
          }} />
          </FormGroup>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filtersText: state.filtersText
  }
}

export default connect(mapStateToProps)(DispensariesListFilters);

