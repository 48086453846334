import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Col, FormGroup, FormControl, Button } from "react-bootstrap";
import { compose } from 'recompose';
import ListIndexFilters from "./ListIndexFilters";
import { startSetDispensaries } from  '../../actions/dispensaries'
import itemSelector from "./../../selectors/selector";
import GoogleMap from 'google-map-react';
import _ from 'lodash'
import Layout from '../layout/Layout';
import MapSmallCard from './MapSmallCard';
import LoadingPage from '../utilities/LoadingPage';
import Navigation from "../layout/Navigation";
import SidebarContentAuth from '../dashboard/SidebarContentAuth';

const K_HOVER_DISTANCE = 100 /2;

var cities = [
  { city_name: 'Select city' },
  { city_name: 'Admore' },
  { city_name: 'Arapaho' },
  { city_name: 'Arcadia' },
  { city_name: 'Arkadelphia' },
  { city_name: 'Arkoma' },
  { city_name: 'Bartlesville' },
  { city_name: 'Bentonville' },
  { city_name: 'Bethany' },
  { city_name: 'Blanchard' },
  { city_name: 'Broken Arrow' },
  { city_name: 'Broken Bow' },
  { city_name: 'Calera' },
  { city_name: 'Claremore' },
  { city_name: 'Carnegie' },
  { city_name: 'Cartwright' },
  { city_name: 'Chandler' },
  { city_name: 'Chickasha' },
  { city_name: 'Noble' },
  { city_name: 'Choctaw' },
  { city_name: 'Claremore' },
  { city_name: 'Clarksville' },
  { city_name: 'Cookson' },
  { city_name: 'Cordell' },
  { city_name: 'Coyle' },
  { city_name: 'Del City' },
  { city_name: 'Duncan' },
  { city_name: 'Edmond' },
  { city_name: 'El Reno' },
  { city_name: 'Elk city' },
  { city_name: 'Enid' },
  { city_name: 'Goldsby' },
  { city_name: 'Grove' },
  { city_name: 'Harrah' },
  { city_name: 'Henryetta' },
  { city_name: 'Holdenville' },
  { city_name: 'Hot Springs' },
  { city_name: 'Hugo' },
  { city_name: 'Hydro' },
  { city_name: 'Jenks' },
  { city_name: 'Lawton' },
  { city_name: 'Lexington' },
  { city_name: 'Lone Grove' },
  { city_name: 'Marlow' },
  { city_name: 'McAlester' },
  { city_name: 'Mead' },
  { city_name: 'Meeker' },
  { city_name: 'Midwest City' },
  { city_name: 'Mustang' },
  { city_name: 'Minco' },
  { city_name: 'Monkey island'},
  { city_name: 'Moore'},
  { city_name: 'Muskogee' },
  { city_name: 'Newcastle' },
  { city_name: 'Norman' },
  { city_name: 'Nicoma Park' },
  { city_name: 'Oklahoma City', center:{ lat: 35.4825666, lng: -97.6196207} },
  { city_name: 'Okmulgee' },
  { city_name: 'Owasso' },
  { city_name: 'Pauls Valley' },
  { city_name: 'Pawhuska' },
  { city_name: 'Piedmont' },
  { city_name: 'Poteau' },
  { city_name: 'Prague' },
  { city_name: 'Pryor' },
  { city_name: 'Purcell' },
  { city_name: 'Roland' },
  { city_name: 'Russellville' },
  { city_name: 'Sallisaw' },
  { city_name: 'Sand Springs' },
  { city_name: 'Sapulpa' },
  { city_name: 'Sayre' },
  { city_name: 'Seminole' },
  { city_name: 'Shawnee' },
  { city_name: 'Skiatook' },
  { city_name: 'Slaughterville' },
  { city_name: 'Stillwater' },
  { city_name: 'Tahlequah' },
  { city_name: 'Tecumseh' },
  { city_name: 'The Village' },
  { city_name: 'Tulsa' },
  { city_name: 'Watts' },
  { city_name: 'Warr Acres' },
  { city_name: 'Weatherford' },
  { city_name: 'Wellston' },
  { city_name: 'Wewoka' },
  { city_name: 'Woodward' },
  { city_name: 'Yukon' }
];

class DispensaryDashboard extends Component {
  constructor(props){
    super(props)
    this.state = {
      position: {},
      displayName: '',
      cities: cities,
      city_name: '',
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      center: null
    }
  }

  componentDidMount(){
    this.getLocation()
    const {city} = this.props.user
    const cityOption = city ? city : 'Tulsa'
    this.props.startSetDispensaries(cityOption)
  }

  static defaultProps = {
    zoom: 12,
  }


  _onBoundsChange = (center, zoom, bounds, marginBounds) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({center, zoom, bounds, marginBounds});
    } else {
      this._onChange(center);
    }
  }

  _onChange({center, zoom, bounds, ...other}) {    
       this.setState({zoom: zoom});
  }

  onSearchInputChange = (e) => {
    const displayName = e.target.value;
    this.setState(() => ({ displayName }));
  };

  onStateChange = (e, center) => {
    const city_name = e.target.value;
    this.setState(() => ({ city_name, center }));
    this.props.startSetDispensaries(city_name)
  };

  searchDispensaries = () => {
    const cityName = this.state.city_name
    this.props.startSetDispensaries(cityName)
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.setState({center: pos})
      });
    } else { 
      this.setState({err: "Geolocation is not supported by this browser."}) 
    }
  }

  render(){
      const dispensariesArr = _.reverse(this.props.dispensaries)
      const places = dispensariesArr
      .map(place => {
        const { id } = place;
        return (
            <MapSmallCard
              key={id}
              text={place}
              hover={this.props.hoverKey === id}
              lat={place.latitude}
              lng={place.longitude}
              yesIWantToUseGoogleMapApiInternals
            />
        );
      });

      return(
        <Layout>
          <Grid fluid style={{height: '100vh'}}>
        <div>
          <Col md={2} lg={2} smHidden xsHidden style={{marginTop: 32}}>
            <SidebarContentAuth/>
          </Col>
          <Col md={10} sm={12} xs={12}>
              <Col md={6} className="no-margin">
                <div style={{ height: '100vh', width: '100%', marginBottom: 16 }}>
                  { dispensariesArr.length === 0 ? (
                    <LoadingPage/>
                  ) : (
                    <GoogleMap
                      bootstrapURLKeys={{ key: 'AIzaSyCxbdS9sY_vALdSVincQAxA7gOPqr6XHLs' }}
                      defaultCenter={this.state.center}
                      center={this.state.center}
                      zoom={this.props.zoom}
                      onBoundsChange={this._onBoundsChange}
                      hoverDistance={K_HOVER_DISTANCE}
                    >
                      {places}
                    </GoogleMap>
                )}
                </div>
              </Col>

              <Col md={6} style={{ height: '100%', overflow: 'hidden', marginTop: 24 }}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 8}}>
                <div style={{width: '80%'}}>
                  <div class="input-group input-group-lg  dropdown-menu-right">
                      <div className="select-container">
                        <select className="select-css" value={this.state.city_name} onChange={this.onStateChange}>
                          {this.state.cities.map(item => (
                            <option key={item.state_code} value={item.city_name} >
                            {item.city_name}
                            </option>
                          ))}
                        </select>
                      </div>
                  </div>
                </div>
                <div style={{justifyContent: 'flex-end'}}>
                  <h3 style={{fontWeight: 'bold', fontSize: 14, textTransform: 'uppercase', lineHeight: 0.5}}>{dispensariesArr.length} Results</h3>
                </div>
              </div>

              <div style={{ width: '100%'}}>
                <ListIndexFilters />
              </div>

              <div style={{ width: '100%', height: '100%' }}>
              <div style={{ width: '100%', height: '100%', overflow: 'scroll',  marginBottom: 80 }}>

              {
                dispensariesArr.length === 0 ? (
                  <LoadingPage/>
                ) : (
                  dispensariesArr.map((profile) => (
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <Link to={`/dispensaries/${profile.city}/${profile.slug}/`}>
                        <div className="list-card-style">
                        
                        <div style={{maxHeight: 280, overflow: 'hidden', borderRadiusTopleft: 16, borderRadiusTopRight: 16, position: 'relative'}}>

                          <div style={{borderRadius: 16, overflow: 'hidden'}}>
                            <img src={profile.photoURL} alt="profile img" width={'100%'} />
                          </div>

                        </div>
                          
                        <div className="list-card" style={{flexDirection: 'row'}}>
                          <div style={{width: '100%', minHeight: 70}}>
                            <div style={{padding: 4}}>
                              <div className="flex-parent">
                                <p style={{fontSize: 14, fontWeight: '900'}} className="long-and-truncated">{profile.displayName}</p>
                              </div>
                              <div className="flex-parent">
                                <p className="long-and-truncated"> {profile.address}</p>
                              </div>
                              <p><i className="fas fa-map-marker map-city-icon"></i> {profile.city}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </Link>
                  </div>
                  )
                )
              )
            }
            
            </div>
            </div>

          </Col>            
        
        </Col>
      </div>
      </Grid>
      </Layout>
    )
  }
};

const mapStateToProps = state => {
  return {
    user: state.user,
    userProps: state.userProps, 
    dispensaries: itemSelector(state.dispensaries, state.filtersText),
  };
};

export default compose(connect(mapStateToProps, { startSetDispensaries }))(DispensaryDashboard);
