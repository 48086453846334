import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import { connect } from 'react-redux';
import { loadCart, removeProduct, changeProductQuantity } from '../../services/cart/actions';
import { updateCart } from '../../services/total/actions';
import CartProduct from './CartProduct';
import { Alert, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import './style.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import no_items_img from '../../assets/images/no_item_in_cart@2x.png'

class FloatCart extends Component {
  static propTypes = {
    loadCart: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    cartProducts: PropTypes.array.isRequired,
    newProduct: PropTypes.object,
    removeProduct: PropTypes.func,
    productToRemove: PropTypes.object,
    changeProductQuantity: PropTypes.func,
    productToChange: PropTypes.object,
  };

  state = {
    isOpen: false,
    show: false,
    setShow: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.newProduct !== this.props.newProduct) {
      this.addProduct(nextProps.newProduct);
    }

    if (nextProps.productToRemove !== this.props.productToRemove) {
      this.removeProduct(nextProps.productToRemove);
    }

    if (nextProps.productToChange !== this.props.productToChange) {
      this.changeProductQuantity(nextProps.productToChange);
    }
  }

  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
  };

  addProduct = product => {
    const { cartProducts, updateCart } = this.props;
    let productAlreadyInCart = false;

    cartProducts.forEach(cp => {
      if (cp.id === product.id) {
        cp.quantity += product.quantity;
        productAlreadyInCart = true;
      }
    });

    if (!productAlreadyInCart) {
      cartProducts.push(product);
    }

    updateCart(cartProducts);
    this.openFloatCart();
  };

  removeProduct = product => {
    const { cartProducts, updateCart } = this.props;

    const index = cartProducts.findIndex(p => p.id === product.id);
    if (index >= 0) {
      cartProducts.splice(index, 1);
      updateCart(cartProducts);
    }
  };

  removeProductsCart = product => {
    const { cartProducts, updateCart } = this.props;

    cartProducts.splice(0, cartProducts.length);
    updateCart(cartProducts);
  };

  proceedToCheckout = () => {

    let { cartProducts } = this.props;
    const { registered_patient, verified, slug}  = this.props.user;
    const newOrderKey = firebase.database().ref().push().key;
    const initialTime = moment()
    const {uid, displayName, photoURL} = this.props.dispensaryProps
    // const dispensaryPropsUID = this.props.profile.uid
    // const dispensaryPropsDisplayName = this.props.profile.displayName
    // const dispensaryPropsPhotoURL = this.props.profile.photoURL

    const {
      totalPrice,
      productQuantity,
      currencyFormat,
      currencyId
    } = this.props.cartTotal;


    const patientOrderObj = {
      createdAt: initialTime.valueOf(),
      displayName,
      id: newOrderKey,
      order: cartProducts,
      order_status: 'pending',
      photoURL,
      registered_patient,
      subtotal: totalPrice.toFixed(2),
      uid,
      totalPrice: totalPrice.toFixed(2),
      verified,
      productQuantity,
      currencyFormat,
      currencyId
    };

    const orderObj = {
      createdAt: initialTime.valueOf(),
      displayName: this.props.user.displayName,
      id: newOrderKey,
      order: cartProducts,
      order_status: 'pending',
      photoURL: this.props.user.photoURL,
      registered_patient,
      subtotal: totalPrice.toFixed(2),
      uid: this.props.user.uid,
      totalPrice: totalPrice.toFixed(2),
      verified,
      productQuantity,
      currencyFormat,
      currencyId
    };

    let updates = {}

    cartProducts = null;

    if (!productQuantity) {
      toast("Add some product in the cart!");
    } else {

      updates[`users/${uid}/orders/${newOrderKey}`] = orderObj
      updates[`users/${this.props.user.uid}/orders/${newOrderKey}`] = patientOrderObj
      firebase.database().ref().update(updates)
      toast(`Order Places - Subtotal: ${currencyFormat} ${totalPrice.toFixed(2), currencyId, cartProducts}`);
      this.removeProductsCart()

    }
  
  };

  changeProductQuantity = changedProduct => {
    const { cartProducts, updateCart } = this.props;

    const product = cartProducts.find(p => p.id === changedProduct.id);
    product.quantity = changedProduct.quantity;
    if (product.quantity <= 0) {
      this.removeProduct(product);
    }
    updateCart(cartProducts);
  }

  render() {
    const { cartTotal, cartProducts, removeProduct, changeProductQuantity } = this.props;
    const { totalPrice } = cartTotal;

    const products = cartProducts.map(p => {
      return (
        <CartProduct product={p} removeProduct={removeProduct} changeProductQuantity={changeProductQuantity} key={p.id} />
      );
    });

    let classes = ['float-cart'];

    if (!!this.state.isOpen) {
      classes.push('float-cart--open');
    }

    return (
      <div className={classes.join(' ')}>
        {/* If cart open, show close (x) button */}
        {this.state.isOpen && (
          <div
            onClick={() => this.closeFloatCart()}
            className="float-cart__close-btn"
          >
            X
          </div>
        )}

        {/* If cart is closed, show bag with quantity of product and open cart action */}
        {!this.state.isOpen && (
          <span
            onClick={() => this.openFloatCart()}
            className="bag bag--float-cart-closed"
          >
            <span className="bag__quantity">{cartTotal.productQuantity}</span>
          </span>
        )}

        <div className="float-cart__content">
          <div className="float-cart__header">
            <span className="bag">
              <span className="bag__quantity">{cartTotal.productQuantity}</span>
            </span>
            <span className="header-title">Shopping Bag</span>
          </div>

          <div className="float-cart__shelf-container">
            {products}
            {!products.length && (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '6%', marginTop: 32, marginBottom: 32}}>
                <div>
                  <img style={{width: 300}} src={no_items_img} alt="default state"/>
                </div>
                <div style={{marginTop: 24}}>
                  <p style={{fontWeight: 600}}>No orders yet...<span role="img" aria-label="sad emoji">😒</span></p>
                </div>
                </div>
            )}
          </div>
            
          <div className="float-cart__footer">
            <div className="sub">SUBTOTAL</div>
            <div className="sub-price">
              <p className="sub-price__val">
                {`${cartTotal.currencyFormat} `}
                {`${totalPrice ? totalPrice : 0.00} `}
                {`${cartTotal.currencyId}`}
              </p>
            </div>
            <div onClick={() => this.proceedToCheckout()} className="buy-btn">
              Checkout
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  productToChange: state.cart.productToChange,
  cartTotal: state.total.data
});

export default connect(
  mapStateToProps,
  { loadCart, updateCart, removeProduct, changeProductQuantity }
)(FloatCart);
