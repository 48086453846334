import React from "react";
import { connect } from "react-redux";
import ProductForm from "./ProductForm";
import { startAddProduct } from "../../actions/products";
import Header from "./../layout/Header";
import Layout from "../layout/Layout";
import { Grid } from "react-bootstrap";

export class AddProductView extends React.Component {
  onSubmit = (lesson) => {
    this.props.startAddProduct(lesson);
    this.props.history.push("/dashboard");
  };
  render() {
    return (
      <Layout>
        <Grid>
          <div style={{ marginTop: 80, marginBottom: 80 }}>
            <ProductForm onSubmit={this.onSubmit} />
          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddProduct: (lesson) => dispatch(startAddProduct(lesson)),
});

export default connect(undefined, mapDispatchToProps)(AddProductView);
