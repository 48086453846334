import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from 'recompose';
import ListIndexFilters from "./ListIndexFilters";
import { startSetBusiness } from  '../../actions/doctors'
import { startSetCity } from  '../../actions/cities'
import _ from 'lodash'
import itemSelector from "./../../selectors/selector";
import GoogleMap from 'google-map-react';
import Footer from "../layout/Footer";
import { Col } from 'react-bootstrap';
import MapSmallCard from './MapSmallCard';
import LoadingPage from '../utilities/LoadingPage';
import Navigation from "../layout/Navigation";
import {Helmet} from "react-helmet";
import  defaultPhotoURL from '../../assets/images/default_avatar.jpg'; 

const K_HOVER_DISTANCE = 100 /2;

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Doctors - Okie Weed Finder</title>
    <link rel="canonical" href="https://okieweedfinder.com/blog" />
  </Helmet>
)


class ExploreDoctors extends Component {
  constructor(props){
    super(props)
    this.state = {
      position: {},
      displayName: '',
      cities: this.props.cities,
      city_name: '',
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      center: null
    }
  }

  componentDidMount(){
    this.getLocation()
    const {city} = this.props.user
    const cityOption = city ? city : 'Tulsa'
    this.props.startSetBusiness(cityOption)
    this.props.startSetCity()
  }

  static defaultProps = {
    // center: [59.744465, 30.042834],
    zoom: 12,
  }


  _onBoundsChange = (center, zoom, bounds, marginBounds) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({center, zoom, bounds, marginBounds});
    } else {
      this._onChange(center);

    }
  }

  _onChange({center, zoom, bounds, ...other}) {

       this.setState({zoom: zoom});
    // });
  }


  onSearchInputChange = (e) => {
    const displayName = e.target.value;
    this.setState(() => ({ displayName }));
  };

  onStateChange = (e, center) => {
    const city_name = e.target.value;
    this.setState(() => ({ city_name, center }));
    this.props.startSetBusiness(city_name)
    
  };

  searchDispensaries = () => {
    const cityName = this.state.city_name
    this.props.startSetBusiness(cityName)
    console.log(this.props.doctors, "DANCING")
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(position, "Position")
        this.setState({center: pos})
      });
    } else { 
      this.setState({err: "Geolocation is not supported by this browser."}) 
    }
  }


  render(){

      const doctorssArr = this.props.doctors
      let select_key = _.toArray(this.props.cities)

      const places = doctorssArr
      .map(place => {
        const { id } = place;
        return (
            <MapSmallCard
              key={id}
              text={place}
              hover={this.props.hoverKey === id}
              lat={place.latitude}
              lng={place.longitude}
            />
        );
      });

      return(
        <div>
          <HeaderTags/>
          <div className="container-fluid">          
              <div style={{marginBottom: 60}}>
                <Navigation/>
              </div>
              <Col md={6} className="no-margin">
              <div style={{ height: '100vh', width: '100%', marginBottom: 16 }}>

                  { doctorssArr.length === 0 ? (
                    <LoadingPage/>
                  ) : (
                    <GoogleMap
                      bootstrapURLKeys={{ key: 'AIzaSyBTZoxaTi6C79jabHWO4SygJd8CVu3tQ5k' }}
                      defaultCenter={this.state.center}
                      center={this.state.center}
                      zoom={this.props.zoom}
                      onBoundsChange={this._onBoundsChange}
                      hoverDistance={K_HOVER_DISTANCE}
                    >
                      {places}
                    </GoogleMap>
                )}
                </div>
              </Col>

              <Col md={6} style={{ height: '100vh', overflow: 'hidden' }}>
              
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 8}}>
                <div style={{width: '80%'}}>
                  <div className="input-group input-group-lg  dropdown-menu-right">
                      <div className="select-container">
                        <select className="select-css" value={this.state.city_name} onChange={this.onStateChange}>
                          {select_key.map((item,index) => (
                            <option key={index} value={item.value} >
                              {item.city_name}
                            </option>
                          ))}
                        </select>
                      </div>
                  </div>
                </div>
                <div style={{justifyContent: 'flex-end'}}>
                  <h3 style={{fontWeight: 'bold', fontSize: 14, textTransform: 'uppercase', lineHeight: 0.5}}>{doctorssArr.length} Results</h3>
                </div>
              </div>

              <div style={{ width: '100%'}}>
                <ListIndexFilters />
              </div>

              <div style={{ width: '100%', height: '100vh' }}>
              <div style={{ width: '100%', height: '90%', overflow: 'scroll',  marginBottom: 80 }}>

              {
                doctorssArr.length === 0 ? (
                  <LoadingPage/>
                ) : (
                  doctorssArr.map((profile) => {
                    const profileImage = profile.photoURL ? (
                          <div style={{borderRadius: 16, overflow: 'hidden'}}>
                            <img src={profile.photoURL} alt="profile img" width={'100%'} />
                          </div>
                    ) : (
                          <div style={{borderRadius: 16, overflow: 'hidden'}}>
                            <img src={defaultPhotoURL} alt="profile img" width={'100%'} />
                          </div>
                    )
                    return(
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <Link to={`/doctors/${profile.city}/${profile.slug}/`}>
                        <div className="list-card-style">
                        
                        <div style={{maxHeight: 280, overflow: 'hidden', borderRadiusTopleft: 16, borderRadiusTopRight: 16, position: 'relative'}}>

                          {profileImage}

                        </div>
                          
                        <div className="list-card" style={{flexDirection: 'row'}}>
                          <div style={{width: '100%', minHeight: 70}}>
                            <div style={{padding: 4}}>
                              <div className="flex-parent">
                                <p style={{fontSize: 14, fontWeight: '900'}} className="long-and-truncated">{profile.displayName}</p>
                              </div>
                              <div className="flex-parent">
                                <p className="long-and-truncated"> {profile.address}</p>
                              </div>
                              <p><i className="fas fa-map-marker map-city-icon"></i> {profile.city}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </Link>
                  </div>
                  )}
                )
              )
            }
            
            </div>
            </div>

          </Col>            
        </div>
        <Footer/>
      </div>
    )
  }
};

const mapStateToProps = state => {
  return {
    user: state.user,
    userProps: state.userProps,
    cities: state.cities,
    doctors: itemSelector(state.doctors, state.filtersText),
  };
};

export default compose(connect(mapStateToProps, { startSetBusiness, startSetCity }))(ExploreDoctors);