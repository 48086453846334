import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Layout from '../layout/Layout';
import { PasswordForgetLink } from '../passwordForget';
import { auth } from '../../firebase';
import * as routes from '../../constants/routes';
import { Grid, Col, FormGroup, FormControl, Button, Row } from 'react-bootstrap';

const AccountSignup = () => 
  <Link to={routes.SIGN_UP}>
    <Col md={3}/>
    <Col md={6} className="no-margin">
      <div>
        <p>Don't have an account? Sign up here</p>
      </div>
    </Col>
    <Col md={3}/>
  </Link>


const SignInPage = ({ history }) =>
    <Layout>
      <Grid style={{marginTop: 90, height: '100vh'}}>
        <Row>
        <Col md={3}/>
        <Col md={6} className="list-card-style">
        <h3 className="login-text">Log into exisitng account</h3>
          <SignInForm history={history} />
          <div style={{marginBottom: 16}}>
            <PasswordForgetLink />
          </div>
        </Col>
        <Col md={3}/>
        </Row>
        <AccountSignup/>
      </Grid>
    </Layout>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(routes.TIMELINE);
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <FormGroup bsSize="large">
      <form onSubmit={this.onSubmit}>
        <FormGroup bsSize="large">
          <FormControl
            value={email}
            style={{backgroundColor: '#EEE'}}
            onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
            type="text"
            placeholder="Email Address"
        />
        </FormGroup>
        <FormGroup bsSize="large">
          <FormControl
            value={password}
            style={{backgroundColor: '#EEE'}}
            onChange={event => this.setState(updateByPropertyName('password', event.target.value))}
            type="password"
            placeholder="Password"
          />
        </FormGroup>
        <Button bsStyle="primary" bsSize="large" className="btn-blank btn-blue" disabled={isInvalid} type="submit">
          Sign In
        </Button>

        { error && <p>{error.message}</p> }
      </form>
      </FormGroup>
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};
 