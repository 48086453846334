import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import firebase from 'firebase/compat/app';
import FileUploader from "react-firebase-file-uploader";
import { Checkbox } from 'react-bootstrap';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NotificationForm extends React.Component {

    fileObj = [];
    fileArray = [];

  constructor(props) {
    super(props);

    this.state = {
      createdAt: props.product ? moment(props.product.createdAt) : moment(),
      displayName: this.props.user.displayName,
      city: this.props.user.city,
      photoURL: this.props.user.photoURL,
      store_name: this.props.user.store_name,
      type: props.product ? props.product.type : '',
      id: firebase.database().ref().push().key,
      uid: firebase.auth().currentUser.uid,
      img_url: '',
      post_body: '',
      file: [null],
      isUploading: false,
      uploadProgress: 0,
      error: ''
    };
  }

  // Product Name
  onPostBodyNameChange = (e) => {
    const post_body = e.target.value;
    this.setState(() => ({ post_body }));
  };
  
  
  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.post_body) {
      this.setState(() => ({ error: 'Please provide product name.' }));
    } else {
      this.setState(() => ({ error: '' }));
      this.props.onSubmit({
        id: this.state.id,
        displayName: this.props.user.displayName,
        post_body: this.state.post_body,
        img_url: this.state.img_url,
        createdAt: this.state.createdAt.valueOf(),
        uid: this.state.uid,
        photoURL: this.props.user.photoURL,
        store_name: this.state.store_name
      });
    }
    toast("Product successfully posted");
  };

// UPLOAD CODE

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });
 
  handleProgress = progress =>
    this.setState({
      uploadProgress: progress
    });
 
  handleUploadError = error => {
    this.setState({
      isUploading: false
      // Todo: handle error
    });
    console.error(error);
  };
 
  handleUploadSuccess = filename => {
    this.setState({ img_url: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref(`push_notifications/${this.state.uid}/${this.state.id}/`)
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ img_url: url }));
  };


  render() {
    return (
      <div className="container-fluid">
      <form onSubmit={this.onSubmit}>
        {this.state.error && <p className="form__error">{this.state.error}</p>}
        
        <div className="col-md-9">
        <div className="row">
        

        <div className="form-group">
          <div className='col-md-12 col-sm-12 col-xs-12'>
          <h4>Send Push Notification</h4>
          <br/>            

            <div>
              <div><h4>Notification Details</h4></div>
              <div><p>Keep it short and sweet!</p></div>
              <div className="new-post-cat-container">

              <div style={{ width: '100%'}}>
                <input
                  className="product_form_price_input"
                  type="text"
                  placeholder="Notification"
                  value={this.state.post_body}
                  onChange={this.onPostBodyNameChange} />
              </div>

              </div>
            </div>

            <div>
              <div><h4>Select Image</h4></div>
              <div><p>Upload a pretty picture</p></div>
              <div className="new-post-cat-container">
              <div className="new-post-cat-inner-wrapper">
              <FileUploader
                accept="image/*"
                name="image-uploader-multiple"
                storageRef={firebase.storage().ref(`push_notifications/${this.state.uid}/${this.state.id}/`)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
              <p>Progress: {this.state.uploadProgress}</p>
            </div>
            </div>
            </div>

            <div>
              <button type="button" onClick={this.onSubmit} className="btn btn-primary btn-lg">Send</button>
            </div>

          </div> 
        </div>

        </div>



        </div>
        
        <div className="col-md-3">
          <h4>Preview</h4>
          <div style={{marginTop: 16}}>
          <div className="list-card-style">

            <div style={{maxHeight: 180, overflow: 'hidden',   position: 'relative'}}>

              <div style={{  borderRadius: 16, minHeight: 220, overflow: 'hidden'}}>
                {this.state.img_url ? (
                  <img src={this.state.img_url} alt="product" style={{height: '100%', width: '100%' }} />
                  ) : ( <div></div>)}
              </div>

            </div>

            <div className="list-card" style={{flexDirection: 'row', minHeight: 120,}}>
              <div style={{}}>
                <div style={{padding: 4}}>
                  <p style={{fontSize: 12, fontWeight: 'bold'}}>#{_.toUpper(this.state.type)}</p>
                  <p style={{fontSize: 18, fontWeight: '900'}}>{this.state.post_body}</p>
                  <div>
                    <div className="val">
                      <p style={{fontSize: 12}}> {this.state.currencyFormat} {this.state.priceRange}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

        </div>

      </form>

      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, undefined)(NotificationForm);