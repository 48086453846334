import React from "react";

import NavigationTransparent from "../layout/NavigationTransparent";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import logo from "../../assets/images/logo_white.svg";
import { Grid, Col } from "react-bootstrap";
const HeroHeader = () => {
  return (
    <Grid
      fluid
      style={{ height: 990, marginBottom: 80, backgroundColor: "#000000" }}
      className="header-background"
    >
      <Col md={4} smHidden xsHidden>
        <div style={{ marginLeft: 120 }}>
          <div style={{ marginBottom: 120, width: "100%" }}>
            )
            <img src={logo} />
          </div>
          <h1 className="header-h1">
            It's all <br />
            homegrown{" "}
          </h1>
          <h2 className="header-h2">
            The ultimate platform for cannabis enthusiasts to connect and
            explore the world of medical marijuana.
          </h2>
          <p style={{ color: "#FFFFFF", fontSize: 18 }}>
            Personalized your medical cannabis journey.
          </p>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 16 }}>
            <div>
              <Link to={routes.DOWNLOAD}>
                <Button
                  className="btn-lg add-new-post-btn"
                  style={{ marginTop: 8, marginRight: 8 }}
                >
                  <p style={{ color: "#FFFFFF" }}>
                    {/* <i className="fas fa-search" style={{marginRight: "6px"}}></i> */}
                    <strong>Sign up</strong>
                  </p>
                </Button>
              </Link>
              <div style={{ marginTop: 18 }}>
                <Link to={routes.SIGN_IN}>
                  <strong style={{ color: "#BBBBBB" }}>Login</strong>
                </Link>
              </div>
            </div>
            <div>
              <Link to={routes.BUSINESS_LANDING}>
                <Button
                  className="btn-lg for-business-btn"
                  style={{ marginTop: 8, marginRight: 12 }}
                >
                  <p style={{ color: "#FFFFFF" }}>
                    <strong>🎤 For Business</strong>
                  </p>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Col>
      <Col md={9} sm={12}></Col>
      <Col sm={12} xs={12} mdHidden lgHidden>
        {/* <h2 className="header-mobile">#local #cannabis #deals </h2> */}
        {/* <h1 className="header-h1-mobile">Discover Oklahoma's <br/> Top Medical Marijuana <br/> Deals near you</h1> */}
      </Col>
    </Grid>
  );
};

export default HeroHeader;
