import React from "react";
import landing_image from "../../assets/images/owf_landing_2@2x.png";
import { Grid, Col, Button } from "react-bootstrap";
import Layout from "../layout/Layout";
import { Helmet } from "react-helmet";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Contact | Okie Weed Finder</title>
    <link rel="canonical" href="https://okieweedfinder.com/" />
  </Helmet>
);

const ContactHeader = () => {
  return (
    <Grid style={{ marginTop: 80 }}>
      <Col md={6} smHidden xsHidden>
        <h2 className="header-h2" style={{ color: "#212121" }}>
          #cannabis #sales #tools{" "}
        </h2>
        <h1 className="header-h1" style={{ color: "#212121" }}>
          Got any questions?
        </h1>
        <h1 className="header-h2" style={{ color: "#212121" }}>
          Contact the okieweedfinder team! We are spread all over Oklahoma! All
          local.
        </h1>
        <img src={landing_image} className="header-img" draggable="false" />
      </Col>
      <Col md={6} sm={12}>
        <div>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSe06kWx8Rfi5spUsIAlGbdhTT86-nEtxVBvEP6TD3_Fu4Rrag/viewform?embedded=true"
            width="640"
            height="979"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
      </Col>
      <Col sm={12} xs={12} mdHidden lgHidden>
        <h2 className="header-mobile">#local #cannabis #deals </h2>
      </Col>
    </Grid>
  );
};

const BusinessLanding = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <HeaderTags />
      <div style={{ marginTop: 80, height: "100vh" }}>
        <ContactHeader />
      </div>
    </Layout>
  );
};
export default BusinessLanding;
