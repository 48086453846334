import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import { compose } from "recompose";
import ListIndexFilters from "./ListIndexFilters";
import { startSetDispensaries } from "../../actions/dispensaries";
import itemSelector from "./../../selectors/selector";
import _ from "lodash";
import { Col } from "react-bootstrap";
import LoadingPage from "../utilities/LoadingPage";
import defaultPhotoURL from "../../assets/images/default_avatar.jpg";

class DealsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {},
      displayName: "",
      city_name: "",
      user_search: null,
      zoomToMarkers: null,
      products: [],
      zoom: null,
      center: null,
      loaded: false,
    };
  }

  getBusinessProducts = async () => {
    try {
      const { uid } = this.props.user;
      const db = firebase.firestore().collection("usersDB");
      let productArr = await db.doc(`${uid}`).collection("product").get();

      let productsArrayTemp = [];

      productArr.forEach((doc) => {
        let dispoData = doc.data();
        productsArrayTemp.push(dispoData);
      });

      this.setState({ products: productsArrayTemp, loaded: true });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    const { city } = this.props.user;
    const cityOption = city ? city : "Tulsa";
    this.props.startSetDispensaries(cityOption);
    this.getBusinessProducts();
  }

  onSearchInputChange = (e) => {
    const displayName = e.target.value;
    this.setState(() => ({ displayName }));
  };

  onStateChange = (e, center) => {
    const city_name = e.target.value;
    // const center = e.target.center;
    this.setState(() => ({ city_name, center }));
    this.props.startSetDispensaries(city_name);
  };

  searchDispensaries = () => {
    const cityName = this.state.city_name;
    this.props.startSetDispensaries(cityName);
  };

  render() {
    const dispensariesArr = this.props.dispensaries;
    const { city, products } = this.props.user;
    const propductsArr = _.toArray(this.state.products);
    return (
      <div>
        <div className="container-fluid no-margin">
          <Col md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <div style={{ justifyContent: "flex-end" }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    textTransform: "uppercase",
                    lineHeight: 0.5,
                  }}
                >
                  {propductsArr.length} Results
                </h3>
              </div>
            </div>

            <div style={{ width: "100%", height: "100vh" }}>
              <div style={{ width: "100%", height: "90%", marginBottom: 80 }}>
                {this.state.loaded === false ? (
                  <LoadingPage />
                ) : (
                  propductsArr.map((profile) => {
                    return (
                      <Col md={3} sm={6} xs={6}>
                        <Link
                          to={`/dispensaries/${profile.city}/${profile.slug}/products/${profile.id}`}
                          {...profile}
                        >
                          <div className="list-card-style">
                            <div
                              style={{
                                boder: "none",
                                minWidth: 200,
                                width: "100%",
                                // mxHeight: 480,
                                borderTopRightRadius: 22,
                                borderTopLeftRadius: 22,
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={profile.img_url}
                                alt="profile img"
                                className="product-img-container"
                              />
                            </div>

                            <div
                              className="list-card"
                              style={{ flexDirection: "row", minHeight: 120 }}
                            >
                              <div style={{ width: "100%" }}>
                                <div style={{ padding: 4 }}>
                                  {profile.hidden ? (
                                    <>
                                      <strong
                                        style={{ fontSize: 12, color: "red" }}
                                      >
                                        HIDDEN
                                      </strong>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <p
                                    style={{ fontSize: 18, fontWeight: "900" }}
                                  >
                                    {profile.productName}
                                  </p>

                                  <p style={{ fontSize: 12, lineHeight: 1.5 }}>
                                    {profile.productThc ? (
                                      <>
                                        <strong
                                          style={{
                                            color: "#999999",
                                            fontWeight: 900,
                                          }}
                                        >
                                          THC{" "}
                                        </strong>
                                        <strong style={{ fontWeight: 900 }}>
                                          {profile.productThc}
                                        </strong>
                                      </>
                                    ) : (
                                      <div style={{ padding: 10 }}></div>
                                    )}

                                    {profile.productCbd ? (
                                      <>
                                        <strong style={{ color: "#aca3a3" }}>
                                          {" "}
                                          | CBD{" "}
                                        </strong>
                                        <strong style={{ fontWeight: 900 }}>
                                          {profile.productCbd}
                                        </strong>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </p>

                                  <div>
                                    <div className="val">
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 900,
                                        }}
                                      >
                                        {" "}
                                        {profile.currencyFormat}{" "}
                                        {profile.priceRange}
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    className="badge badge-secondary"
                                    style={{ marginTop: 8 }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      #{_.toUpper(profile.type)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })
                )}
              </div>
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userProps: state.userProps,
    dispensaries: itemSelector(state.dispensaries, state.filtersText),
  };
};

export default compose(connect(mapStateToProps, { startSetDispensaries }))(
  DealsMenu
);
