import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Col } from "react-bootstrap";
import _ from "lodash";
import firebase from 'firebase/compat/app';
import Friend from "./Friend";
import Layout from "../layout/Layout";
import ProfileSidebar from "../profile/ProfileSidebar";


class FollowingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 100,
      isLoading: true,
      isEmpty: false,
      isFinished: false,
      followingData: [],
      profileData: {}
    };
  }

  componentDidMount() {
    const uid  = this.props.location.state.uid;
    const displayName  = this.props.location.state.displayName;
    
    firebase.database().ref(`/following-data/${uid}`).once("value").then(snap => {
        this.setState({
          followingData: _.reverse(_.toArray(snap.val()))
        });
      })
    firebase.database().ref(`/users/${uid}`).once("value").then(snap => {
      console.log(snap.val(), "SHOPPER")
      this.setState({
          profileData: snap.val()
      });
    })
  }

  render() {
    const { displayName }  = this.state.profileData
    return (
      <Layout>
        <Grid style={{marginTop: 90}}>
          <Col md={3} sm={12} xs={12}>
          <ProfileSidebar profileData={this.state.profileData}/>
          </Col>
          <Col md={6} sm={12} xs={12}>
            {this.state.followingData.length === 0 ? (
              <p>
                @{displayName} is not following anyone!
                <span role="img" aria-label="sad emoji">
                  😒
                </span>
              </p>
            ) : (
              this.state.followingData.map((friend, id) => {
                return <Friend key={id} {...friend} profileData={this.state.profileData} />;
              })
            )}
          </Col>
          <Col md={3} smHidden xsHidden>
            {/* <h6>Featured Curators</h6> */}
            {/* <TopFriendsIndex featured={featured}/> */}
          </Col>
          
        </Grid>
      </Layout>
    );
  }
}

export default (FollowingList = connect(
  state => ({
    following: state.following,
  }),
  undefined
)(FollowingList));
