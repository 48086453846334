import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import firebase from "firebase/compat/app";
// import database from "../../firebase/firebase";
import FileUploader from "react-firebase-file-uploader";
import { Checkbox } from "react-bootstrap";
import _ from "lodash";
import { cities } from "../../utils";

class ProductForm extends React.Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);

    this.state = {
      createdAt: props.product ? moment(props.product.createdAt) : moment(),
      displayName: "",
      address: "",
      city: "",
      zip_code: "",
      latitude: "",
      longitude: "",
      slug: "",
      website: "",
      type: props.product ? props.product.type : "",
      id: firebase.database().ref().push().key,
      photoURL: "",
      package_level: "",
      lic_number: "",
      license_type: "",
      map_featured: false,
      verified: false,
      file: [null],
      isUploading: false,
      uploadProgress: 0,
      error: "",
    };
  }

  // Product Type
  onMapFeatureChange = (e) => {
    this.setState(() => {
      return {
        map_featured: e,
      };
    });
  };

  onVerifiedChange = (e) => {
    this.setState(() => {
      return {
        verified: e,
      };
    });
  };

  onCategoryChange = (e) => {
    this.setState(() => {
      return {
        type: e,
      };
    });
  };

  onDisplayNameChange = (e) => {
    const displayName = e.target.value;
    this.setState(() => ({ displayName }));
  };

  onBusinessAddressChange = (e) => {
    const address = e.target.value;
    this.setState(() => ({ address }));
  };

  onBusinessZipChange = (e) => {
    const zip_code = e.target.value;
    this.setState(() => ({ zip_code }));
  };

  onLatitudeChange = (e) => {
    const latitude = e.target.value;
    this.setState(() => ({ latitude }));
  };

  onLongitudeChange = (e) => {
    const longitude = e.target.value;
    this.setState(() => ({ longitude }));
  };

  onSlugChange = (e) => {
    const slug = e.target.value;
    this.setState(() => ({ slug }));
  };

  onWebsiteChange = (e) => {
    const website = e.target.value;
    this.setState(() => ({ website }));
  };

  onLicNumberChange = (e) => {
    const lic_number = e.target.value;
    this.setState(() => ({ lic_number }));
  };

  onLicenseTypeChange = (e) => {
    const license_type = e.target.value;
    this.setState(() => ({ license_type }));
  };

  onPhoneNumberChange = (e) => {
    const phone_number = e.target.value;
    this.setState(() => ({ phone_number }));
  };

  onUIDChange = (e) => {
    const uid = e.target.value;
    this.setState(() => ({ uid }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.displayName) {
      this.setState(() => ({ error: "Please provide product name." }));
    } else {
      this.setState(() => ({ error: "" }));

      this.props.onSubmit({
        createdAt: this.state.createdAt.valueOf(),
        displayName: this.state.displayName,
        address: this.state.address,
        city: this.state.city,
        zip_code: this.state.zip_code,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        slug: this.state.slug,
        website: this.state.website,
        type: this.state.type,
        photoURL: this.state.photoURL,
        id: this.state.id,
        map_featured: this.state.map_featured,
        verified: this.state.verified,
        package_level: this.state.package_level,
        uid: this.state.uid,
        lic_number: this.state.lic_number,
        license_type: this.state.license_type,
      });
    }
  };

  // UPLOAD CODE

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0,
    });

  handleProgress = (progress) =>
    this.setState({
      uploadProgress: progress,
    });

  handleUploadError = (error) => {
    this.setState({
      isUploading: false,
      // Todo: handle error
    });
    console.error(error);
  };

  handleUploadSuccess = (filename) => {
    this.setState({ photoURL: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref(`business/${this.state.id}/`)
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ photoURL: url }));
  };

  handleOptionChange = (value) => {
    this.setState({ city: value });
  };

  render() {
    return (
      <div className="container-fluid">
        <form onSubmit={this.onSubmit}>
          {this.state.error && (
            <p className="form__error">{this.state.error}</p>
          )}

          <div className="col-md-8">
            <div className="row">
              <div className="form-group">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h4>Add Business</h4>
                  <br />
                  <div>
                    <div>
                      <h4>Type</h4>
                    </div>
                    <div>
                      <p>Select at least one category type</p>
                    </div>
                    <div className="new-post-cat-container">
                      <div className="new-post-cat-inner-wrapper">
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              onClick={() =>
                                this.onCategoryChange("dispensary")
                              }
                            />
                          </div>
                          <div>
                            <p>Dispensary</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              onClick={() => this.onCategoryChange("doctors")}
                            />
                          </div>
                          <div>
                            <p>Doctor</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              onClick={() =>
                                this.onCategoryChange("processors")
                              }
                            />
                          </div>
                          <div>
                            <p>Processor</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              onClick={() => this.onCategoryChange("growers")}
                            />
                          </div>
                          <div style={{ marginRight: 8 }}>
                            <p>Grower</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              onClick={() =>
                                this.onCategoryChange("testing-labs")
                              }
                            />
                          </div>
                          <div>
                            <p>Testing Lab</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4>Business Details</h4>
                    </div>
                    <div>
                      <p>Enter information about business</p>
                    </div>
                    <div className="new-post-cat-container">
                      <div
                        style={{
                          width: "100%",
                          marginBottom: 16,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "100%", marginRight: 8 }}>
                          <input
                            className="product_form_price_input"
                            type="text"
                            placeholder="Store name"
                            value={this.state.displayName}
                            onChange={this.onDisplayNameChange}
                            row={4}
                          />
                        </div>
                        <div style={{ width: "100%", marginLeft: 8 }}>
                          <strong>City</strong>
                          <div>
                            <select
                              value={this.state.city}
                              onChange={(e) => {
                                this.setState({
                                  city: e.target.value,
                                });
                              }}
                            >
                              {cities.map((city) => (
                                <option value={city.city_name}>
                                  {city.city_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginBottom: 16,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "100%", marginRight: 8 }}>
                          <input
                            className="product_form_price_input"
                            type="text"
                            placeholder="Address"
                            value={this.state.address}
                            onChange={this.onBusinessAddressChange}
                            row={4}
                          />
                        </div>
                        <div style={{ width: "100%", marginLeft: 8 }}>
                          <input
                            className="product_form_price_input"
                            type="text"
                            placeholder="Zip Code"
                            value={this.state.zip_code}
                            onChange={this.onBusinessZipChange}
                            row={4}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginBottom: 16,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "100%", marginRight: 8 }}>
                          <input
                            className="product_form_price_input"
                            type="text"
                            placeholder="Latitude example: -94.345545"
                            value={this.state.latitude}
                            onChange={this.onLatitudeChange}
                            row={4}
                          />
                        </div>
                        <div style={{ width: "100%", marginLeft: 8 }}>
                          <input
                            className="product_form_price_input"
                            type="text"
                            placeholder="Longitude example: -94.345545"
                            value={this.state.longitude}
                            onChange={this.onLongitudeChange}
                            row={4}
                          />
                        </div>
                      </div>

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="Slug"
                          value={this.state.slug}
                          onChange={this.onSlugChange}
                          row={4}
                        />
                      </div>

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="Website"
                          value={this.state.website}
                          onChange={this.onWebsiteChange}
                          row={4}
                        />
                      </div>

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="License number"
                          value={this.state.lic_number}
                          onChange={this.onLicNumberChange}
                          row={4}
                        />
                      </div>

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="License type"
                          value={this.state.license_type}
                          onChange={this.onLicenseTypeChange}
                          row={4}
                        />
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginBottom: 16,
                          marginLeft: 8,
                        }}
                      >
                        <strong>Package level</strong>
                        <div>
                          <select
                            value={this.state.package_level}
                            onChange={(e) => {
                              this.setState({
                                package_level: e.target.value,
                              });
                            }}
                          >
                            <option value={""}>Select one</option>
                            <option value={"free"}>Free</option>
                            <option value={"premium"}>Premuim</option>
                          </select>
                        </div>
                      </div>

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="Phone number"
                          value={this.state.phone_number}
                          onChange={this.onPhoneNumberChange}
                          row={4}
                        />
                      </div>

                      {/* <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="State"
                          value={this.state.state}
                          onChange={this.onStateChange}
                          row={4}
                        />
                      </div> */}

                      {/* <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="Store hours"
                          value={this.state.store_hours}
                          onChange={this.onStoreHoursChange}
                          row={4}
                        />
                      </div> */}

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="UID"
                          value={this.state.uid}
                          onChange={this.onUIDChange}
                          row={4}
                        />
                      </div>

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              onClick={() =>
                                this.onMapFeatureChange(
                                  !this.state.map_featured
                                )
                              }
                            />
                          </div>
                          <div>
                            <p>Map featured</p>
                          </div>
                        </div>
                      </div>

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              onClick={() =>
                                this.onVerifiedChange(!this.state.verified)
                              }
                            />
                          </div>
                          <div>
                            <p>Verified</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4>Select Image</h4>
                    </div>
                    <div>
                      <p>Upload a pretty picture</p>
                    </div>
                    <div className="new-post-cat-container">
                      <div className="new-post-cat-inner-wrapper">
                        <FileUploader
                          accept="image/*"
                          name="image-uploader-multiple"
                          storageRef={firebase
                            .storage()
                            .ref(`business/${this.state.id}/`)}
                          onUploadStart={this.handleUploadStart}
                          onUploadError={this.handleUploadError}
                          onUploadSuccess={this.handleUploadSuccess}
                          onProgress={this.handleProgress}
                        />
                        <p>Progress: {this.state.uploadProgress}</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      onClick={this.onSubmit}
                      className="btn btn-primary btn-lg"
                    >
                      Post business
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <h4>Preview</h4>
            <div style={{ marginTop: 16 }}>
              <div className="list-card-style">
                <div
                  style={{
                    maxHeight: 180,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      borderRadius: 16,
                      minHeight: 220,
                      overflow: "hidden",
                    }}
                  >
                    {this.state.photoURL ? (
                      <img
                        src={this.state.photoURL}
                        alt="product"
                        style={{ height: "100%", width: "100%" }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>

                <div
                  className="list-card"
                  style={{ flexDirection: "row", minHeight: 120 }}
                >
                  <div style={{}}>
                    <div style={{ padding: 4 }}>
                      <p style={{ fontSize: 12, fontWeight: "bold" }}>
                        #{_.toUpper(this.state.type)}
                      </p>
                      <p style={{ fontSize: 18, fontWeight: "900" }}>
                        {this.state.displayName}
                      </p>
                      <strong>
                        <p>Address</p>
                      </strong>
                      <p style={{ fontSize: 12 }}>{this.state.address}</p>
                      <strong>
                        <p>City</p>
                      </strong>
                      <p style={{ fontSize: 12 }}>{this.state.city}</p>
                      <strong>
                        <p>Website</p>
                      </strong>
                      <p style={{ fontSize: 12 }}>{this.state.website}</p>
                      <strong>
                        <p>Slug</p>
                      </strong>
                      <p style={{ fontSize: 12 }}>{this.state.slug}</p>
                      <strong>
                        <p>Map Coordinates</p>
                      </strong>
                      <p style={{ fontSize: 12 }}>
                        Lat: {this.state.latitude} Lng: {this.state.longitude}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, undefined)(ProductForm);
