import React, { Component } from "react";
import firebase from 'firebase/compat/app';
import { connect } from "react-redux";
import { getComments, startAddComment } from "../../actions/comments";
import CommentForm from "./CommentForm";
import CommentsList from "./CommentsList";

class CommentsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      counter: 5,
      comments: [],
      isLoading: true,
      isEmpty: false,
      isFinished: false
    }
  }

  componentWillMount() {
    const { id } = this.props;
    firebase.database().ref(`comments/${id}/comment/`).orderByChild('createdAt').on('value', (snapshot) => {
      const comments = snapshot.val()
      this.setState({ comments })
    })
  }

  componentWillUnmount() {
    const { id } = this.props;
    firebase.database().ref(`comments/${id}/comments/`).off()
  }


  onSubmit = commentBody => {
    this.props.startAddComment(commentBody);
  };

  render() {
    return (
      <div>
          <CommentForm 
            commentBody={this.props.commentBody} 
            onSubmit={commentBody => {
              this.props.startAddComment(this.props.id, commentBody)
            }}
          />
          <CommentsList/>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lists: state.lists,
  comments: state.comments,
  profile_avatar: state.profile_avatar
});

const mapDispatchToProps = (dispatch, props) => ({
  getComments,
  startAddComment: (id, commentBody) => dispatch(startAddComment(id, commentBody))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsView);
