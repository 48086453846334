import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUserProfileProps } from '../../actions/profile';
import moment from "moment";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Layout from "../layout/Layout";
import _ from "lodash";
import "../../styles/App.css";
import { Grid, Col, Button } from "react-bootstrap";
import firebase from 'firebase/compat/app';
import { fetchProducts } from '../../services/shelf/actions';
import ProductList from '../Shelf/ProductList';
import '../Shelf/style.scss';
import Filter from '../Shelf/Filter';
import LoadingPage from '../utilities/LoadingPage'
import ShelfHeader from '../Shelf/ShelfHeader';
import * as routes from "../../constants/routes";

class ProductsView extends Component {
  static propTypes = {
      fetchProducts: PropTypes.func.isRequired,
      products: PropTypes.array.isRequired,
      filters: PropTypes.array,
      sort: PropTypes.string
      };

    state = {
      isLoading: false,
      profileData: {}
    };
    
  constructor(props){
    super(props)
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      profileData: [],
      profileDataListing: [],
    };
  }
  


  render() {
   const { city, slug, photoURL, displayName, products } = this.props.user
    return (
      <Layout>
        <Grid fluid>
          <div style={{marginTop: 90, minHeight: 800}}>
          <Col md={2}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div className="list-profile-avatar-wrapper">
                <img src={photoURL} className="list-profile-avatar" alt="profile img" />
              </div>
              <div>
                <p className="list-user-name">{displayName}</p>
              </div>
            </div>
            <div style={{marginTop: 16}}>
            <h4><Link to={routes.TIMELINE}><i className="fas fa-home nav-bar-icon"></i> Home </Link> </h4> <br/>
            <h4><Link to={`/dashboard`}><i className="fas fa-chart-line nav-bar-icon"></i> Dashboard </Link> </h4> <br/>
            <h4><Link to={`/dispensaries/${city}/${slug}`}><i className="fas fa-user nav-bar-icon"></i>  Profile </Link> </h4><br/>
            <h4><Link to={`/dashboard/products`}><i className="fas fa-prescription-bottle-alt nav-bar-icon"></i>  Products </Link></h4><br/>
            {/* <h4><i className="fas fa-comments nav-bar-icon"></i>  Reviews </h4><br/> */}
            {/* <h4><i className="fas fa-envelope nav-bar-icon"></i>  Messages </h4><br/> */}
            </div>
          </Col>
            <Col md={10} sm={12} xs={12}>
                <div className="col-md-10 col-sm-12 col-xs-12">
                  <React.Fragment>
                    <div className="shelf-container">
                      <ShelfHeader productsLength={_.toArray(products).length} />
                      <ProductList products={products} />
                    </div>
                  </React.Fragment>
                </div>

            </Col>

          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  profile: state.profile,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  fetchUserProfileProps: () => dispatch(fetchUserProfileProps()),
  fetchProducts: () => dispatch(fetchProducts())
});



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsView);
