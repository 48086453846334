// Lists Reducer
import { ADD_POST, FETCH_TIMELINE_POSTS, FETCH_PROFILE_LISTS, FETCH_MORE_LISTS, ADD_ITEM, REMOVE_POST, SET_LISTS } from '../actions/posts'


const postReducerDefaultState = [];

export default (state = postReducerDefaultState, action) => {
  switch (action.type) {
    case ADD_POST:
    return [
      ...state,
      action.post
    ];

    case ADD_ITEM:
      return [
        ...state,
        action.itemBody
      ];
      
    case REMOVE_POST:
      return state.filter(({ id }) => id !== action.id);
    
    case SET_LISTS:
      return action.posts;
      
    case FETCH_PROFILE_LISTS:
      return action.payload;
    
    case FETCH_MORE_LISTS:
      return action.posts;
      
    case FETCH_TIMELINE_POSTS:
      return action.posts;
      
    default:
      return state;
  }
};
