import React from 'react';
import { connect } from 'react-redux';
import PostForm from './PostForm';
import { startAddPost } from '../../actions/posts';
import Layout from '../layout/Layout';
import { Grid, Col } from 'react-bootstrap';
import firebase from 'firebase/compat/app';

export class AddProductView extends React.Component {
  onSubmit = (post) => {
    this.props.startAddPost(post);
    
    const uid = firebase.auth().currentUser.uid;
    // const displayName = firebase.auth().currentUser.displayName;

    (function () {

      const followersRef = firebase.database().ref(`/followers/${uid}`);

      var followers_ = {};

      followersRef.on("value", snapshot => {
        var followers = Object.keys(snapshot.val());
        followers_ = snapshot.val();
        followers = Object.keys(followers_);
        var fanoutObj = {};
        // Post gets sent to user owns lists
        fanoutObj[`users/${uid}/posts/` + post.id] = post;
        // Post gets sent to all followers
        followers.forEach(key => (fanoutObj["/timeline/" + key + "/" + post.id] = post));
        // Post gets sent to post node for explnation and search purposes
        // fanoutObj["posts/" + post.id] = post;
        // Send object to firebase to spread
        firebase.database().ref().update(fanoutObj);
      });
    })();
    
    this.props.history.push('/');
  };
  render() {
    return (
      <Layout>
        <Grid>
          <Col md={3}></Col>
          <Col md={6}>
          <div style={{ marginTop: 80, marginBottom: 80 }}>
          <div className="list-card-style">
            <PostForm
              onSubmit={this.onSubmit}
            />
          </div>
          </div>
          </Col>
          <Col md={3}></Col>
        </Grid>
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddPost: (post) => dispatch(startAddPost(post))
});

export default connect(undefined, mapDispatchToProps)(AddProductView);
