import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import landing_image from "../../assets/images/owf_landing_2@2x.png"
import { Grid, Col,Button } from 'react-bootstrap'
import Layout from '../layout/Layout';
import white_logo from "../../assets/images/logo_white.svg"
import owf_weed_image from "../../assets/images/owf-weed-image.jpg"
import price_basic from "../../assets/images/price_basic@2x.png"
import price_pro from "../../assets/images/price_pro@2x.png"
import price_enterprise from "../../assets/images/price_enterprise@2x.png"

import "./style.scss"

const BusinessLanding = () => {
  return (
    <div style={{paddingBottom: 80}}>
        <div className="pricing-table-wrapper">
        <ul className="pricing-table">
            <li className="pricing-table__item">
            <img src={price_basic} alt="" className="pricing-table__img" />
            <h3 className="pricing-table__title">Okie Basic</h3>
            <p className="pricing-table__description">
                <span className="pricing-table__tagline">For small business that need exposure</span>
                <span className="pricing-table__price">FREE</span>
                <span className="pricing-table__label"></span>
                <span className="pricing-table__save"></span>
            </p>
            <ul className="pricing-table__products">
                <li className="pricing-table__product"><a href="">Profile Listing</a></li>
                <li className="pricing-table__product"><a href="">Reviews</a></li>
                <li className="pricing-table__product pricing-table__product--excluded"><a href="">Unlimited Menu</a></li>
                <li className="pricing-table__product pricing-table__product--excluded"><a href="">Directory Featured</a></li>
            </ul>
            <a href="https://okieweedfinder.com/signup/business" className="pricing-table__button">continue</a>
            </li>
            <li className="pricing-table__item pricing-table__item--popular" data-popular="Popular">
            <img src={price_pro} alt="" className="pricing-table__img" />
            <h3 className="pricing-table__title">Okie Business</h3>
            <p className="pricing-table__description">
                <span className="pricing-table__tagline">For business looking to expand the sales</span>
                <span className="pricing-table__price">Call Sales</span>
                <span className="pricing-table__label">(580) 678-5654</span>
                {/* <span className="pricing-table__save">You save $100</span> */}
            </p>
            <ul className="pricing-table__products">
                <li className="pricing-table__product"><a href="">Listing Management</a></li>
                <li className="pricing-table__product"><a href="">Reviews Management</a></li>
                <li className="pricing-table__product"><a href="">Unlimited Menu</a></li>
                <li className="pricing-table__product"><a href="">Directory Featured</a></li>
            </ul>
            <a href="https://okieweedfinder.com/signup/business" className="pricing-table__button_pro">continue</a>
            </li>
            <li className="pricing-table__item">
            <img src={price_enterprise} alt="" className="pricing-table__img" />
            <h3 className="pricing-table__title">Enterprise</h3>
            <p className="pricing-table__description">
                <span className="pricing-table__tagline">For multi-business operations and team management</span>
                <span className="pricing-table__price">Call Sales</span>
                <span className="pricing-table__label">(580) 678-5654</span>
                {/* <span className="pricing-table__save">You save 100</span> */}
            </p>
            <ul className="pricing-table__products">
            <li className="pricing-table__product"><a href="">Listing Management</a></li>
                <li className="pricing-table__product"><a href="">Reviews Management</a></li>
                <li className="pricing-table__product"><a href="">Unlimited Menu</a></li>
                <li className="pricing-table__product"><a href="">Directory Featured</a></li>
            </ul>
            <a href="https://okieweedfinder.com/signup/business" className="pricing-table__button">continue</a>
            </li>
        </ul>
        </div>
    </div>
  )
}
export default BusinessLanding