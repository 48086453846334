import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from 'firebase/compat/app';
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import _ from "lodash";
import FollowButton from "../followButton/FollowButton";
import { Col } from "react-bootstrap";
import "../../styles/App.css";

const NotSingedInFollowBtn = () => (
  <div>
    <Link to={routes.SIGN_IN}>
      <div className="follow-btn">
        <p className="follow-button-text">Follow</p>
      </div>
    </Link>
  </div>
);
const DispensaryMenu = (props) => (
  <div>
    <a href={`/dispensaries/${props.profileData.city}/${props.profileData.slug}`}>
      <div className="follow-btn">
        <p className="follow-button-text">Menu</p>
      </div>
    </a>
  </div>
);

const EditProfileButton = () => (
  <Link to={routes.ACCOUNT}>
    <div className="unfollow-btn">
      <p className="unfollow-button-text">Edit profile</p>
    </div>
  </Link>
);

class ProfileSidebarButton extends Component {
  render() {
    return (
      <div>
        {this.props.userProps.uid === this.props.uid ? (
          <EditProfileButton />
        ) : (
          <FollowButton profileData={this.props.profileData} />
        )}
      </div>
    );
  }
}


class ProfileSidebar extends Component {
  constructor(props){
    super(props)
    this.state = {
      user: false
    }
  }
  componentDidMount(){
    firebase.auth().onAuthStateChanged(user => { 
      if(user){
        this.setState({user: true})
      }
    })
  }
  render() {

    const { photoURL, uid, displayName, bio, posts, followers, following, website, phonenumber, verified, type } = this.props.profileData;
    const followers_ = _.toArray(followers);
    const following_ = _.toArray(following);
    
    return (
      <Col md={3} sm={12} xs={12} className="profile-user-card-wrapper no-margin">
      
      <Col md={12} className="no-margin">
        <Col md={12} sm={12} className="no-margin">
          
          <div className="sidebar-img-wrapper">
            <img src={photoURL} className="sidebar-user-img" alt="user avatar" draggable={false} />
          </div>

          <div className="sidebar-card no-margin">
            
            <div className="sidebar-user-details-wrapper">
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 12}}>
                  <div>
                    <a href={`/u/${displayName}`}>
                      <strong><p className="sidebar-user-name">{displayName}</p></strong>
                    </a>
                  </div>
                  <div style={{marginTop: 4, marginLeft: 4}}>
                  {" "} {verified ? <span className="verified" /> : <span />}{" "} 
                  </div>
              </div>
              <p className="sidebar-user-bio" dangerouslySetInnerHTML={ {__html: bio}}></p>
              <a href={website} target="_blank" alt="user website"><p className="sidebar-user-bio">{website}</p></a>
              
            </div>
          </div>
        </Col>

        <Col md={12} sm={12} className="no-margin">
          {this.state.user ? <ProfileSidebarButton uid={uid} userProps={this.props.userProps} profileData={this.props.profileData} /> : <div></div>}
          {!this.state.user ? <NotSingedInFollowBtn/> : <div></div>}
          {type === 'dispensary' ? <DispensaryMenu profileData={this.props.profileData}/> : <div></div>}

          <div className="sidebar-user-stats no-margin">
            <div>
              <Link to={{ pathname: `/u/${displayName}` }}>
                <strong>Posts</strong> <p>{_.toArray(posts).length}</p>{" "}
              </Link>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/u/${displayName}/followers`,
                  state: { uid, displayName }
                }}
              >
                {" "}
                <div style={{fontWeight: '900'}}>Followers</div> <p>{followers_.length}</p>{" "}
              </Link>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/u/${displayName}/following`,
                  state: { uid, displayName }
                }}
              >
                {" "}
                <strong>Following</strong> <p>{following_.length}</p>{" "}
              </Link>
            </div>
          </div>
        </Col>
        </Col>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  userProps: state.userProps,
});

export default connect(mapStateToProps, undefined)(ProfileSidebar)
