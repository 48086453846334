import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Grid,
} from "react-bootstrap";
import { connect } from "react-redux";
import { startAddPost } from "../../actions/posts";
import logo from "../../assets/images/logo_white.svg";
import Sidebar from "react-sidebar";
import * as routes from "../../constants/routes";
import firebase from "firebase/compat/app";
import { auth } from "../../firebase";
// import FloatCart from '../FloatCart'
import PostForm from "../posts/PostForm";
import SearchUser from "../explore/";
import SidebarContentAuth from "./SidebarContentAuth";

class Navigation extends Component {
  onSubmit = (post) => {
    this.props.startAddPost(post);
  };
  render() {
    return (
      <div>
        {this.props.authUser ? (
          <NavigationAuth
            startAddPost={this.props.startAddPost}
            onSubmit={this.onSubmit}
            user={this.props.user}
          />
        ) : (
          <NavigationNonAuth />
        )}
      </div>
    );
  }
}

class NavigatioMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
  }

  onSetSidebarOpen() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  render() {
    return (
      <Col mdHidden lgHidden>
        <Sidebar
          sidebar={<SidebarContentAuth />}
          open={this.state.sidebarOpen}
          styles={{ sidebar: { background: "white" } }}
        >
          <div>
            <SearchUser />
          </div>
        </Sidebar>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            backgroundColor: "#101118",
          }}
        >
          <div className="mobile-nav-bar">
            <div style={{ position: "absolute", top: 5, left: 5, zIndex: 100 }}>
              <Button
                type="button"
                style={{ backgroundColor: "#282839" }}
                onClick={() => this.onSetSidebarOpen()}
              >
                <i
                  className="fas fa-bars"
                  style={{ fontSize: 24, color: "#fff" }}
                ></i>
              </Button>
            </div>
            <Link to="/">
              <img
                src={logo}
                className="app-logo-header"
                alt="okie weed finder"
                draggable="false"
              />
            </Link>
          </div>
        </div>
      </Col>
    );
  }
}

class NavigationAuth extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.state = {
      show: false,
      photoURL: null,
      displayName: "",
    };
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleHide() {
    this.setState({ show: false });
  }
  onSubmit = (post) => {
    const uid = firebase.auth().currentUser.uid;

    (function () {
      const followersRef = firebase.database().ref(`/followers/${uid}`);

      var followers_ = {};

      followersRef.on("value", (snapshot) => {
        var followers = Object.keys(snapshot.val());
        followers_ = snapshot.val();
        followers = Object.keys(followers_);
        var fanoutObj = {};
        fanoutObj[`users/${uid}/posts/` + post.id] = post;
        followers.forEach(
          (key) => (fanoutObj["/timeline/" + key + "/" + post.id] = post)
        );
        fanoutObj["posts/" + post.id] = post;
        firebase.database().ref().update(fanoutObj);
      });
    })();
    this.setState({ show: false });
  };

  render() {
    const { displayName, photoURL } = this.props.user;
    // const { admin, city, slug, superadmin, displayName } = this.props.user
    return (
      <div>
        <NavigatioMobile />
        <Grid fluid className="no-margin">
          <Col md={12} lg={12} smHidden xsHidden className="no-margin">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                backgroundColor: "#101118",
              }}
            >
              <div style={{ marginLeft: 16, width: "100%" }}>
                <Link to="/">
                  <img
                    src={logo}
                    className="app-logo-header"
                    alt="okie weed finder"
                    draggable="false"
                  />
                </Link>
                <Link
                  to={routes.BUSINESS_LANDING}
                  style={{
                    fontWeight: "bold",
                    color: "#e1e1e1",
                    marginLeft: 16,
                  }}
                >
                  Why okieweedfinder?
                </Link>
              </div>
              <div style={{ marginRight: 8 }}>
                <SearchUser />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: 12,
                }}
              >
                <div style={{ marginLeft: 8 }}>
                  <div className="dropdown">
                    <div
                      className="dropdown-toggle nav-profile-img-wrapper"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={photoURL}
                        className="nav-profile-img"
                        alt="user avatar"
                      />
                    </div>

                    <ul className="dropdown-menu">
                      <li>
                        <Link to={routes.DASHBOARD}>Dashboard</Link>
                      </li>
                      <li role="separator" className="divider"></li>
                      <li>
                        <Link
                          to={`/dispensaries/${this.props.user.city}/${this.props.user.slug}`}
                        >
                          Profile
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div style={{ marginLeft: 16 }}>
                  <Button
                    className="btn add-new-post-btn"
                    style={{ marginRight: 8 }}
                  >
                    <Link
                      to={routes.PRODUCT_NEW}
                      style={{
                        fontWeight: "bold",
                        color: "#e1e1e1",
                      }}
                    >
                      <i
                        className="fas fa-pencil-alt"
                        style={{ marginRight: "6px", color: "#e1e1e1" }}
                      ></i>
                      <strong style={{ color: "#e1e1e1" }}>Post deal</strong>
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Grid>

        <div>
          <Modal
            {...this.props}
            show={this.state.show}
            onHide={this.handleHide}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-lg">
                Share new post!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
              <PostForm onSubmit={this.onSubmit} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

class NavigationNonAuth extends Component {
  render() {
    return (
      <div>
        <Grid fluid className="no-margin">
          <Col md={12} lg={12} className="no-margin">
            <nav className="navbar navbar-default navbar-fixed-top">
              <div className="container-fluid">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#navbar"
                    aria-expanded="false"
                    aria-controls="navbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <div style={{ marginLeft: 16, marginTop: 10 }}>
                    <Link to="/">
                      <img
                        src={logo}
                        className="app-logo-header"
                        alt="okie weed finder"
                        draggable="false"
                      />
                    </Link>
                    <Link
                      to={routes.BUSINESS_LANDING}
                      style={{
                        fontWeight: "bold",
                        color: "#e1e1e1",
                        marginLeft: 16,
                      }}
                    >
                      Why okieweedfinder?
                    </Link>
                  </div>
                </div>

                <div
                  id="navbar"
                  className="navbar-collapse collapse"
                  style={{ marginRight: 8, marginLeft: 8 }}
                >
                  <ul className="nav navbar-nav navbar-right">
                    <li>
                      <Link to={routes.EXPLORE} style={{ fontWeight: "bold" }}>
                        <i
                          className="fas fa-search"
                          style={{ marginRight: "6px" }}
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={routes.DISPENSARIES}
                        style={{
                          fontWeight: "bold",
                          color: "#e1e1e1",
                          textTransform: "uppercase",
                        }}
                      >
                        Dispensaries
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={routes.BLOG}
                        style={{
                          fontWeight: "bold",
                          color: "#e1e1e1",
                          textTransform: "uppercase",
                        }}
                      >
                        Blog
                      </Link>
                    </li>

                    <li className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          fontWeight: "bold",
                          color: "#e1e1e1",
                          textTransform: "uppercase",
                        }}
                      >
                        Medical Marijuana 101{" "}
                        <i className="fas fa-caret-down"></i>
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to={routes.MEDICAL_MARIJUANA}>
                            Marijuana 101
                          </Link>
                        </li>
                        <li>
                          <Link to={routes.AVAILABLE_FORMS}>
                            Available Forms
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        to={routes.SIGN_IN}
                        style={{
                          fontWeight: "bold",
                          color: "#e1e1e1",
                          textTransform: "uppercase",
                        }}
                      >
                        Login
                      </Link>
                    </li>
                    <Link to={routes.SIGN_UP}>
                      <Button
                        className="btn-lg add-new-post-btn"
                        style={{ marginTop: 8, marginRight: 24 }}
                      >
                        <strong
                          style={{
                            fontWeight: "bold",
                            color: "#e1e1e1",
                            textTransform: "uppercase",
                          }}
                        >
                          Sign Up
                        </strong>
                      </Button>
                    </Link>
                  </ul>
                </div>
              </div>
            </nav>
          </Col>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddPost: (post) => dispatch(startAddPost(post)),
});

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  userProps: state.userProps,
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
