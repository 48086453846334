// Dispensaries Reducer

import { SET_DISPENSARIES } from '../actions/dispensaries'

const dispensariesReducerDefaultState = [];

export default (state = dispensariesReducerDefaultState, action) => {
  switch (action.type) {
    case SET_DISPENSARIES:
      return action.dispensaries;
    default:
      return state;
  }
};
