import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { startEditProduct, startRemoveProduct } from "../../actions/products";
import ProductEditForm from "./ProductEditForm";
import { Button, Grid } from "react-bootstrap";
import Layout from "../../components/layout/Layout";
import _ from "lodash";
import firebase from "firebase/compat/app";

const EditProductView = (props) => {
  const [postData, setPostData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState({});

  const getBusinessProduct = async () => {
    try {
      const { uid } = props.user;
      const id = props.match.params.id;
      const db = firebase.firestore().collection("usersDB");
      let productArr = await db
        .doc(`${uid}`)
        .collection("product")
        .doc(`${id}`)
        .get();

      // let productTemp = [];

      // productArr.forEach((doc) => {
      //   let dispoData = doc.data();
      //   productTemp.push(dispoData);
      // });

      setPostData(productArr.data());
      setLoaded(true);
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBusinessProduct();
  }, []);

  const removeProductButton = () => {
    const { slug, city, products, uid } = props.user;
    return (
      <Grid fluid style={{ margin: 16 }}>
        <Button
          bsStyle="danger"
          style={{ marginBottom: "10px" }}
          onClick={() => {
            props.startRemoveProduct({
              id: props.match.params.id,
              uid,
            });
            props.history.push(`/dashboard`);
          }}
        >
          <div>
            <i className="fa fa-trash" aria-hidden="true" /> Delete product
          </div>
        </Button>
      </Grid>
    );
  };

  if (loaded === false) {
    return (
      <Layout>
        <div>
          <div>
            <div md={6} sm={12} xs={12}></div>
            {removeProductButton(postData)}
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <div>
          <div>
            <div md={6} sm={12} xs={12}>
              <ProductEditForm
                product={postData}
                onSubmit={(postData) => {
                  props.startEditProduct(postData.id, postData.city, postData);
                  props.history.push("/dashboard");
                }}
              />
            </div>
            {removeProductButton(postData)}
          </div>
        </div>
      </Layout>
    );
  }
};

const mapStateToProps = (state, props) => ({
  products: state.shelf.products,
  userProps: state.userProps,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  startRemoveProduct: (id, city, data) =>
    dispatch(startRemoveProduct(id, city, data)),
  startEditProduct: (id, city, product) =>
    dispatch(startEditProduct(id, city, product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProductView);
