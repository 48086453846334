import React, { Component } from "react";
import { connect } from "react-redux";
import "../../styles/App.css";
import FollowButtonDispensary from "../followButton/FollowButtonDispensary";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import _ from "lodash";
import  defaultPhotoURL from '../../assets/images/default_avatar.jpg';

const NotSingedInFollowBtn = () => (
  <div style={{marginRight: 8}}>
    <Link to={routes.SIGN_IN}>
      <div className="follow-btn">
        <p className="follow-button-text">Follow</p>
      </div>
    </Link>
  </div>
);

const ProfileBtn = (props) => (
  <div>
    <a href={`/u/${props.displayName}/`}>
      <div className="follow-btn">
        <p className="follow-button-text">News</p>
      </div>
    </a>
  </div>
);

const EditProfileButton = () => (
  <Link to={routes.ACCOUNT}>
    <div className="unfollow-btn">
      <p className="unfollow-button-text">Edit profile</p>
    </div>
  </Link>
);

class ProfileSidebarButton extends Component {
  render() {
    return (
      <div>
        {this.props.userProps.uid === this.props.uid ? (
          <EditProfileButton />
        ) : (
          <FollowButtonDispensary profileData={this.props.profileData} />
        )}
      </div>
    );
  }
}

class ProfileSidebar extends Component {
  constructor(props){
    super(props)
    this.state = {
      user: false,
      profileData: {}
    }
  }
  

  render() {

    const { displayName, store_name, bio, address, phonenumber, verified, website, email, photoURL, uid, username } = this.props.profileData;
    
    const profileImage = photoURL ? (
          <div>
            <img src={photoURL} className="sidebar-user-img" alt="user avatar" draggable={false} />
          </div>
    ) : (
          <div>
            <img src={defaultPhotoURL} className="sidebar-user-img" alt="user avatar" draggable={false} />
          </div>
    )

    return (
      <div md={3} sm={12} xs={12} className="profile-user-card-wrapper no-margin">
      
      <div md={12} smHidden xsHidden >
        <div md={12} sm={12} >
          
            <div className="col-md-2 col-sm-12 col-xs-12">
              {/* div hack for  profile image*/}
            </div>
            
            <div className="col-md-10 col-sm-12 col-xs-12">
            <div className="sidebar-user-details-wrapper-dispensary">

              <div>
                <div className="sidebar-img-wrapper">
                  {profileImage}
                </div>
              </div>

              <div style={{marginLeft: 12}}>
              <div style={{marginTop:8, marginBottom: 8, display: 'flex', flexDirection: 'row'}}>
                <div>
                  <strong><p className="sidebar-user-name">{store_name ? store_name : displayName}</p></strong>
                </div>
                <div style={{marginTop: 2, marginLeft: 4}}>
                  {verified ? <span className="verified" /> : <span />}
                </div>
              </div>
              
              {bio ? (
                <div style={{marginTop:8, marginBottom: 8}}>
                  <p className="sidebar-user-bio" dangerouslySetInnerHTML={ {__html: bio}}></p>
                </div>) : (
                  <div/>
                )}

              {address ? (
                <div style={{marginTop:8, marginBottom: 8}} style={{marginRight: 10}}>
                  <p className="sidebar-user-bio"> <i className="fas fa-map-marker nav-bar-icon"></i><span style={{marginLeft:8}}>{address}</span></p>
                </div> ) : (
                  <div/>
                )}

              {phonenumber ? (
                <div style={{marginTop:8, marginBottom: 8}} style={{marginRight: 10}}>
                  <p className="sidebar-user-bio"><i className="fas fa-phone nav-bar-icon"></i><span style={{marginLeft:8}}>{phonenumber}</span></p>
                </div> ) : (
                  <div/>
                )}

              {email ? (
                <div style={{marginTop:8, marginBottom: 8}} style={{marginRight: 10}}>
                  <p className="sidebar-user-bio"><i className="fas fa-envelope nav-bar-icon"></i><span style={{marginLeft:8}}>{email}</span></p>
                </div>
              ) : (
                <div/>
              )}
              
              {website ? (
                <div style={{marginTop:8, marginBottom: 8}}>
                  <a href={website} target="blank" className="sidebar-user-bio"><i className="fas fa-link nav-bar-icon" style={{marginRight: 10}}></i><span style={{marginLeft:8}}>{website}</span></a>
                </div>
              ) : (
                <div/>
              )}

              <div style={{marginTop:8, marginBottom: 8, maxWidth: 160}}>
                  {/* <FollowButtonDispensary profileData={this.props.profileData} /> */}
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                  {this.state.user ? <ProfileSidebarButton uid={uid} userProps={this.props.userProps} profileData={this.props.profileData} /> : <div></div>}
                  {!this.state.user ? <NotSingedInFollowBtn/> : <div></div>}
                  {!this.state.user ? <ProfileBtn displayName={username}/> : <div></div>}
                </div>
              </div>

              </div>

              
            </div>
            </div>
          {/* </div> */}
        </div>
        
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProps: state.userProps,
});

export default connect(mapStateToProps, undefined)(ProfileSidebar)
