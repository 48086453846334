import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Col } from "react-bootstrap";
import Layout from "../layout/Layout";
import _ from "lodash";
import LoadingPage from "../utilities/LoadingPage";
import { fetchReviews } from "../../actions/reviews";
import Review from "./Review";
import SidebarContentAuth from "../dashboard/SidebarContentAuth";

class ReviewsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      user_search: {},
    };
  }

  componentDidMount() {
    const { slug } = this.props.user;
    // const slug = this.props.match.params.slug;
    // const city = this.props.match.params.city;
    this.mounted = true;

    if (this.mounted) {
      this.props.fetchReviews(slug);
    }
    console.log("slug::: ", slug);
  }

  render() {
    const reviewsArr = _.reverse(_.toArray(this.props.reviews));

    return (
      <Layout>
        <Grid fluid style={{ height: "100vh", marginTop: 32 }}>
          <div>
            <Col md={2} lg={2} smHidden xsHidden>
              <SidebarContentAuth />
            </Col>
            <Col md={10} sm={12} xs={12}>
              <h1>Review</h1>
              {reviewsArr.length === 0 ? (
                <LoadingPage />
              ) : (
                reviewsArr.map((props) => (
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <Review {...props} />
                  </div>
                ))
              )}
            </Col>
          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  reviews: state.reviews,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchReviews: (data) => dispatch(fetchReviews(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsView);
