import React from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../layout/Layout';
import { Grid, Col } from "react-bootstrap";
 
const SignUpPage = () =>
  <Layout>
    <Grid style={{marginTop: 90, height: '100%'}}>
      <Col md={6} className="login-modal">
        <h3 className="login-text">Business Sign Up</h3>
        <p className="login-text">Fill up the form below to gain access to your profile.</p>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScbd3cbITnsFj7Fi1796Q7Kl7E7PvkAKk6nR0jUH-brh4baBg/viewform?embedded=true" width="100%" height="1605" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </Col>
      <Col md={6} className="login-modal">
        <h3 className="login-text">Join oklahoma's very own community!</h3>
        <p className="login-text">Showcase your brand or business on our exclusive community. We have a mission, to champion local brands and explore the best products and dispensaries in Oklahoma. Are you from out of state? That, cool too. But know we will always put Oklahoma business before any other.</p>
        <p className="login-text">Our support team will contact you, someone either from Tulsa, Oklahoma City!</p>
      </Col>
    </Grid>
  </Layout>

export default withRouter(SignUpPage);