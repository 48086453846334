import React, { useState, useEffect } from "react";
import moment from "moment";
import firebase from "firebase/compat/app";
import _ from "lodash";
import "../Shelf/style.scss";

function PostDetailView(props) {
  const db = firebase.firestore();
  const [postData, setPostData] = useState({});
  const { slug } = props.match.params;
  // const postsARR = props.recent_news;
  // const post = postsARR.find((product) => product.slug === slug);
  const { img_url, title, post_body, createdAt } = postData;

  const getPostData = async () => {
    let blogPostData = await db
      .collection("blog")
      .where("slug", "==", slug)
      .get();

    blogPostData.forEach((doc) => {
      setPostData(doc.data());
    });
  };

  useEffect(() => {
    getPostData();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="app-wrapper">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div>
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: 28,
                  textTransform: "uppercase",
                  lineHeight: 1,
                }}
              >
                {title}
              </h1>
            </div>

            <div>
              {img_url ? (
                <div>
                  <img
                    src={img_url}
                    alt="post"
                    style={{
                      boder: "none",
                      width: "100%",
                      height: "100%",
                      borderRadius: 22,
                      marginBottom: 24,
                    }}
                  />
                </div>
              ) : (
                <div />
              )}
              <div className="">
                <div className="list-detail-wrapper">
                  <div>
                    <p className="list-user-name">{title}</p>
                    <p
                      className="list-title"
                      dangerouslySetInnerHTML={{ __html: post_body }}
                    ></p>

                    <p className="timestamp">{moment(createdAt).fromNow()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostDetailView;
