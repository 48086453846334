import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import withAuthorization from "../session/withAuthorization";
import firebase from "firebase/compat/app";
import { startUserProps } from "../../actions/user";
import { fetchRecentNews } from "../../actions/recent_news";
import { Grid, Col } from "react-bootstrap";
import _ from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import Layout from "../layout/Layout";
import no_order_img from "../../assets/images/no_order_img@2x.png";
import LocalMap from "./LocalMap";
import LocalDirectory from "./LocalDirectory";
import SidebarContentAuth from "./SidebarContentAuth";
import DealsMenu from "./DealsMenu";

class Dashboard extends Component {
  state = {
    isLoading: false,
    profileData: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      profileData: {},
      timelinePosts: [],
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { uid } = this.props.user;
    firebase
      .database()
      .ref(`timeline/${uid}`)
      .once("value")
      .then((snapshot) => {
        const timelinePosts = _.reverse(_.toArray(snapshot.val()));
        this.setState({ timelinePosts });
      });
    this.props.startUserProps();
    this.props.fetchRecentNews();
  }

  okienews = () => (
    <div>
      <h1>News</h1>
    </div>
  );

  render() {
    const { orders, createdAt } = this.props.user;
    const orderArr = _.toArray(orders);
    const recentNewsArr = this.props.recent_news;
    const timelinePostArr = this.state.timelinePosts;

    var status_color = (order_status) => {
      switch (order_status) {
        case "Completed":
          return "#4CAF50";
        case "Pending":
          return "#2196F3";
        case "Ready for pick up":
          return "#4CAF50";
        case "Cancelled":
          return "#ff1744";
        default:
          return "#212121";
      }
    };

    return (
      <Layout>
        <Grid fluid style={{ paddingTop: 32, backgroundColor: "#F5F5F5" }}>
          <div>
            <Col md={2} lg={2} smHidden xsHidden>
              <SidebarContentAuth />
            </Col>

            <Col md={2} lg={2} smHidden xsHidden className="no-margin">
              {recentNewsArr.length === 0 ? (
                <h2>
                  loading...
                  <span role="img" aria-label="sad emoji">
                    🐣
                  </span>
                </h2>
              ) : (
                recentNewsArr.slice(0, 3).map((post) => (
                  <Link to={`/blog/${post.slug}/`} key={post.id}>
                    <div className="list-card-style">
                      {post.img_url ? (
                        <div>
                          <img
                            src={post.img_url}
                            alt="post"
                            style={{
                              boder: "none",
                              width: "100%",
                              height: "100%",
                              borderTopRightRadius: 22,
                              borderTopLeftRadius: 22,
                            }}
                          />
                        </div>
                      ) : (
                        <div />
                      )}

                      <div className="list-card">
                        <div className="list-detail-wrapper">
                          <div>
                            <p className="list-user-name">{post.title}</p>
                            <p className="timestamp">
                              {moment(post.createdAt).fromNow()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </Col>

            <Col md={8} sm={12}>
              <LocalMap />
              <DealsMenu />
            </Col>
          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.shelf.products,
  user: state.user,
  authUser: state.sessionState.authUser,
  profile: state.profile,
  recent_news: state.recent_news,
});

const mapDispatchToProps = (dispatch) => ({
  startUserProps: () => dispatch(startUserProps()),
  fetchRecentNews: () => dispatch(fetchRecentNews()),
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
