import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import LikeButton from  "../timeline/LikeButton"
import "../../styles/App.css";

class UserCard extends Component {
  render(){
    const { title, createdAt, photoURL, displayName, id, likes, img_url, verified } = this.props
    return(
      <a href={`/u/${displayName}/`}>      
        <div className="list-card">

            <div className="list-profile-avatar-wrapper">
              <img src={photoURL} className="user-search-profile-avatar" alt="profile img"/>
            </div>
            
            <div className="list-detail-wrapper">
              <div>
                <p className="list-user-name">{displayName} {verified ? <span className="verified"></span> : <span></span> }</p>
              </div>
            </div>
            
        </div>
      </a>
    )
  }
}

export default UserCard