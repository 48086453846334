// BUSINESS Reducer
import { ADD_BUSINESS, SET_BUSINESS } from '../actions/processors'

const businessReducerDefaultState = [];

export default (state = businessReducerDefaultState, action) => {
  switch (action.type) {
    case ADD_BUSINESS:
    return [
      ...state,
      action.business
    ];
    case SET_BUSINESS:
      return action.business;
    default:
      return state;
  }
};
