import React from "react";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import landing_image from "../../assets/images/business_page.png";
import { Grid, Col, Button } from "react-bootstrap";
import Layout from "../layout/Layout";
import white_logo from "../../assets/images/logo_white.svg";
import owf_weed_image from "../../assets/images/owf-weed-image.jpg";
import dashboard_screenshot from "../../assets/images/dashboard_screenshot.png";
import PriceRange from "./PriceRange";
import { Helmet } from "react-helmet";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Business | Okie Weed Finder</title>
    <link rel="canonical" href="https://okieweedfinder.com/" />
  </Helmet>
);

const BusinessHeader = () => {
  return (
    <Grid style={{ marginTop: 80 }}>
      <Col md={6} smHidden xsHidden>
        <h2 className="header-h2" style={{ color: "#212121" }}>
          #cannabis #sales #tools{" "}
        </h2>
        <h1 className="header-h1" style={{ color: "#212121" }}>
          Empowering <br /> Oklahoma's <br /> Medical Marijuana Community
        </h1>
        <Link to={routes.SIGN_UP_BUSINESS}>
          <Button
            className="btn-lg for-business-btn"
            style={{ marginTop: 8, marginRight: 8 }}
          >
            <p style={{ color: "#FFFFFF" }}>
              <strong>🇺🇸 Contact Sales</strong>
            </p>
          </Button>
        </Link>
      </Col>
      <Col md={6} sm={12}>
        <div style={{ marginTop: 50 }}>
          <img src={landing_image} className="header-img" draggable="false" />
        </div>
      </Col>
      <Col sm={12} xs={12} mdHidden lgHidden>
        <h2 className="header-mobile">#local #cannabis #deals </h2>
        {/* <h1 className="header-h1-mobile">Discover Oklahoma's <br/> Top Medical Marijuana <br/> Deals near you</h1> */}
      </Col>
    </Grid>
  );
};

const BusinessDashboard = () => {
  return (
    <Grid style={{ marginTop: 16 }}>
      <center>
        <Col md={12} smHidden xsHidden className="no-margin">
          <h1 className="header-h1" style={{ color: "#212121" }}>
            Dashboard
          </h1>
          <h1 className="header-h2" style={{ color: "#212121" }}>
            Effortlessly Connect with Nearby Patients using our Powerful
            Software Tools
          </h1>
        </Col>
      </center>
      <Col md={12} sm={12} className="no-margin">
        <div>
          <img
            src={dashboard_screenshot}
            className="bussiness-dashboard-img"
            draggable="false"
          />
        </div>
      </Col>
    </Grid>
  );
};

const WeedFullWidth = () => {
  return (
    <Grid fluid>
      <div className="business-full-width-cta-container">
        <div className="container">
          <center>
            <div>
              <div style={{ marginRight: 8 }}>
                <img src={white_logo} width={380} />
              </div>
              <Link to={routes.SIGN_UP}>
                <Button
                  className="btn-lg add-new-post-btn"
                  style={{ marginTop: 8, marginRight: 8 }}
                >
                  <p style={{ color: "#FFFFFF" }}>
                    <strong>Join Now! </strong>
                  </p>
                </Button>
              </Link>
            </div>
          </center>
        </div>
      </div>
    </Grid>
  );
};

const BusinessDashboardDispensaries = () => {
  return (
    <Grid style={{ marginTop: 80 }}>
      <Col md={6} smHidden xsHidden>
        <h2 className="header-h2" style={{ color: "#212121" }}>
          #business #tools #orders{" "}
        </h2>
        <h1 className="header-h1" style={{ color: "#212121" }}>
          Online Menu
        </h1>
        <h1 className="header-h2" style={{ color: "#212121" }}>
          Increase orders with online menu
        </h1>
      </Col>
      <Col md={6} sm={12} style={{ overflow: "hidden" }}>
        <div>
          <img
            src={dashboard_screenshot}
            className="bussiness-dashboard-img"
            draggable="false"
          />
        </div>
      </Col>
      <Col sm={12} xs={12} mdHidden lgHidden>
        <h2 className="header-mobile">#local #cannabis #deals </h2>
        <h1 className="header-h1-mobile">
          Discover Oklahoma's <br /> Top Medical Marijuana <br /> Deals near you
        </h1>
      </Col>
    </Grid>
  );
};

const BusinessPrice = () => {
  return (
    <Grid style={{ marginTop: 32 }}>
      <center>
        <Col md={12} smHidden xsHidden className="no-margin">
          <h1 className="header-h1">Pricing</h1>
          <h1 className="header-h2">Always free.</h1>
          <div></div>
        </Col>
      </center>
    </Grid>
  );
};

const ScheduleDemo = () => {
  return (
    <Grid
      fluid
      style={{
        marginTop: 32,
        paddingTop: 64,
        paddingBottom: 64,
        // backgroundColor: "#f3f3f3",
        background: `rgb(21,255,0)`,
        background: `linear-gradient(90deg, rgba(21,255,0,1) 0%, rgba(166,255,132,1) 35%, rgba(0,212,255,1) 100%)`,
      }}
    >
      <center>
        <Col md={12} smHidden xsHidden className="no-margin">
          <h2 className="header-h2" style={{ color: "#212121" }}>
            #demo #features
          </h2>
          <h1 className="header-h1" style={{ color: "#212121" }}>
            Schedule A Demo
          </h1>
          <h1 className="header-h2" style={{ color: "#212121" }}>
            Our local team in Tulsa would be happy to show you around.
          </h1>
        </Col>
        <Link to={routes.CONTACT}>
          <Button
            className="btn-lg for-business-btn"
            style={{ marginTop: 8, marginRight: 8 }}
          >
            <p style={{ color: "#FFFFFF" }}>
              <strong>🇺🇸 Contact Sales</strong>
            </p>
          </Button>
        </Link>
      </center>
    </Grid>
  );
};

const BusinessLanding = () => {
  return (
    <Layout>
      <HeaderTags />
      <div style={{ marginTop: 80 }}>
        <BusinessHeader />
        <div style={{ marginTop: 120 }}>
          <BusinessDashboard />
        </div>
        <WeedFullWidth />
        <BusinessDashboardDispensaries />
        <BusinessPrice />
        <PriceRange />
        <ScheduleDemo />
      </div>
    </Layout>
  );
};
export default BusinessLanding;
