import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { db } from '../../firebase';
import withAuthorization from '../session/withAuthorization';
import { Grid, Col } from 'react-bootstrap';
import Layout from '../layout/Layout';
import List from "./List"
import { fetchTimeline } from '../../actions/posts'
import SidebarContentAuth from '../dashboard/SidebarContentAuth';

class Timeline extends Component {
  componentDidMount() {
    const { onSetUser } = this.props;
    const uid = this.props.user;

    // db.onceGetUser(displayName).then(snapshot =>
    //   onSetUser(snapshot.val())
    // );

     this.props.fetchTimeline(uid)
  }
  render() {
    const { user } = this.props;
    return (
      <Layout>
        <Grid fluid style={{height: '100vh', marginTop: 32}}>
        <div>
        <Col md={2} lg={2} smHidden xsHidden>
            <SidebarContentAuth/>
          </Col>
          <Col md={10} sm={12} xs={12}>
            {
              this.props.posts.length === 0 ? (
                <p>No posts yet...<span role="img" aria-label="sad emoji">😒</span></p>
              ) : (
                 this.props.posts.map((post) => (
                  <div className="col-md-3 col-sm-6 col-xs-6">
                    <List key={post.id} {...post} />
                  </div>
                    )
                  )
                )
            }
          </Col>
        </div>
        </Grid>
      </Layout>
    );
  }
}

const authCondition = (authUser) => !!authUser;

const mapStateToProps = (state) => ({
  user: state.user,
  posts: state.posts,
  likes: state.likes,
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = dispatch => ({
  onSetUser: (user) => dispatch({ type: 'USER_SET', user }),
  fetchTimeline: (user) => dispatch(fetchTimeline(user))
});


export default compose(withAuthorization(authCondition), connect(mapStateToProps, mapDispatchToProps))(Timeline);
