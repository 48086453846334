import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "../../styles/App.css";

class List extends Component {  
  render(){
    const { title, createdAt, photoURL, city, slug, displayName, id, uid, img_url } = this.props
    return(
              <Link to={`/u/${displayName}/p/${id}`} key={id}>
                <div className="list-card-style">

                    {img_url ? (
                      <div>
                        <img src={img_url} alt="post" style={{ boder: 'none', width: "100%", height: "100%", borderTopRightRadius: 22, borderTopLeftRadius: 22 }}/>
                      </div>
                      ) : (<div/>)}

                  <div className="list-card">
                    
                    <div className="list-profile-avatar-wrapper" style={{paddingTop: 16, paddingBottom: 16, paddingLeft: 16, paddingRight: 8}}>
                      <img src={photoURL} className="list-profile-avatar" alt="profile img" />
                    </div>

                    <div className="list-detail-wrapper">
                      <div>
                        <p className="list-user-name">{displayName}</p>
                        <p className="list-title">{title}</p>
                        <p className="timestamp">{moment(createdAt).fromNow()}</p>
                      </div>
                    </div>          

                  </div>

                </div>

            </Link>
    )
  }
}

export default List;

