import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import _ from "lodash";
import { fetchPublicProfileLikes } from "../../actions/likes";

const Liked = () => (
  <span>
    <i
      className="fa fa-heart"
      aria-hidden="true"
      style={{ color: "red", fontSize: 24 }}
    />
  </span>
);

const NotLiked = () => (
  <span>
    <i
      className="fa fa-heart"
      aria-hidden="true"
      style={{ color: "#CFD8DC", fontSize: 24 }}
    />
  </span>
);

class LikeButton extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      liked: false,
      likesCounts: 0,
      likesData: {},
      likesArr: [],
    };
    this.handleLiked = this.handleLiked.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const postId = this.props.postId;
    // this.setLikes()
    firebase.auth().onAuthStateChanged((user) => {
      if (this._isMounted) {
        // firebase.database().ref(`likes/${user.uid}/${postId}/`).once("value").then(snapshot => {
        const likeRef = firebase.database().ref(`likes/${user.uid}/${postId}`);
        likeRef.on("value", (snapshot) => {
          const likeTemp = snapshot.val();

          if (likeTemp === null) {
            this.setState({ liked: false });
          } else {
            this.setState({ liked: true });
          }
        });
      }
    });
  }

  handleLiked() {
    alert(
      "Download the app from Google PlayStore or the AppStore to like posts and more."
    );
  }
  // handleLiked() {
  //   const uid = firebase.auth().currentUser.uid
  //   const postId = this.props.postId
  //   const postUID = this.props.postUID;

  //   if(!this.state.liked){
  //     let newCount = this.props.postLikes;

  //     this.setState(prevState => ({
  //       liked: !this.state.liked,
  //       likesCounts: newCount + 1
  //     }));

  //     const likeBody = true;
  //     const likesCountBody = this.props.postLikes + 1;

  //     let updates = {}

  //     updates[`/users/${postUID}/posts/${postId}/likes/` + uid] = likeBody
  //     updates[`/likes/${uid}/${postId}`] = likeBody
  //     updates[`/users/${postUID}/posts/${postId}/likeCounts/`] = likesCountBody

  //     firebase.database().ref().update(updates)
  //   }

  //   if(!!this.state.liked){
  //     let newCount = this.props.postLikes;

  //     this.setState(prevState => ({
  //       liked: !this.state.liked,
  //       likesCounts: prevState.newCount - 1
  //     }));

  //     firebase.database().ref(`/likes/${uid}/${postId}`).remove().then(() => {
  //       firebase.database().ref(`/users/${postUID}/posts/${postId}/likes/${uid}`).remove()
  //     }).then(() =>{
  //       let updates = {}
  //       const likesCountBody = this.props.postLikes - 1;
  //       updates[`/users/${postUID}/posts/${postId}/likeCounts/`] = likesCountBody

  //       firebase.database().ref().update(updates)
  //     })

  //   }

  // }

  likeButton() {
    return this.state.liked === true ? <Liked /> : <NotLiked />;
  }

  render() {
    let likesCounter = this.state.likesCounts
      ? this.state.likesCounts
      : this.props.postLikes;
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <span onClick={this.handleLiked}>{this.likeButton()}</span>
        </div>
        <div style={{ marginLeft: 6 }}>
          <p style={{ color: "#CFD8DC", fontWeight: "bold", fontSize: 14 }}>
            {likesCounter}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
});

// const mapDispatchToProps = dispatch => ({
//   fetchPublicProfileLikes: (uid, id) => dispatch(fetchPublicProfileLikes(uid, id)),
// });

export default connect(mapStateToProps, undefined)(LikeButton);
