export const LANDING = '/';
export const DASHBOARD = '/dashboard';
export const TIMELINE = '/feed/';
export const DASHBOARD_PRODUCTS = '/dashboard/products';
export const DISPENSARIES_DASHBOARD = '/dashboard/dispensaries';
export const DISPENSARIES = '/dispensaries';
export const DISPENSARY_PROFILE = '/dispensaries/:city/:slug';
export const DISPENSARY_PRODUCT_DETAIL = '/dispensaries/:city/:slug/products/:id';
export const DOCTORS = '/doctors';
export const DOCTOR_PROFILE = '/doctors/:id/:uid';
export const PROCESSORS = '/processors';
export const PROCESSOR_PROFILE = '/processors/:id/:uid';
export const GROWERS = '/growers';
export const GROWER_PROFILE = '/growers/:id/:uid';
export const TESTING_LABS = '/testing-labs';
export const TESTING_LAB_PROFILE = '/testing-labs/:id/:uid';
export const BLOG = '/blog';
export const AVAILABLE_FORMS = '/available-forms';
export const MEDICAL_MARIJUANA = '/medical-marijuana';
export const BLOG_DETAIL = '/blog/:slug';
export const CONTACT = '/contact';
export const ORDER_DETAIL = '/dashboard/order/:id';
export const EXPLORE = '/explore/';
export const REVIEWS = '/reviews/';
export const ACTIVITY = '/activity/users';
export const SIGN_UP = '/signup/user';
export const SIGN_UP_BUSINESS = '/signup/business';
export const SIGN_IN = '/signin/user';
export const PASSWORD_FORGET = '/forgot-password/user';
export const ACCOUNT = '/account/user';
export const USER_PROFILE = '/u/:uid';
export const USER_PROFILE_DETAIL = '/u/:uid/p/:id';
export const FOLLOWING_FRIENDS = '/u/:uid/following';
export const FOLLOWERS_FRIENDS = '/u/:uid/followers';
export const BUSINESS_NEW = '/business/new';
export const PRODUCT_NEW = '/product/new';
export const NOTIFICATION_NEW = '/notification/new';
export const POST_NEW = '/post/new';
export const PRODUCT_EDIT = '/edit/:id';
export const BUSINESS_LANDING = '/business/';
export const APP = '/terms/legal/privacy/';
export const ABOUT = '/legal/about/';
export const PRIVACY = '/legal/privacy/';
export const TERMS = '/legal/terms/';
export const APP_ABOUT = '/app/about/';
export const APP_PRIVACY = '/app/privacy/';
export const APP_TERMS = '/app/terms/';
export const DOWNLOAD = '/download/';
export const careers = '/download/';
export const CAREERS = '/careers/';
export const ROOM = '/room/:roomID';
// export const DISCOVERY = '/explore/users';