import React from 'react';
import { connect } from 'react-redux';
import NotificationForm from './NotificationForm';
import { startAddNotification } from '../../actions/notifications';
import Layout from '../layout/Layout';
import { Grid } from 'react-bootstrap'

export class AddNotificationView extends React.Component {
  onSubmit = (notification) => {
    this.props.startAddNotification(notification);
    this.props.history.push('/dashboard');
  };
  render() {
    return (
      <Layout>
        <Grid>
          <div style={{ marginTop: 80, marginBottom: 80 }}>
            <NotificationForm
              onSubmit={this.onSubmit}
            />
          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddNotification: (notification) => dispatch(startAddNotification(notification))
});

export default connect(undefined, mapDispatchToProps)(AddNotificationView);
