import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import firebase from 'firebase/compat/app';
import Layout from '../layout/Layout';
import _ from "lodash";
import ProfileSidebar from './ProfileSidebar';
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';
import { fetchProducts } from '../../services/shelf/actions';
import '../Shelf/style.scss';

class DoctorProfile extends Component {
  static propTypes = {
    fetchProducts: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    filters: PropTypes.array,
    sort: PropTypes.string
  };

  state = {
    isLoading: false,
    profileData: {}
  };

  constructor(props){
    super(props)
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      profileData: [],
      profileDataListing: [],
    };
  }
  
  componentDidMount() {
    
    const slug = this.props.match.params.uid
    const city = this.props.match.params.id
   
    this.mounted = true;

      if (this.mounted) {


        firebase.database().ref(`doctors`).orderByChild("city").equalTo(city).once('value').then(snap => {
          if (snap.val()) {

            this.setState({ isEmpty: false });

            let placeArr = _.toArray(snap.val())

            const profileData = placeArr.find(profile => profile.slug === slug);
            
            this.setState({ profileData });

          } else {
            this.setState({ isEmpty: true });
          }
          this.setState({ isLoading: false });
        })

      }
      
      this.handleFetchProducts();
      
  }
  
  componentWillReceiveProps(nextProps) {
    const { filters: nextFilters, sort: nextSort } = nextProps;
    const { filters } = this.props;
    
    if (nextFilters.length !== filters.length) {
      this.handleFetchProducts(nextFilters, undefined);
    }

    if (nextSort !== this.props.sort) {
      this.handleFetchProducts(undefined, nextSort);
    }

  }

  componentWillUnmount(){
    this.handleFetchProducts([]);
    this.setState({
      profileDataListing: [],
      profileData: null
    });
  }

  handleFetchProducts = ( filters = this.props.filters, sort = this.props.sort) => {
    this.setState({ isLoading: true });
    const slug = this.props.match.params.uid
    const city = this.props.match.params.id
    this.props.fetchProducts(filters, sort, city, slug,  () => { this.setState({ isLoading: false });} );
  };

  render() {

    const {  displayName, type, city, state } = _.toPlainObject(this.state.profileData)
    
    return (
      <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title> { `${displayName } ${type} in ${city}, ${state}` }</title>
        </Helmet>

        <div className="container-fluid" style={{marginTop: 90}}>
          <div className="app-wrapper">    
            <div className="col-md-12 col-sm-12 col-xs-12">
              <ProfileSidebar profileData={this.state.profileData}/>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="col-md-10 col-sm-12 col-xs-12"></div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}



const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
  products: state.shelf.products,
  filters: state.filters.items,
  sort: state.sort.type
});


export default compose(connect(mapStateToProps, { fetchProducts }))(DoctorProfile);
