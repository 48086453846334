import React, { Component } from "react";
import { connect } from "react-redux";
import { startAddReview } from "../../actions/reviews";
import _ from "lodash";
import Review from "./Review";
import no_reviews from "../../assets/images/no-reviews@2x.png";
class ReviewsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
      isLoading: true,
      isEmpty: false,
      isFinished: false,
    };
  }

  onSubmit = (reviewBody) => {
    this.props.startAddComment(reviewBody);
  };

  render() {
    const reviewsArray = _.reverse(_.toArray(this.props.reviews));
    console.log("reviewsArray::: ", reviewsArray);
    return (
      <div>
        <div>
          {reviewsArray.length === 0 ? (
            <div
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: 22,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "6%",
                marginTop: 32,
                marginBottom: 32,
              }}
            >
              <div>
                <img
                  style={{ width: 300 }}
                  src={no_reviews}
                  alt="default state"
                />
              </div>
              <div style={{ marginTop: 24 }}>
                <p style={{ fontWeight: 600 }}>
                  Be the first to write a review
                  <span role="img" aria-label="star emoji">
                    {" "}
                    ⭐️
                  </span>
                </p>
              </div>
            </div>
          ) : (
            reviewsArray.map((props, key) => (
              <div className="col-md-12 col-sm-12 col-xs-12">
                <Review {...props} key={key} />
              </div>
            ))
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reviews: state.reviews,
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch, props) => ({
  startAddComment: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsView);
