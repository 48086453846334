import React from 'react';
import loader from "../../assets/images/loader.gif"

const LoadingPage = () => (
  <div className="loader">
    <div>
      <img className="loader__image" src={loader} alt="loading" />
    </div>
  </div>
);

export default LoadingPage;
