import React, { Component } from "react";
import {Col, Form, FormGroup, FormControl, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { startUserProps } from '../../actions/userProps';
import { startEditUser  } from '../../actions/user';
import firebase from 'firebase/compat/app';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AccountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayName: props.userProps ? props.userProps.displayName : '',      
      bio: props.userProps ? props.userProps.bio : '',
      city: props.userProps ? props.userProps.city : '',
      website: props.userProps ? props.userProps.website : '',
      phonenumber: props.userProps ? props.userProps.phonenumber : '',
      error: '', 
    }
  }
  state = {
    displayName: '',
    bio: '',
    city: '',
    website: '',
    phonenumber: '',
    error: ''
  }

  onBioChange = (e) => {
    const bio = e.target.value;

    if (bio && bio.length > 151) {
      this.setState({error: 'Maximum of 150 characters'})
    }
    this.setState(() => ({ bio }));
  }

  onCityChange = (e) => {
    const city = e.target.value;

    if (city && city.length > 15) {
      this.setState({error: 'Maximum of 15 characters'})
    }
    this.setState(() => ({ city }));
  }
  
  onWebsiteChange = (e) => {
    const website = e.target.value;
    this.setState(() => ({ website }));
  }
  
  onPhonenumberChange = (e) => {
    const phonenumber = e.target.value;
    this.setState(() => ({ phonenumber }));
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState(() => ({ error: '' }))
    
    const { uid } = this.props.userProps

    this.setState(() => ({ error: '' }))

    let updates ={
      bio: this.state.bio,
      website: this.state.website,
      phonenumber: this.state.phonenumber,
      city: this.state.city
    }

    firebase.database().ref(`users/${uid}/`).update(updates)

    toast("Changes successfully saved");
    
    
    
    // this.props.onSubmit({
    //   bio: this.state.bio,
    //   website: this.state.website,
    //   phonenumber: this.state.phonenumber,
    //   city: this.state.city
    // })

    // let updates = {};
    // let updatesObj = {
    //   bio: this.state.bio,
    //   website: this.state.website,
    //   phonenumber: this.state.phonenumber,
    //   city: this.state.city
    // }
    // let bio = this.state.bio

    // updates[`/dispensaries/${id}/bio/`] = bio;
    // updates[`users/${uid}/bio/`] = bio;
    // updates[`users/${uid}/`] = updatesObj;

    // firebase.database().ref().update(updates)

  }

  render() {
    return (
      <div style={styles.container}>
         <ToastContainer />
        <form onSubmit={this.onSubmit}>
  
      <FormGroup>
        <Col sm={12}>
        <p><strong>Bio</strong></p>
        <FormControl
          placeholder={'Bio'}
          value={this.state.bio}
          onChange={this.onBioChange}
          style={{backgroundColor: '#EEE'}}
        />
        {this.state.error && <p>{this.state.error}</p>}
        </Col>
        
      </FormGroup>

      <FormGroup>
        <Col sm={12}>
        <p><strong>City</strong></p>
        <FormControl
          placeholder={'City'}
          value={this.state.city}
          onChange={this.onCityChange}
          style={{backgroundColor: '#EEE'}}
        />
        {this.state.error && <p>{this.state.error}</p>}
        </Col>  
      </FormGroup>
      
      <FormGroup>
        <Col sm={12}>
        <p><strong>Website</strong></p>
        <FormControl
          placeholder={'Website'}
          value={this.state.website}
          onChange={this.onWebsiteChange}
          style={{backgroundColor: '#EEE'}}
        />
        {this.state.error && <p>{this.state.error}</p>}
        </Col>  
      </FormGroup>
      
      <FormGroup>
        <Col sm={12}>
        <p><strong>Phone</strong></p>
        <FormControl
          placeholder={'Phone number'}
          value={this.state.phonenumber}
          onChange={this.onPhonenumberChange}
          style={{backgroundColor: '#EEE'}}
        />
        {this.state.error && <p>{this.state.error}</p>}
        </Col>  
      </FormGroup>
      
        <Button style={styles.submitUpdatesButton} onClick={this.onSubmit}>
          <p style={styles.submitButtonText}>Update Account</p>
        </Button>
      </form>
    </div>
    )
  }
}




const mapStateToProps = state => ({
  userProps: state.userProps
});

const mapDispatchToProps = dispatch => ({
  startUserProps: () => dispatch(startUserProps()),
});


export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);


const styles = {
  container: {
    padding: 16,
  },
  privateInfoContainer: {
    paddingTop: 20,
    paddingLeft: 16
  },
  privateInfoText:{
    color: '#9E9E9E',
    fontWeight: 'bold'
  },
  userAccountTextInput: {
    flexDirection: 'row',
  },
  searchFieldContainer: {
    padding: 10,
    margin: 10,
    backgroundColor: '#FFFFFF',
    height: 40,
    flex: 1,
    borderColor: '#CCCCCC',
    borderBottomWidth: 1,
  },
  leftTitleContainer: {
    width: 90,
    paddingTop: 20,
    fontWeight: 'bold'
  },
  submitUpdatesButton: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: '#304FFE',
    padding: 10,
    margin: 10,
    backgroundColor: '#304FFE',
    height: 40,
  },
  submitButtonText: {
    color: '#ffffff',
    textAlign: 'center'
  },
}
