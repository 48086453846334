const cities = [
  {city_name: 'Change city'},
  {city_name: 'Ardmore'},
  {city_name: 'Anadarko'},
  {city_name: 'Arapaho'},
  {city_name: 'Arcadia'},
  {city_name: 'Arkadelphia'},
  {city_name: 'Arkoma'},
  {city_name: 'Bartlesville'},
  {city_name: 'Bentonville'},
  {city_name: 'Bethany'},
  {city_name: 'Blanchard'},
  {city_name: 'Broken Arrow'},
  {city_name: 'Broken Bow'},
  {city_name: 'Calera'},
  {city_name: 'Claremore'},
  {city_name: 'Carnegie'},
  {city_name: 'Cartwright'},
  {city_name: 'Chandler'},
  {city_name: 'Chickasha'},
  {city_name: 'Noble'},
  {city_name: 'Choctaw'},
  {city_name: 'Claremore'},
  {city_name: 'Clarksville'},
  {city_name: 'Cookson'},
  {city_name: 'Cordell'},
  {city_name: 'Coyle'},
  {city_name: 'Del City'},
  {city_name: 'Duncan'},
  {city_name: 'Edmond'},
  {city_name: 'Elgin'},
  {city_name: 'El Reno'},
  {city_name: 'Elk city'},
  {city_name: 'Enid'},
  {city_name: 'Goldsby'},
  {city_name: 'Grove'},
  {city_name: 'Harrah'},
  {city_name: 'Henryetta'},
  {city_name: 'Holdenville'},
  {city_name: 'Hot Springs'},
  {city_name: 'Hugo'},
  {city_name: 'Hydro'},
  {city_name: 'Jenks'},
  {city_name: 'Lawton'},
  {city_name: 'Lexington'},
  {city_name: 'Lone Grove'},
  {city_name: 'Marlow'},
  {city_name: 'McAlester'},
  {city_name: 'Mead'},
  {city_name: 'Meeker'},
  {city_name: 'Midwest City'},
  {city_name: 'Mustang'},
  {city_name: 'Minco'},
  {city_name: 'Monkey island'},
  {city_name: 'Moore'},
  {city_name: 'Muskogee'},
  {city_name: 'Newcastle'},
  {city_name: 'Norman'},
  {city_name: 'Nicoma Park'},
  {city_name: 'Oklahoma City', center: {lat: 35.4825666, lng: -97.6196207}},
  {city_name: 'Okmulgee'},
  {city_name: 'Owasso'},
  {city_name: 'Pauls Valley'},
  {city_name: 'Pawhuska'},
  {city_name: 'Piedmont'},
  {city_name: 'Poteau'},
  {city_name: 'Prague'},
  {city_name: 'Pryor'},
  {city_name: 'Purcell'},
  {city_name: 'Roland'},
  {city_name: 'Russellville'},
  {city_name: 'Sallisaw'},
  {city_name: 'Sand Springs'},
  {city_name: 'Sapulpa'},
  {city_name: 'Sayre'},
  {city_name: 'Seminole'},
  {city_name: 'Shawnee'},
  {city_name: 'Skiatook'},
  {city_name: 'Slaughterville'},
  {city_name: 'Stillwater'},
  {city_name: 'Tahlequah'},
  {city_name: 'Tecumseh'},
  {city_name: 'The Village'},
  {city_name: 'Tulsa'},
  {city_name: 'Watts'},
  {city_name: 'Warr Acres'},
  {city_name: 'Weatherford'},
  {city_name: 'Wellston'},
  {city_name: 'Wewoka'},
  {city_name: 'Woodward'},
  {city_name: 'Yukon'},
];

export {cities};
