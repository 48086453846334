import firebase from 'firebase/compat/app';


export const FETCH_PROFILE_PROPS = "FETCH_PROFILE_PROPS";

export function fetchUserProfileProps(username) {
  return dispatch => {
    
    
      // const { uid = null } = user;
      firebase.database().ref().child("users").orderByChild("username").equalTo(username).once("value", snapshot  => {
          let payload = snapshot.val();
          
          console.log(payload, "SOME PAYLOAD")
          
          dispatch({
            type: FETCH_PROFILE_PROPS,
            payload
          })

        }).catch(err => console.log(err, "Something went wrong"))
  }
}


