import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import firebase from "firebase/compat/app";
import FileUploader from "react-firebase-file-uploader";
import { Checkbox } from "react-bootstrap";
import _ from "lodash";
// var product_type = [
//   { type: 'Select product type', product_code: 'DEFAULT' },
//   { type: 'Flower', product_code: 'FL' },
//   { type: 'Sativa', product_code: 'ST' },
//   { type: 'Indica', product_code: 'ID' },
//   { type: 'Hybrid', product_code: 'HB' },
//   { type: 'Concentrate', product_code: 'CT' },
//   { type: 'Vapes', product_code: 'VP' },
//   { type: 'Edibles', product_code: 'ED' },
//   { type: 'Other', product_code: 'OTHER' }
// ];

class ProductForm extends React.Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);

    this.state = {
      createdAt: props.product ? moment(props.product.createdAt) : moment(),
      displayName: this.props.user.displayName,
      store_name: this.props.user.store_name,
      city: this.props.user.city,
      photoURL: this.props.user.photoURL,
      productNotes: props.product ? props.product.productNotes : "",
      productName: this.props.product.productName,
      productThc: props.product ? props.product.productThc : "",
      productCbd: props.product ? props.product.productCbd : "",
      manufacturer: props.product ? props.product.manufacturer : "",
      price: props.product ? props.product.price : 0,
      one_gram: props.product ? props.product.one_gram : 0,
      one_eight: props.product ? props.product.one_eight : 0,
      one_quarter: props.product ? props.product.one_quarter : 0,
      one_half: props.product ? props.product.one_half : 0,
      one_ounce: props.product ? props.product.one_ounce : 0,
      type: props.product ? props.product.type : "",
      hidden: props.product ? props.product.hidden : false,
      tags: props.product ? props.product?.tags : [],
      id: props.product ? props.product.id : "",
      img_url: props.product ? props.product.img_url : "",
      uid: firebase.auth().currentUser.uid,
      currencyFormat: props.product ? props.product.currencyFormat : "$",
      priceRange: props.product ? props.product.priceRange : "",
      // productCategories: product_type,
      product_type: props.product ? props.product.product_type : "",
      file: [null],
      isUploading: false,
      uploadProgress: 0,
      error: "",
    };
  }

  // Product Type
  onCategoryChange = (e) => {
    this.setState(() => {
      return {
        type: e,
      };
    });
  };

  // Product Name
  onProductNameChange = (e) => {
    const productName = e.target.value;
    this.setState(() => ({ productName }));
  };

  // Product Description / Notes
  onDescriptionChange = (e) => {
    const productNotes = e.target.value;
    this.setState(() => ({ productNotes }));
  };

  // Product THC
  onProductThcChange = (e) => {
    const productThc = e.target.value;
    this.setState(() => ({ productThc }));
  };

  // Product CBD
  onProductCbdChange = (e) => {
    const productCbd = e.target.value;
    this.setState(() => ({ productCbd }));
  };

  // Product Manufacturer
  onProductManufacturerChange = (e) => {
    const productManufacturer = e.target.value;
    this.setState(() => ({ manufacturer: productManufacturer }));
  };

  // Product Tags
  onTagChange = (e) => {
    this.setState((prevState) => {
      return {
        tags: prevState.tags.concat(e),
      };
    });
  };

  // Product Hidden
  onHiddenChange = () => {
    if (this.state.hidden === true) {
      this.setState(() => ({ hidden: false }));
    }

    this.setState(() => ({ hidden: true }));
  };

  // Product Price Range
  onProductPriceRangeChange = (e) => {
    const price = e.target.value;
    this.setState(() => ({ priceRange: price }));
  };

  // Product One Gram
  onProductPriceChangeOneGram = (e) => {
    const priceOneGram = e.target.value;
    this.setState(() => ({ one_gram: priceOneGram }));
  };

  // Product One Eight
  onProductPriceChangeOneEight = (e) => {
    const priceOneEight = e.target.value;
    this.setState(() => ({ one_eight: priceOneEight }));
  };

  // Product One Quarter
  onProductPriceChangeOneQuarter = (e) => {
    const priceOneQuarter = e.target.value;
    this.setState(() => ({ one_quarter: priceOneQuarter }));
  };

  // Product One Half
  onProductPriceChangeOneHalf = (e) => {
    const priceOneHalf = e.target.value;
    this.setState(() => ({ one_half: priceOneHalf }));
  };

  // Product One Ounce
  onProductPriceChangeOneOunce = (e) => {
    const priceOneOunce = e.target.value;
    this.setState(() => ({ one_ounce: priceOneOunce }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.productName) {
      this.setState(() => ({ error: "Please provide product name." }));
    } else {
      this.setState(() => ({ error: "" }));
      this.props.onSubmit({
        id: this.state.id,
        store_name: this.state.store_name,
        displayName: this.props.user.displayName,
        slug: this.props.user.slug,
        city: this.props.user.city,
        productCategories: [this.state.type],
        type: this.state.type,
        productName: this.state.productName,
        productNotes: this.state.productNotes,
        productThc: this.state.productThc,
        productCbd: this.state.productCbd,
        hidden: this.state.hidden,
        manufacturer: this.state.manufacturer,
        tags: this.state.tags ? this.state.tags : [],
        price: Number(this.state.price),
        priceRange: this.state.priceRange,
        weights: {
          one_gram: Number(this.state.one_gram),
          one_eight: Number(this.state.one_eight),
          one_quarter: Number(this.state.one_quarter),
          one_half: Number(this.state.one_half),
          one_ounce: Number(this.state.one_ounce),
        },
        one_gram: Number(this.state.one_gram),
        one_eight: Number(this.state.one_eight),
        one_quarter: Number(this.state.one_quarter),
        one_half: Number(this.state.one_half),
        one_ounce: Number(this.state.one_ounce),
        img_url: this.state.img_url,
        createdAt: this.state.createdAt.valueOf(),
        uid: this.state.uid,
        photoURL: this.props.user.photoURL,
      });
    }
  };

  // UPLOAD CODE

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0,
    });

  handleProgress = (progress) =>
    this.setState({
      uploadProgress: progress,
    });

  handleUploadError = (error) => {
    this.setState({
      isUploading: false,
      // Todo: handle error
    });
    console.error(error);
  };

  handleUploadSuccess = (filename) => {
    this.setState({ img_url: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref(`users/${this.state.uid}/products/${this.state.id}/`)
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ img_url: url }));
  };

  render() {
    const type_check = this.state.product_type;
    const hidden_check = this.state.hidden;
    return (
      <div className="container-fluid" style={{ marginTop: 60 }}>
        <form onSubmit={this.onSubmit}>
          {this.state.error && (
            <p className="form__error">{this.state.error}</p>
          )}

          <div className="col-md-9">
            <div className="row">
              <div className="form-group">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h4>Edit product</h4>
                  <br />
                  <div>
                    <div>
                      <h4>Type</h4>
                    </div>
                    <div>
                      <p>Select at least one category type</p>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#EEEEEE",
                        padding: 8,
                        borderRadius: 8,
                        marginBottom: 16,
                      }}
                    >
                      <div
                        style={{
                          marginBottom: 16,
                          marginTop: 16,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={
                                type_check ? type_check === "Sativa" : true
                              }
                              onClick={() => this.onCategoryChange("Sativa")}
                            />
                          </div>
                          <div>
                            <p>Sativa</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={
                                type_check ? type_check === "Indica" : true
                              }
                              onClick={() => this.onCategoryChange("Indica")}
                            />
                          </div>
                          <div>
                            <p>Indica</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={
                                type_check ? type_check === "Hybrid" : true
                              }
                              onClick={() => this.onCategoryChange("Hybrid")}
                            />
                          </div>
                          <div>
                            <p>Hybrid</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={
                                type_check ? type_check === "Concentrate" : true
                              }
                              onClick={() =>
                                this.onCategoryChange("Concentrate")
                              }
                            />
                          </div>
                          <div>
                            <p>Concentrate</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={
                                type_check ? type_check === "Vapes" : true
                              }
                              onClick={() => this.onCategoryChange("Vapes")}
                            />
                          </div>
                          <div>
                            <p>Vapes</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={
                                type_check ? type_check === "Edibles" : true
                              }
                              onClick={() => this.onCategoryChange("Edibles")}
                            />
                          </div>
                          <div>
                            <p>Edibles</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={
                                type_check ? type_check === "Deal" : true
                              }
                              onClick={() => this.onCategoryChange("Deal")}
                            />
                          </div>
                          <div>
                            <p>Deal</p>
                          </div>
                        </div>
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={
                                type_check ? type_check === "Other" : true
                              }
                              onClick={() => this.onCategoryChange("Other")}
                            />
                          </div>
                          <div>
                            <p>Other</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4>Product Details</h4>
                    </div>
                    <div>
                      <p>
                        Enter information, grower, organic, test lab results
                      </p>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#EEEEEE",
                        padding: 8,
                        borderRadius: 8,
                        marginBottom: 16,
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="Product name"
                          value={this.state.productName}
                          onChange={this.onProductNameChange}
                        />
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginTop: 16,
                          marginBottom: 16,
                        }}
                      >
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="Product notes"
                          value={this.state.productNotes}
                          onChange={this.onDescriptionChange}
                          row={4}
                        />
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginBottom: 16,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "100%", marginRight: 8 }}>
                          <input
                            className="product_form_price_input"
                            type="text"
                            placeholder="THC%"
                            value={this.state.productThc}
                            onChange={this.onProductThcChange}
                            row={4}
                          />
                        </div>
                        <div style={{ width: "100%", marginLeft: 8 }}>
                          <input
                            className="product_form_price_input"
                            type="text"
                            placeholder="CBD%"
                            value={this.state.productCbd}
                            onChange={this.onProductCbdChange}
                            row={4}
                          />
                        </div>
                      </div>

                      <div style={{ width: "100%", marginBottom: 16 }}>
                        <input
                          className="product_form_price_input"
                          type="text"
                          placeholder="Product maker or manufacturer"
                          value={this.state.manufacturer}
                          onChange={this.onProductManufacturerChange}
                          row={4}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4>Price Range</h4>
                    </div>
                    <div>
                      <p>Product price range. For product display purposes</p>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderRadius: 8,
                        marginBottom: 16,
                      }}
                    >
                      <div style={{ padding: 8 }}>
                        <div>
                          <input
                            className="product_form_price_input"
                            type="text"
                            placeholder="Price"
                            value={this.state.priceRange}
                            onChange={this.onProductPriceRangeChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <h4>Price breakdown</h4>
                    </div>
                    <div>
                      <p>Product price range. For product display purposes</p>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderRadius: 8,
                        marginBottom: 16,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          marginBottom: 16,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div className="product_form_price_breakdown_container">
                          <div className="product_form_price_breakdown">
                            <div>
                              <p>1G: </p>
                            </div>
                            <div>
                              <input
                                className="product_form_price_input"
                                type="number"
                                placeholder="0"
                                value={this.state.one_gram}
                                onChange={this.onProductPriceChangeOneGram}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="product_form_price_breakdown_container">
                          <div className="product_form_price_breakdown">
                            <div>
                              <p>1/8 </p>
                            </div>
                            <div>
                              <input
                                className="product_form_price_input"
                                type="number"
                                placeholder="0"
                                value={this.state.one_eight}
                                onChange={this.onProductPriceChangeOneEight}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="product_form_price_breakdown_container">
                          <div className="product_form_price_breakdown">
                            <div>
                              <p>1/4 </p>
                            </div>
                            <div>
                              <input
                                className="product_form_price_input"
                                type="number"
                                placeholder="Price"
                                value={this.state.one_quarter}
                                onChange={this.onProductPriceChangeOneQuarter}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="product_form_price_breakdown_container">
                          <div className="product_form_price_breakdown">
                            <div>
                              <p>1/2 </p>
                            </div>
                            <div>
                              <input
                                className="product_form_price_input"
                                type="number"
                                placeholder="Price"
                                value={this.state.one_half}
                                onChange={this.onProductPriceChangeOneHalf}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="product_form_price_breakdown_container">
                          <div className="product_form_price_breakdown">
                            <div>
                              <p>1 OZ </p>
                            </div>
                            <div>
                              <input
                                className="product_form_price_input"
                                type="number"
                                placeholder="Price"
                                value={this.state.one_ounce}
                                onChange={this.onProductPriceChangeOneOunce}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4>Select Image</h4>
                    </div>
                    <div>
                      <p>Upload a pretty picture</p>
                    </div>
                    <div className="new-post-cat-container">
                      <div className="new-post-cat-inner-wrapper">
                        <FileUploader
                          accept="image/*"
                          name="image-uploader-multiple"
                          storageRef={firebase
                            .storage()
                            .ref(
                              `users/${this.state.uid}/products/${this.state.id}/`
                            )}
                          onUploadStart={this.handleUploadStart}
                          onUploadError={this.handleUploadError}
                          onUploadSuccess={this.handleUploadSuccess}
                          onProgress={this.handleProgress}
                        />
                        <p>Progress: {this.state.uploadProgress}</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4>Hide Product</h4>
                    </div>
                    <div>
                      <p>
                        Temporarily hide product when unavailable from
                        storefront
                      </p>
                    </div>
                    <div className="new-post-cat-container">
                      <div className="new-post-cat-inner-wrapper">
                        <div className="product_tag_container">
                          <div>
                            <Checkbox
                              checked={hidden_check ? hidden_check : false}
                              onClick={() => this.onHiddenChange(hidden_check)}
                            />
                          </div>
                          <div>
                            <p>Hidden</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      onClick={this.onSubmit}
                      className="btn btn-primary btn-lg"
                    >
                      Update Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <h4>Preview</h4>
            <div style={{ marginTop: 16 }}>
              <div className="list-card-style">
                <div
                  style={{
                    maxHeight: 180,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      borderRadius: 16,
                      minHeight: 220,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={this.state.img_url}
                      alt="product"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                </div>

                <div
                  className="list-card"
                  style={{ flexDirection: "row", minHeight: 120 }}
                >
                  <div style={{}}>
                    <div style={{ padding: 4 }}>
                      <p style={{ fontSize: 12, fontWeight: "bold" }}>
                        #{_.toUpper(this.state.type)}
                      </p>
                      <p style={{ fontSize: 18, fontWeight: "900" }}>
                        {this.state.productName}
                      </p>
                      <p style={{ fontSize: 12 }}>{this.state.productThc}</p>
                      <div>
                        <div className="val">
                          <p style={{ fontSize: 12 }}>
                            {" "}
                            {this.state.currencyFormat} {this.state.priceRange}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, undefined)(ProductForm);
