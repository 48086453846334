import React from "react";
import { connect } from "react-redux";
import logo from "./../../assets/images/logo_white.svg";
import { startLogout } from "../../actions/auth";

const Header = (props) => {
  
    return (
      <div>
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid" style={{ marginLeft: 16, marginRight: 16}}>
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a href="/"><img src={logo} className="app-logo-header" alt="teach stream" /></a>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="/">
                    <i className="fas fa-home nav-bar-icon"></i>
                  </a>
                </li>
                <li>
                  {/* <Link to={`/edit/${id}`}>
                    <i className="fas fa-cogs nav-bar-icon"></i>
                  </Link> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
}

const mapDispatchToProps = dispatch => ({
  startLogout: () => dispatch(startLogout())
});

export default connect(
  undefined,
  mapDispatchToProps
)(Header);
