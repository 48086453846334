import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Layout from '../layout/Layout';
import _ from "lodash";
import {Helmet} from "react-helmet";
import '../Shelf/style.scss';
import { Link } from "react-router-dom";
import Trust1 from "../../assets/images/Trust-Badge-1.png"
import Trust2 from "../../assets/images/Trust-Badge-2.png"
import Trust3 from "../../assets/images/Trust-Badge-3.png"
import Trust4 from "../../assets/images/Trust-Badge-4.png"
import Trust5 from "../../assets/images/Trust-Badge-5.png"
import Trust6 from "../../assets/images/Trust-Badge-6.png"
import ThingsToKnow from "../../assets/images/1-circle-img-medical101.png"

class AvailableForms extends Component {

  render() {


    return (
      <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Medical Marijuana 101</title>
        </Helmet>
        
        <div className="container-fluid" style={{marginTop: 90}}>
          <div className="app-wrapper">    

            <div className="wovenwire-cta-container">
              <div className="container">
                <center>
                  <div>
                    <h1 style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 48, textTransform: 'uppercase'}}>Available Forms of Medical Marijuana</h1>
                  </div>
                </center>
              </div>
            </div>

            <div className="join-cta-container">
              <div className="container" style={{backgroundColor: '#'}}>
                <center>
                  <div>
                    <h2 style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 28, textTransform: 'uppercase'}}>You Don't Have to Smoke Your Medicine Anymore.</h2>
                    <p>The medical marijuana industry has evolved over the years to provide patients with a variety of medicinal products. The most popular way to ingest medical marijuana is to smoke it, but there are more options for getting your THC. Read on to learn about the available forms of medical marijuana.</p>
                  </div>
                </center>
              </div>
            </div>

            <div className="cta-container">
              <div>
                <img src={Trust1} style={{width: '100%'}}/>    
              </div>
              <div>
                <img src={Trust2} style={{width: '100%'}}/>
              </div>
              <div>
                <img src={Trust3} style={{width: '100%'}} />
              </div>
              <div>
                <img src={Trust4} style={{width: '100%'}} />
              </div>
              <div>
                <img src={Trust5} style={{width: '100%'}}/>
              </div>
              <div>
                <img src={Trust6} style={{width: '100%'}}/>
              </div>
            </div>
            
            <div className="container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
              <div className="col-md-6">
              <h3>Medical marijuana is available in many forms, including:</h3>
              <ul>
                <li>Medical Marijuana Flower</li>
                <li>Pre-rolls</li>
                <li>Medical Cannabis Edibles</li>
                <li>Medical Marijuana Concentrates</li>
                <li>Medical Cannabis Capsules</li>
                <li>Vape Pen</li>
                <li>Sublingual Medical Cannabis</li>
                <li>Topicals</li>
              </ul>
              </div>
            </div>
            
            <div className="container">
              <div className="col-md-6">
                <h3>Medical Marijuana Flower</h3>
                Patients may choose to use medical marijuana through traditional methods, such as smoking the product via cannabis flower. Also known as a strain, this refers to the unprocessed dry flower you can buy at the medical dispensary.
                When patients smoke medical marijuana, they experience the effects within moments and have more control over them, unlike other methods that deliver a dose all at once. For example, an edible can take over an hour to reach full effect and lasts much longer.
                Smoking might be the best option for patients who are sensitive to THC or those who want to avoid an intense high since they can take small hits to medicate as needed. However, patients with impaired lung function or breathing difficulties may want to avoid smoking and opt for a different administration method. Smoking can also irritate the throat for some users.
              </div>
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
            </div>

            <div className="join-cta-container">
              <div className="container" style={{backgroundColor: '#'}}>
                <center>
                  <div>
                    <h2 style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 28, textTransform: 'uppercase'}}>Choose Your Flower Strain</h2>
                    <p>Medical dispensaries offer a variety of quality flower products, including Indica, Sativa, and hybrid strains. All strains fall somewhere on a single spectrum, some closer to 100% Sativa, others closer to 100% Indica, and depending on where it lands is how you can better determine how it might affect you. So, how do you choose the best option for your medicating needs? Here’s a quick rundown to help:</p>
                  </div>
                  <div className="container">
                    <div className="col-md-4">
                      <h4>Sativa</h4>
                      <p>Patients experience a more cerebral, energizing effect as Sativa-dominant strains tend to possess higher levels of THC. With the ability to spark creativity and conversation, this is an excellent option for daytime medicating. If you worry about anxiety or paranoia, try a Sativa with a lower percentage mix (closer to 60%).</p>
                    </div>
                    <div className="col-md-4">
                      <h4>Hybrid</h4>
                      <p>Patients get the best of both worlds. Soothe body aches without your body melting into your couch. A hybrid might be the perfect strain for you. Medical dispensaries carry several varieties to help you strike just the right balance for any planned activities.</p>
                    </div>
                    <div className="col-md-4">
                      <h4>Indica</h4>
                      <p>These strains tend to be physically sedating and promote full-body relaxation for medical marijuana patients. If you have tension in your neck, sore muscles, or just spent 8 hours sitting at a desk working, Indicas will help you shed the aches and pains of the day. Indica is great for mellow nights and getting restful sleep.</p>
                    </div>
                  </div>
                </center>
              </div>
            </div>

            <div className="container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
              <div className="col-md-6">
                <h4>Fine-Tune with THC &amp; CBD</h4>
                <p>Beyond choosing a strain, you’ll want to look at THC's ratio to CBD the medical cannabis contains.

                <strong>• THC, Tetrahydrocannabinol –</strong> This cannabinoid produces the euphoric high correlated with consuming medical marijuana. It can also relieve pain, nausea, and induce appetite.

                <strong>• CBD, Cannabidiol –</strong> This non-intoxicating compound provides relief for anxiety, body pain, and inflammation. In flower, it can help to block some of the more intense effects caused by THC.

                What does this all mean? Well, if you’re an experienced smoker or want a more potent medicinal experience, choosing a strain with high THC and minimal CBD might be right for your medicating needs.

                However, if you’re a new medical marijuana patient or you are prone to anxiety and paranoia, you’ll want to choose a flower with more balanced THC and CBD percentages. And if you want a soothing body effect while remaining clear-headed, your medicinal ratio should heavily favor CBD with minimal THC.</p>

                <h4>A Few More Flower Tips</h4>
                <p>Beyond strains, THC, and CBD, selecting medicinal flower for your needs can be quite personal. When you visit one of the medical marijuana dispensaries in Oklahoma, the staff will guide you and let you examine flower products for yourself. Take in the smell, check out the coloring, and feel free to ask questions you may have. You can also purchase just a gram of various medical strains and try them out before stocking up on your favorite flower.</p>

              </div>
            </div>

            <div className="container">
              <div className="col-md-6">
                <h3>Pre-rolls</h3>
                <p>When it comes to medical cannabis, pre-rolled joints are one of the most popular treatment forms. It’s a good form of medical marijuana for people who are comfortable with smoking. It’s not a form that is particularly discreet, but if you’re happy to smoke at home and enjoy the ritual, it could be the perfect type of medical cannabis for you. Speak to a medical dispensary employee to ensure that you pick the correct strain.</p>
              </div>
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
            </div>

            <div className="container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
              <div className="col-md-6">
                <h3>Medical Cannabis Edibles</h3>
                <p>One of the more obvious alternatives to smoking is edibles — cannabis-infused food and drink. They’re discreet, convenient, and delicious. You can also find them in all your favorite forms, including gummies, truffles, lollipops, cereal bars, cookies, chocolate bars, and much more.
                    It can also be brewed as a tea or other beverage.
                    <br/><br/>
                    Unlike smoking, which typically only takes seconds or minutes to hit your bloodstream, edibles must first be digested in the stomach and processed by your liver. So, effects are typically delayed about 30 minutes, up to 2 hours, and last longer due to slower absorption of the cannabinoids. The results will vary according to the individual; if you have a higher metabolism, you’ll likely feel the effects sooner than someone who took the same dose but has a slower metabolism. You’ll then have around 3-6 hours to enjoy the effects of your medicine.
                    <br/><br/>
                    Some edibles, such as lollipops and lozenges, typically kick in faster because they fall under the sublingual category and absorb into your bloodstream through the tissues in your mouth.
                    <br/><br/>
                    Various forms of medical cannabis can be used for edible medicating. The potency of the edible will depend on the material and amount used in making it. If you’re new to edibles, we highly suggest you take it slow and be patient. Because edibles are made using oil, butter, or marijuana distillate, all of which contain highly-concentrated amounts of cannabinoids, they can pack a serious punch in just one tiny square of chocolate or gummy. While a typical dose is 10 mg of THC, your medical dispensary may suggest starting at 2.5 to 5 mg and see how you respond.
                    <br/><br/>
                    Creating delicious cannabis treats has come a long way from making brownies at home with an unknown flower quality and random THC amounts. With the appearance of medical dispensaries, things are highly regulated, which gives both medical patients full control of how much they want to ingest. Medical dispensaries often have a large abundance of options, from infused lemonade to mints.</p>
              </div>
            </div>

            <div className="join-cta-container">
              <div className="container" style={{backgroundColor: '#'}}>
                <center>
                  <div>
                    <h2 style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 28, textTransform: 'uppercase'}}>How to Choose Edibles for Your Needs</h2>
                    <p>Here’s a quick guide of how to choose edibles that provide the desired effect:</p>
                  </div>
                  <div className="container">
                    <div className="col-md-4">
                      <h4>Determine Your Goals</h4>
                      <p>A good starting point is to choose an edible that fits your needs. If you are looking for a full-body high, stick with edibles higher in THC. To relieve pain, stress, anxiety, and even help get a restful night’s sleep, you’ll want to look into edibles infused with higher amounts of CBD.</p>
                    </div>
                    <div className="col-md-4">
                      <h4>Do a Little Research</h4>
                      <p>While Oklahoma dispensaries have carefully curated tasty treats from trustworthy manufacturers with high-quality standards and good reviews, you can always Google the items they sell to gain a better understanding of what they offer and what to expect.</p>
                    </div>
                    <div className="col-md-4">
                      <h4>Talk to an Expert</h4>
                      <p>When visiting a medical marijuana dispensary in Oklahoma, don’t be shy! The staff would be happy to help you find the perfect edible for your medicinal needs and tastes. They will walk you through doses, strains, CBD versus THC, and more.</p>
                    </div>
                  </div>
                  <p>Ready to get started with edibles? <a href="/dispensaries/">Browse great options in the Okie Weed Finder shop</a> or stop by one of the medical dispensary locations.</p>
                </center>
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
              <div className="col-md-6">
                <h3>Medical Marijuana Concentrates</h3>
                <p>This consumption method removes the flower and gets down to business, separating the terpene and cannabinoid-filled resin. When cannabis flowers are processed to extract their essential oils, their active ingredients become much more concentrated. The result is a cannabis concentrate. The extraction process delivers a final product that contains concentrated amounts of THC and CBD. While THC potency in flower might be 15-20%, concentrates can reach up to 90%. Thus, concentrates are not typically advised for new patients. If you choose to proceed, just remember to take it slow.</p>
                <br/><br/>
                <h4>Types of Concentrate</h4>
                <br/><br/>
                <p>There are so many different types of concentrates available. Walking into a medical dispensary, you'll likely see a collection of concentrates that includes many colors, consistencies, and textures. You may also see several names used to describe these concentrates, such as:</p>
                <br/><br/>
                <p><strong>• Shatter –</strong> Considered one of the purest forms of concentrate, shatter is made with an extraction method using a butane solvent. As the name suggests, shatter appears as hardened glass-like pieces that break apart if dropped and can have around 80% THC. A dab rig, vape, or water pipe are the most common ways to medicate with shatter.<br/>
                    <strong>• Wax –</strong> A sticky form of concentrate that can be broken down into different names depending on the end product's consistency after butane extraction. Wax has a sappy, candle wax texture, while crumble is drier and broken into smaller pieces. Finally, there's Budder or Badder, which is thick and gooey, so you'll want to have your dab tools handy.<br/>
                    <strong>• CO2 Oil –</strong> Here, the concentrate is extracted using carbon dioxide instead of butane, which leaves behind more terpenes for added flavor and more significant medical benefits. Aptly named, the end product is a thicker, but fully liquid concentrate that works in vaporizers and vape pens. CO2 oil is often packaged in plastic syringes for easier measuring and application.<br/>
                    <strong>• Live Resin–</strong> Another concentrate is called Live Resin, which uses a relatively new method of extraction. Harvested buds are frozen, and then the resin is removed from the flower. The process is pretty complicated and needs lab equipment.<br/>
                    <strong>• Distillate –</strong> Becoming increasingly popular is distillate. A refined cannabinoid oil that is typically free of taste, smell &amp; flavor. It is used as the base of most edibles and vape cartridges.</p>
                    <p>If you're looking for powerful effects that you'll feel almost immediately, a concentrate can provide the medicinal experience you're seeking. It's excellent for vaporizing instead of smoking cannabis flower and offers instant relief for medical conditions. Looking for taste? Concentrates with more terpenes provide a more in-depth, clean flavor to meet any long-term patient's expectations.</p>
                    <a href="https://okieweedfinder.com/shop/">Browse the Okie Weed Finder shop</a> to find concentrates at a medical dispensary near you.
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <h3>Medical Cannabis Capsules</h3>
                <p>Medical cannabis pills are made with ingestible oils and are often high in CBD. These most commonly come in capsules or plastic applicators, which can be consumed directly or added to food or drink. Like edibles, these capsules can induce powerful effects that take a while to kick in, so be mindful of your dose!</p>
              </div>
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
              <div className="col-md-6">
                <h3>Vape Pen</h3>
                <p>While vaping is relatively new to the medical cannabis community, it has quickly become one of the leading medical marijuana methods. Vaping is a medicating method in which the patient uses low heat to turn the active compounds in marijuana into an inhalable vapor. At these lower temperatures, no combustion or burning takes place as it does with smoking. This light cannabis vapor is quite different from cannabis smoke both in taste and effect, and for some patients, it's the ideal way to medicate.</p>
                <br/><br/>
                <p>That said, not all vaping is the same. There are various ways patients can vape, and these choices can make a big difference to the resulting medicinal experience. For one, you can vape either cannabis flower or cannabis concentrates. While some vaporizers allow patients to vape either of these interchangeably, most vaporizers specialize in one or the other. If you have a preference, make sure to purchase the right type of vaporizing device for your needs.</p>
                <br/><br/>
                <p>Some patients prefer vaping because of its light taste and reduced odor. You can taste all of the cannabis flower's complex flavors when vaping, while the aroma is usually sweeter than smoking. The scent produced when vaping is often lighter and tends to diffuse more quickly, making it a more discreet option.</p>
                <br/><br/>
                <p>You may also find that you prefer the effects of vaping. Because heat alters many of the compounds in cannabis, the effects of vapor can differ from that of smoke. With lower heat, cannabis vapor often produces more cerebral effects. If you're looking for a more energetic and cerebral effect, then vaping may be right up your alley.</p>
                <br/><br/>
                <p>If you’re interested in vaping medical cannabis,<a href="https://okieweedfinder.com/shop/"> browse Okie Weed Finder</a> to find a medical dispensary in Oklahoma near you.</p>
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <h3>Sublingual Medical Cannabis</h3>
                  <p>Sublingual medical cannabis is a popular choice for patients who'd prefer not to inhale their cannabis. For those who rule out vaping and smoking, either for personal health reasons or because they don't enjoy that high, sublingual medication offers the perfect substitute. For one thing, it's one of the only non-inhaled options that can provide quick relief from symptoms.</p>
                  <br/><br/>
                  <p>Many patients choose edibles as a common substitute for smoking or vaping cannabis. However, unlike sublingual cannabis, edibles are processed through the digestive system. This process can be slow and often takes over an hour and even two or more for the cannabis to take effect.</p>
                  <br/><br/>
                  <p>The concept of "tinctures" is certainly not new, but today's medical cannabis tinctures offer a very modern and effective consumption method. Taken sublingually by placing drops under your tongue, the cannabinoids and terpenes are absorbed into your bloodstream directly. It's quick, discreet, and convenient, with no smoke and no long waiting period like edibles. You will also have better control of dosages with most products, clearly displaying the CBD and THC ratio you'll get from various measurements in the dropper.</p>
                  <br/><br/>
                  <p>You'll typically feel the full effects in about 15-30 minutes, and tinctures last around two hours or more depending on your experience level with medical cannabis. If you don't like the taste of a tincture, it can also be added to tea, juice, even your food. However, your body will first need to digest things and process the cannabinoids through the liver. This will delay the onset of effects for up to 2 hours, just like edibles.</p>
              </div>
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
            </div>

            <div className="join-cta-container">
              <div className="container" style={{backgroundColor: '#'}}>
                <center>
                  <div>
                    <h2 style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 28, textTransform: 'uppercase'}}>Using Tinctures for Medical Benefits</h2>
                    <p>While some tinctures provide a THC high, many patients have discovered tinctures rich in CBD with minimal to no THC help with a wide variety of medical ailments. People from all over the world have used CBD to treat issues such as:</p>
                  </div>
                  <div className="container">
                    <div className="col-md-4">
                    <ul>
                      <li>Depression and Anxiety</li>
                      <li>Loss of Appetite and Nausea</li>
                      <li>Psoriasis and Eczema</li>
                      <li>Seizures, Muscle Spasms</li>
                      <li>Bacterial Infections</li>
                    </ul>
                    </div>
                    <div className="col-md-4">
                    <ul>
                      <li>Chronic and Acute Pain</li>
                      <li>Joint Inflammation, Arthritis</li>
                      <li>Progression of Alzheimer's</li>
                      <li>IBS and Crohn's Disease</li>
                    </ul>
                    </div>
                    <div className="col-md-4">
                    <ul>
                      <li>Insomnia, Sleep Disorders</li>
                      <li>Post-Traumatic Stress Disorder</li>
                      <li>Cancer (Inhibits Tumor Growth)</li>
                      <li>And more</li>
                    </ul>
                    </div>
                    <p>If you're interested in exploring tinctures, <a href="https://okieweedfinder.com/shop/">check out the Okie Weed Finder shop</a> for products. For more help, visit a medical marijuana dispensary and their knowledgeable staff to find the right tincture for your specific medicating needs or explore other product options.</p>
                  </div>
                  <p>Ready to get started with edibles? <a href="/dispensaries/">Browse great options in the Okie Weed Finder shop</a> or stop by one of the medical dispensary locations.</p>
                </center>
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{width: '100%'}}/>    
              </div>
              <div className="col-md-6">
                <h3>Topicals</h3>
                <p>Medical cannabis-infused topicals come in a wide variety of forms, including lotions, balms, oils, ointments, salves, and even transdermal patches that provide various therapeutic benefits. When applied directly to the skin, topicals provide localized relief for aching muscles and reduce inflammation without the psychoactive high produced by other medical cannabis consumption methods such as smoking.</p>
                <p>If you currently suffer from chronic pain in your back or joints, topicals are a great option to explore.</p>
                <br/><br/>
                <h4>Uses for Topicals</h4>
                <p>From sports injuries to arthritis and even sprays to treat minor burns, medical cannabis topicals are used in all kinds of incredible ways. Here are just a few applications:</p>
                <br/><br/>
                <p><strong>• Eczema–</strong> Atopic dermatitis, itchy red rashes, bumps, or scaly skin patches may all benefit from the anti-inflammatory and pain-reducing properties of medical cannabis topicals.</p>
                <p><strong>• Skin Infections –</strong> Bacterial issues such as cellulitis and impetigo may benefit from the antibacterial properties found in cannabinoids. Research has also found them to be highly effective against MRSA, which has proven resistant to all other antibiotics.</p>
                <p><strong>• Aging Skin–</strong> Topicals contain potent antioxidants, possibly more so than vitamins C and E. Antioxidants protect the skin from damage that can lead to collagen breakdown.</p>
                <p>Feel free to <a href="https://okieweedfinder.com/shop/">browse topicals in the Okie Weed Finder shop</a> or visit a medical marijuana dispensary in Oklahoma to learn more about the many cannabis-based products available today.</p>
              </div>
            </div>

            <div className="join-cta-container">
              <div className="container" style={{backgroundColor: '#'}}>
                <center>
                  <div>
                    <h2 style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 28, textTransform: 'uppercase'}}>Finding the Best Medication Method for You</h2>
                    <p>This is just the beginning when it comes to the many ways you can use medical cannabis. Once you receive your medical marijuana card and OMMA certified doctor recommendation, begin exploring your local Oklahoma medical dispensaries for products to try. Not everyone likes to smoke, and those with compromised lung health may not even have the option. The stigmatized image of smoking might be the only thing stopping some people from trying medical cannabis. You’ll start to notice the many different ways patients can use this medicinal plant.</p>

                    <p>Medical dispensaries typically stock a wide array of cannabis products that come in various forms—from flower and concentrate to edibles and topicals. Medical marijuana is often associated with smoking. Though that’s one way patients can use it, medical marijuana can also be vaped, eaten, rubbed on your skin, and even taken under the tongue. Whatever products you choose, make sure to pay close attention to dosing.</p>

                    <p>Okie Weed Finder is here to help you learn more about the various methods of medicating and can help locate medical marijuana dispensaries in Oklahoma near you. Start browsing here.</p>

                      <a role="button" href="https://okieweedfinder.com/shop/">
                      SHOP NOW
                      </a>
                  </div>
                </center>
              </div>
            </div>

          </div>
        </div>
      </Layout>
    );
  }
}



const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
});


export default compose(connect(mapStateToProps, {}))(AvailableForms);
