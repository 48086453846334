import database from "../firebase/firebase";
import firebase from 'firebase/compat/app';
import _ from 'lodash';

// const profile = 'https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/profile_images%2FQcroUjlQ16PvL2rUzmFKvBKCfuH3%2Fjessie_small.jpg?alt=media&token=58f28991-79da-493a-8c61-bd3af5baa238'
/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const SET_PRODUCTS = "SET_PRODUCTS";
export const FETCH_TIMELINE_NEWS = "FETCH_TIMELINE_NEWS";
export const FETCH_PROFILE_PRODUCTS = "FETCH_PROFILE_PRODUCTS";
export const FETCH_MORE_PRODUCTS = "FETCH_MORE_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const EDIT_PRODUCT = 'EDIT_PRODUCT';



/**
 |--------------------------------------------------
 | Fetch news for signed in user
 |--------------------------------------------------
 */


// SET LISTS
export const setNews = (recent_news) => ({
  type: FETCH_TIMELINE_NEWS,
  recent_news
});

export const fetchRecentNews = () => {
  return (dispatch, getState) => {
    // const user = getState().auth.user;
    return database.ref(`news`).once("value").then(snapshot => {
      const recent_news = [];

      snapshot.forEach(childSnapshot => {
        recent_news.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        });
      });
      dispatch(setNews( _.reverse(recent_news)));
    })
  };
}

/**
 |--------------------------------------------------
 | Fetch news for signed in user
 |--------------------------------------------------
 */


// GETS FRIEND OR PUBLIC PROFILE BY PASSING USERS UID // USED by publicProfile
export function fetchUserProfileLists(uid) {
  return dispatch => {
    firebase.database().ref(`users/${uid}/`).on("value", snapshot => {
      let payload = snapshot.val();
      dispatch({
        type: FETCH_PROFILE_PRODUCTS,
        payload
      });
    });
  }; 
}



// ADD_PRODUCT
export const addProduct = product => ({
  type: ADD_PRODUCT,
  product
});

export const startAddProduct = (productData = {}) => {
  const { currentUser } = firebase.auth();
  return dispatch => {

    const {
      createdAt = 0,
      currencyFormat = '$',
      displayName = firebase.auth().currentUser.displayName,
      hidden = false,
      img_url = '',
      listed = true,
      price = 0,
      productCategories = [],
      photoURL = firebase.auth().currentUser.photoURL,
      productNotes = '',
      productName = '',
      productThc = '',
      productType = '',
      type = '',
      id = '',
      uid = firebase.auth().currentUser.uid,
      product_type = '',
      file = [],
      files = [],
      filenames = [],
      downloadURLs = [],
    } = productData;

    const product = { 
      currencyFormat,
      hidden,
      img_url,
      listed,
      price,
      productCategories,
      displayName,
      photoURL,
      productName,
      productType,
      productThc,
      productNotes,
      type,
      createdAt, 
      id, 
      uid, 
      product_type,
      file,
      files,
      filenames,
      downloadURLs
    };

    let updates = {};

    updates[`/products/${id}`] = product;
    updates[`/dispensaries/tulsa/${currentUser.displayName}/products/${id}`] = product;
    updates[`/users/${currentUser.displayName}/products/${id}`] = product;
    updates[`/timeline/${currentUser.uid}/${id}`] = product;

    return database.ref().update(updates).then(ref => {
        dispatch(
          addProduct({
            id: ref,
            ...product
          })
        );
      });
  };
};


// REMOVE_PRODUCT
export const removeProduct = ({ id } = {}) => ({
  type: REMOVE_PRODUCT,
  id
});

export const startRemoveProduct = ({ id } = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch) => {
    return database.ref(`users/${currentUser.displayName}/news/${id}`).remove().then(() => {
      dispatch(removeProduct({ id }));
    }).then(() => {
      const followersRef = database.ref(`followers/${currentUser.uid}`)
      followersRef.on("value", snapshot => {
        const followers = Object.keys(snapshot.val())
        followers.forEach(key => database.ref(`/timeline/${key}/${id}`).remove().then(() => {
          dispatch(removeProduct({ id }));
        }))
      })
    })

  }
}

// EDIT_PRODUCT
export const editProduct = (id, updates) => ({
  type: EDIT_PRODUCT,
  id,
  updates
});

export const startEditProduct = (id, updates) => {
  return (dispatch,  getState) => {
    // const user = getState().auth.user;
    // return database.ref(`followers/${user.uid}`).once("value", snapshot => {
      // Read followers keys and turn into hash
      // const followers = Object.keys(snapshot.val())
      // Create object to send updated to
      let updates_ = {}
      // Loop through followers and send updates
      // followers.forEach(key => updates_[`/timeline/${key}/${id}`] = updates)
      // Sendd updates to Users own Lists
      // updates_[`users/${user.displayName}/news/${id}`] = updates
      // Send updates to Lists node
      updates_[`/news/${id}`] = updates
      
      // updates_[`/timeline/${user.uid}/${id}`] = updates
      return database.ref().update(updates_).then(() => {
        dispatch(editProduct(id, updates));
      })

    // })
  
  };

};
