import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import firebase from 'firebase/compat/app';
var ratings = [
  { star_rating: 'Select' },
  { star_rating: 1 },
  { star_rating: 2 },
  { star_rating: 3 },
  { star_rating: 4 },
  { star_rating: 5 }
]

class ReviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratings: ratings,
      id: firebase.database().ref().push().key,
      review: props.reviewBody ? props.reviewBody.review : "",
      star_rating: props.reviewBody ? props.reviewBody.star_rating : 0,
      createdAt: props.reviewBody ? moment(props.reviewBody.createdAt) : moment(),
      error: ''
    };
  }
  state = {
    review: '',
    star_rating: 0,
    createdAt: moment(),
    error: ''
  }

  onSubmit = () => {
    if (!this.state.review) {
      this.setState(() => ({ error: "Can't post that" }));
    } else {
      this.setState(() => ({ error: '', review: '' }))
      this.props.onSubmit({
        review: this.state.review,
        id: this.state.id,
        rating: Number(this.state.star_rating),
        createdAt: this.state.createdAt.valueOf()
      });
    }
  };

  onChangeText = (e) => {
    const review = e.target.value;
    this.setState(() => ({ review }));
    
  }

  onChangeRating = (e) => {
    const star_rating = e.target.value;
    this.setState(() => ({ star_rating }));
  };

  render() {
    const {photoURL} = this.props.userProps
    const start_rating_selector = (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{ display: 'flex', width: '100%', padding: 16 }}>
          <div>
            <i className="fas fa-star" style={{fontSize: 28, color: '#adadaf'}}/>
          </div>
        </div>
        <div style={{width: '100%'}}>
          <div class="input-group input-group-lg  dropdown-menu-right">
              <div className="select-container">
                <select className="select-css-star-rating" value={this.state.star_rating} onChange={this.onChangeRating}>
                  {this.state.ratings.map(item => (
                    <option key={item.state_code} value={item.star_rating} >
                    {item.star_rating} {item.star_rating === 1 ? ('Star') : ('Stars')}
                    </option>
                  ))}
                </select>
              </div>
          </div>
        </div>
      </div>
    )
    return (
      <div>
        <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{ marginBottom: 8}}>
            <p>Select rating</p>
          </div>
          <div className="list-card-style">
            {start_rating_selector}
          </div>
        </div>
      <div className="list-card-style">
      <div className="add-comment-container" onSubmit={this.onSubmit}>
        <div>
          <img className="add-comment-profile-avatar" src={photoURL} alt="user avatar" />
        </div>

        <div className="add-comment-input-container-style">
          <input
            type="text"
            placeholder="Leave review"
            value={this.state.review}
            onChange={this.onChangeText}
            className="add-comment-input-textfield"
          />
        </div>
        <div className="add-comment-button-container">
          <div onClick={this.onSubmit}>
            <div className="add-comment-div-style">Post</div>
          </div>
        </div>
        <div>{this.state.error && <p>{this.state.error}</p>}</div>
      </div>
      </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
    return {
      userProps: state.userProps
    };
  };

  export default connect(mapStateToProps)(ReviewForm);