import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import Layout from "../layout/Layout";

export default class TermsAndPrivacy extends Component {
  render() {
    return (
      <Layout>
        <Grid style={{ marginTop: 32, marginBottom: 32 }}>
          <div style={{ margin: 16, paddingTop: 32 }}>
            <p style={{ fontSize: 48, fontWeight: "900", color: "#212121" }}>
              Privacy Policy
            </p>
          </div>

          <div
            style={{
              marginLeft: 16,
              marginRight: 16,
              marginTop: 16,
              borderRadius: 16,
              elevation: 1,
            }}
          >
            <div style={styles.title_container}>
              <p style={styles.title}>Effective date: May 23rd, 2023</p>
            </div>

            <div style={styles.title_container}>
              <p>
                This Privacy Policy governs the collection, use, and disclosure
                of personal information by OkieWeedFinder ("we," "us," or "our")
                in relation to your use of our mobile application
                ("OkieWeedFinder"). This Policy outlines how we handle user
                data, including videos and images uploaded by users, and
                describes your rights and choices regarding your personal
                information. By using our App, you consent to the practices
                described in this Policy.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>1. Information We Collect</p>
            </div>

            <div style={styles.title_container}>
              <p>
                1.1 Personal Information: When you use our App, we may collect
                personal information that you voluntarily provide to us, such as
                your name, email address, and other contact details. We require
                this information to provide you with access to certain features
                of the App and to communicate with you.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                1.2 User Content: Our App enables you to upload videos and
                images ("User Content"). You understand and agree that by
                uploading User Content, you grant us a non-exclusive,
                royalty-free, worldwide, perpetual, and irrevocable license to
                use, reproduce, modify, adapt, publish, distribute, and display
                the User Content for marketing material, business development,
                and any other related purposes. However, we do not claim
                ownership over your User Content, and you retain all rights to
                your User Content.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                1.3 Usage Information: We may automatically collect certain
                information about your use of the App, including your device
                information, IP address, browsing history, and other usage
                details. This information is collected to analyze trends,
                administer the App, track user movements, and gather demographic
                information.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>2. Use of Information</p>
            </div>

            <div style={styles.title_container}>
              <p>
                2.1 Personal Information: We may use your personal information
                to provide and improve our services, respond to your inquiries,
                send you administrative notifications, and communicate with you
                about our App and any updates or changes. We may also use your
                personal information for marketing purposes, such as sending you
                promotional emails or displaying targeted advertisements.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                2.2 User Content: We may use your User Content for marketing
                material, business development, and any other related purposes,
                including but not limited to promoting our App and showcasing
                its features. However, we will not use your User Content in a
                way that would disclose personally identifiable information
                without your explicit consent.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                2.3 Usage Information: We may use usage information to analyze
                App usage patterns, personalize your experience, improve our
                services, and ensure the security and integrity of the App.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>3. Disclosure of Information</p>
            </div>

            <div style={styles.title_container}>
              <p>
                3.1 Third-Party Service Providers: We may share your personal
                information and User Content with third-party service providers
                that assist us in operating, managing, and enhancing our App.
                These service providers are contractually bound to protect your
                personal information and are only authorized to use it as
                necessary to provide their services to us.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                3.2 Business Transfers: In the event of a merger, acquisition,
                or sale of all or a portion of our assets, your personal
                information and User Content may be transferred as part of the
                transaction. We will notify you via email or prominent notice on
                our App if such a transfer occurs and your personal information
                becomes subject to a different privacy policy.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                3.3 Legal Requirements: We may disclose your personal
                information if required to do so by law, or if we believe that
                such disclosure is necessary to comply with a legal obligation,
                protect our rights or interests, or detect, prevent, or address
                fraud, security issues, or technical problems.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>4. Data Security</p>
            </div>

            <div style={styles.title_container}>
              <p>
                We implement reasonable security measures to protect your
                personal information and User Content from unauthorized access,
                alteration, disclosure, or destruction. However, no method of
                transmission or storage is 100% secure, and we cannot guarantee
                absolute security.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>5. Your Choices and Rights</p>
            </div>

            <div style={styles.title_container}>
              <p>
                5.1 Account Settings: You may review, update, or delete your
                personal information provided through the App by accessing your
                account settings. Please note that certain information may be
                retained in our records even after deletion of your account.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                5.2 Marketing Communications: You can opt-out of receiving
                promotional emails from us by following the unsubscribe
                instructions provided in those emails. Please note that even if
                you opt-out, we may still send you non-promotional
                communications related to your use of the App.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                5.3 Legal Rights: Depending on your jurisdiction, you may have
                certain rights regarding your personal information, such as the
                right to access, correct, or delete your data. Please contact us
                using the information provided below to exercise your rights or
                if you have any questions or concerns regarding your personal
                information.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>6. Changes to this Policy</p>
            </div>

            <div style={styles.title_container}>
              <p>
                We reserve the right to update or modify this Policy at any
                time. If we make any material changes, we will notify you by
                email or by posting a notice on our App prior to the change
                becoming effective. Your continued use of the App following the
                changes constitutes your acceptance of the revised Policy.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>7. Contact Us</p>
            </div>

            <div style={styles.title_container}>
              <p>
                If you have any questions, comments, or concerns regarding this
                Policy or our privacy practices, please contact us at
                support@okieweedfinder.com.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                Please note that this Privacy Policy only applies to our mobile
                app and does not cover the practices of third-party websites or
                services that may be linked to or integrated with our App. We
                encourage you to review the privacy policies of those third
                parties before providing any personal information to them.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>Location Information</p>
            </div>

            <div style={styles.title_container}>
              <p>
                To provide you with a personalized experience and enhance the
                functionality of our App, we may request access to your device's
                location information. This allows us to offer location-based
                features, tailored content, and relevant recommendations. We are
                committed to respecting your privacy and ensuring the secure
                handling of your location data. This section outlines how we
                collect, use, and protect your location information.
              </p>
            </div>
            <div style={styles.title_container}>
              <p style={styles.title}>1. Collection of Location Information</p>
            </div>

            <div style={styles.title_container}>
              <p>
                1.1 Voluntary Consent: We will request your explicit consent
                before accessing your device's location information. You may
                grant or deny this permission through your device settings or
                the App's permission prompts. Please note that denying access to
                your location may limit certain features and functionalities of
                the App.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                1.2 Automatic Collection: With your consent, we may also collect
                your location information automatically through GPS, Wi-Fi, or
                other similar technologies when you use the App. This
                information helps us provide you with a personalized experience
                based on your current location.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>2. Use of Location Information</p>
            </div>

            <div style={styles.title_container}>
              <p>
                2.1 Personalized Features: We use your location information to
                offer location-based features, such as finding nearby content,
                events, or services that may be relevant to you. This enables us
                to customize your experience and provide you with tailored
                recommendations based on your geographical context.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                2.2 App Functionality: Your location information may also be
                used to facilitate essential App functionalities, such as
                providing accurate weather updates, local time, or navigation
                assistance.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                2.3 Analytics and Improvements: We may analyze aggregated and
                anonymized location data to understand usage patterns, trends,
                and preferences. This helps us improve our services, optimize
                the App's performance, and develop new features to better meet
                your needs.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>3. Disclosure of Location Information</p>
            </div>

            <div style={styles.title_container}>
              <p>
                3.1 Service Providers: We may share your location information
                with trusted third-party service providers who assist us in
                delivering location-based features and functionalities. These
                providers are bound by contractual obligations to handle your
                information securely and in accordance with this Privacy Policy.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                3.2 Legal Requirements: We may disclose your location
                information if required by applicable law, regulation, legal
                process, or governmental request. We may also disclose such
                information to protect our rights, enforce our terms of service,
                or respond to claims of violations.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>4. Data Retention and Security</p>
            </div>

            <div style={styles.title_container}>
              <p>
                We retain your location information only for as long as
                necessary to fulfill the purposes outlined in this Privacy
                Policy, unless a longer retention period is required or
                permitted by law. We implement reasonable security measures to
                protect your location data from unauthorized access, alteration,
                disclosure, or destruction.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>5. Your Choices</p>
            </div>

            <div style={styles.title_container}>
              <p>
                You have the right to control the collection, use, and sharing
                of your location information. You can manage location
                permissions through your device settings or the App's privacy
                settings. You may also withdraw your consent for the collection
                of location information at any time, but please note that doing
                so may impact the availability or functionality of certain
                features within the App.
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>6. Contact Us</p>
            </div>

            <div style={styles.title_container}>
              <p>
                If you have any questions, comments, or concerns regarding the
                collection or use of your location information or our privacy
                practices, please contact us using the information provided in
                the "Contact Us" section of this Privacy Policy.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                Please note that this section regarding location information
                supplements the overall Privacy Policy and should be read in
                conjunction with it.
              </p>
            </div>
          </div>
        </Grid>
      </Layout>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    paddingTop: 90,
  },
  detailsContainer: {
    padding: 16,
    margin: 10,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    shadowColor: "#AAAAAA",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
  },
  title_container: {
    paddingBottom: 5,
    marginBottom: 12,
  },
  title: {
    fontWeight: "bold",
  },
};
