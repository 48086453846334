import React, { Component } from 'react';
import Navigation from './Navigation.js';
import Footer from './Footer';
import TabBar from './TabBar.js';

class Layout extends Component {
  render() {
    return (
      <div>
        <Navigation/>
          {this.props.children}
        <Footer/>
        <TabBar/>
      </div>
    );
  }
}

export default Layout;