// Cities Reducer
import { SET_CITIES } from '../actions/cities'


const citiesReducerDefaultState = [];

export default (state = citiesReducerDefaultState, action) => {
  switch (action.type) {
    case SET_CITIES:
      return action.cities;
    default:
      return state;
  }
};
