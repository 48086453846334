import database from "../firebase/firebase";
import firebase from "firebase/compat/app";

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */
export const SET_REVIEWS = "SET_REVIEWS";

export const ADD_REVIEW = "ADD_REVIEW";

/**
|--------------------------------------------------
| Actions
|--------------------------------------------------
*/
//${currentUser.uid}

// export function fetchReviews( id ) {
//   return dispatch => {
//     database.ref(`/reviews/${id}/`).on('value', snapshot => {
//       dispatch({
//         type: SET_REVIEWS,
//         payload: snapshot.val()
//       })
//     })
//   }
// }

// FETCH_USER CURRENTLY LOGGED IN
export const getReviews = (payload) => ({
  type: SET_REVIEWS,
  payload,
});

export const fetchReviews = (slug) => {
  return (dispatch) => {
    return database
      .ref(`/reviews/${slug}`)
      .once("value")
      .then((snapshot) => {
        const payload = snapshot.val();
        console.log("payload::: ", payload);
        dispatch(getReviews(payload));
      });
  };
};

// ADD_REVIEW
export const addReview = (slug, reviewBody) => ({
  type: ADD_REVIEW,
  slug,
  reviewBody,
});

export const startAddReview = (slug, reviewData = {}) => {
  return (dispatch) => {
    const newReviewKey = firebase
      .database()
      .ref(`reviews/${slug}`)
      .child("review")
      .push().key;
    const {
      review = "",
      createdAt = 0,
      likes = 0,
      reviewId = newReviewKey,
      photoURL = firebase.auth().currentUser.photoURL,
      uid = firebase.auth().currentUser.uid,
      displayName = firebase.auth().currentUser.displayName,
      rating = 0,
    } = reviewData;
    const reviewBody = {
      displayName,
      uid,
      slug,
      photoURL,
      review,
      createdAt,
      likes,
      reviewId,
      rating,
    };
    let updates = {};
    updates[`/reviews/${slug}/${newReviewKey}`] = reviewBody;
    updates[`/users/${uid}/reviews/${newReviewKey}`] = reviewBody;
    return database
      .ref()
      .update(updates)
      .then((ref) => {
        dispatch(
          addReview({
            // id: ref.key,
            ...reviewBody,
          })
        );
      });
  };
};
