import { FETCH_PRODUCTS } from './actionTypes';
import database from "../../firebase/firebase";
import _ from 'lodash';

// import { productsAPI } from '../util';

const compare = {
  lowestprice: (a, b) => {
    if (a.price < b.price) return -1;
    if (a.price > b.price) return 1;
    return 0;
  },
  highestprice: (a, b) => {
    if (a.price > b.price) return -1;
    if (a.price < b.price) return 1;
    return 0;
  }
};



export const fetchProducts = (filters, sortBy, products, callback ) => dispatch => {
    
      
      // let objectState = products
      // let { products } = profileData
      
      let productsArr = _.toArray(products)
      console.log(productsArr, "PRODUCT ACTION")

      if (!!filters && filters.length > 0) {
        productsArr = productsArr.filter(p =>
          filters.find(f => p.productCategories.find(size => size === f))
        );
      }

      if (!!sortBy) {
        productsArr = productsArr.sort(compare[sortBy]);
      }

      if (!!callback) {
        callback();
      }

      return dispatch({
        type: FETCH_PRODUCTS,
        payload: productsArr
      });
};
