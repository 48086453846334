import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {greatPlaceStyle, smallCard} from './my_great_place_with_hover_styles.js';
import map_marker from '../../assets/images/map_marker@2x.png'

export default class MyGreatPlaceWithHover extends Component {
  static defaultProps = {};

  render() {
    
    // const style = this.props.$hover ? greatPlaceStyleHover : greatPlaceStyle;
    const marker = this.props.$hover ? <Card text={this.props.text}/> : <MarkerIcon/>;

    return (
       <div>
          {/* {this.props.text} */}
          {marker}
          {/* <Marker text={this.props.text}/> */}
       </div>
    );
  }
}

const MarkerIcon = ({ text }) => <div><img src={map_marker} style={{width: 40, height: 40}} alt="map marker img"/></div>;

const Card = (props) => (
    <Link to={`/dispensaries/${props.text.city}/${props.text.slug}/`}>
      <div style={greatPlaceStyle}>
      <div style={smallCard}>

        <div className="list-profile-avatar-wrapper">
          <img src={props.text.photoURL} className="list-profile-avatar" alt="profile img" />
        </div>
        
        <div className="flex-parent">
            <strong className="long-and-truncated">{props.text.displayName}</strong>
            <p className="long-and-truncated" style={{fontSize: 12, lineHeight: 1 }}>{props.text.address}</p>
        </div>
        
      </div>
      </div>
    </Link>
    );

