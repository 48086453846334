// LESSONS Reducer
import { ADD_PRODUCT, FETCH_TIMELINE_NEWS, FETCH_PROFILE_PRODUCTS, FETCH_MORE_PRODUCTS, REMOVE_PRODUCT, SET_PRODUCTS } from '../actions/recent_news'


const productsReducerDefaultState = [];

export default (state = productsReducerDefaultState, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
    return [
      ...state,
      action.lesson
    ];

    case REMOVE_PRODUCT:
      return state.filter(({ id }) => id !== action.id);
    
    // case EDIT_LIST:
    //   return state.map((list) => {
    //     if (list.id === action.id) {
    //       return {
    //         ...list,
    //         ...action.updates
    //       };
    //     } else {
    //       return list;
    //     };
    //   });
    
    case SET_PRODUCTS:
      return action.recent_news;
      
    case FETCH_PROFILE_PRODUCTS:
      return action.payload;
    
    case FETCH_MORE_PRODUCTS:
      return action.products;
      
    case FETCH_TIMELINE_NEWS:
      return action.recent_news;
      
    default:
      return state;
  }
};
