import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from 'recompose';
// import ListIndexFilters from "./ListIndexFilters";
import { startSetDispensaries } from  '../../actions/dispensaries'
import itemSelector from "./../../selectors/selector";
// import div from "../layout/div";
import GoogleMap from 'google-map-react';
// import _ from 'lodash'
// import map_marker from '../../assets/images/map_marker.png'

import { Col } from 'react-bootstrap';
// import HeroHeader from '../landing/HeroHeader';
// import dispensaries from "../../reducers/dispensaries";
import MapSmallCard from './MapSmallCard';
import LoadingPage from '../utilities/LoadingPage';
import Navigation from "../layout/Navigation";
import Footer from "../layout/Footer";


// const K_MARGIN_TOP = 30;
// const K_MARGIN_RIGHT = 30;
// const K_MARGIN_BOTTOM = 30;
// const K_MARGIN_LEFT = 30;
const K_HOVER_DISTANCE = 100 /2;

var cities = [
  { city_name: 'Select city' },
  { city_name: 'Admore' },
  { city_name: 'Arapaho' },
  { city_name: 'Arcadia' },
  { city_name: 'Arkadelphia' },
  { city_name: 'Arkoma' },
  { city_name: 'Bartlesville' },
  { city_name: 'Bentonville' },
  { city_name: 'Bethany' },
  { city_name: 'Blanchard' },
  { city_name: 'Broken Arrow' },
  { city_name: 'Broken Bow' },
  { city_name: 'Calera' },
  { city_name: 'Claremore' },
  { city_name: 'Carnegie' },
  { city_name: 'Cartwright' },
  { city_name: 'Chandler' },
  { city_name: 'Chickasha' },
  { city_name: 'Noble' },
  { city_name: 'Choctaw' },
  { city_name: 'Claremore' },
  { city_name: 'Clarksville' },
  { city_name: 'Cookson' },
  { city_name: 'Cordell' },
  { city_name: 'Coyle' },
  { city_name: 'Del City' },
  { city_name: 'Duncan' },
  { city_name: 'Edmond' },
  { city_name: 'El Reno' },
  { city_name: 'Elk city' },
  { city_name: 'Enid' },
  { city_name: 'Goldsby' },
  { city_name: 'Grove' },
  { city_name: 'Harrah' },
  { city_name: 'Henryetta' },
  { city_name: 'Holdenville' },
  { city_name: 'Hot Springs' },
  { city_name: 'Hugo' },
  { city_name: 'Hydro' },
  { city_name: 'Jenks' },
  { city_name: 'Lawton' },
  { city_name: 'Lexington' },
  { city_name: 'Lone Grove' },
  { city_name: 'Marlow' },
  { city_name: 'McAlester' },
  { city_name: 'Mead' },
  { city_name: 'Meeker' },
  { city_name: 'Midwest City' },
  { city_name: 'Mustang' },
  { city_name: 'Minco' },
  { city_name: 'Monkey island'},
  { city_name: 'Moore'},
  { city_name: 'Muskogee' },
  { city_name: 'Newcastle' },
  { city_name: 'Norman' },
  { city_name: 'Nicoma Park' },
  { city_name: 'Oklahoma City', center:{ lat: 35.4825666, lng: -97.6196207} },
  { city_name: 'Okmulgee' },
  { city_name: 'Owasso' },
  { city_name: 'Pauls Valley' },
  { city_name: 'Pawhuska' },
  { city_name: 'Piedmont' },
  { city_name: 'Poteau' },
  { city_name: 'Prague' },
  { city_name: 'Pryor' },
  { city_name: 'Purcell' },
  { city_name: 'Roland' },
  { city_name: 'Russellville' },
  { city_name: 'Sallisaw' },
  { city_name: 'Sand Springs' },
  { city_name: 'Sapulpa' },
  { city_name: 'Sayre' },
  { city_name: 'Seminole' },
  { city_name: 'Shawnee' },
  { city_name: 'Skiatook' },
  { city_name: 'Slaughterville' },
  { city_name: 'Stillwater' },
  { city_name: 'Tahlequah' },
  { city_name: 'Tecumseh' },
  { city_name: 'The Village' },
  { city_name: 'Tulsa' },
  { city_name: 'Watts' },
  { city_name: 'Warr Acres' },
  { city_name: 'Weatherford' },
  { city_name: 'Wellston' },
  { city_name: 'Wewoka' },
  { city_name: 'Woodward' },
  { city_name: 'Yukon' }
];

// const bounds = {
//   nw: {
//     lat: 50.01038826014866,
//     lng: -118.6525866875
//   },
//   se: {
//     lat: 32.698335045970396,
//     lng: -92.0217273125
//   }
// };

// Or
// const K_SCALE_NORMAL = 0.65;

// const bounds = {
//   ne: {
//     lat: 36.5312376,
//     lng: -96.6935472
//   },
//   sw: {
//     lat: 34.230039,
//     lng: -98.800048
//   }
// };

// const size = {
//   width: 640, // Map width in pixels
//   height: 380, // Map height in pixels
// };

// const {center, zoom} = fitBounds(bounds, size);

class ExploreDispensaries extends Component {
  constructor(props){
    super(props)
    this.state = {
      position: {},
      displayName: '',
      cities: cities,
      city_name: '',
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      center: null
    }
  }

  componentDidMount(){
    this.getLocation()
    const {city} = this.props.user
    const cityOption = city ? city : 'Tulsa'
    this.props.startSetDispensaries(cityOption)
  }

//   componentWillMount() {

//     this.setState({

//         zoomToMarkers: map => {
//             //console.log("Zoom to markers");
//             const bounds = new window.google.maps.LatLngBounds();
//             map.props.children.forEach((child) => {
//                 if (child.type === MapSmallCard) {
//                     bounds.extend(new window.google.maps.LatLng(child.props.position.latitude, child.props.position.longitude));
//                 }
//             })
//             map.fitBounds(bounds);
//         }
//   })
// }

  // static defaultProps = {
  //   zoom: 12
  // };


  static defaultProps = {
    // center: [59.744465, 30.042834],
    zoom: 13,
  }


  _onBoundsChange = (center, zoom, bounds, marginBounds) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({center, zoom, bounds, marginBounds});
    } else {
      this._onChange(center);
      // this.props.onZoomChange(zoom);
    }
  }

  _onChange({center, zoom, bounds, ...other}) {
    // unstable_batchedUpdates(() => {  
      //  this.props.onZoomChange(zoom);
       // change center here
       this.setState({zoom: zoom});
    // });
  }

  // _onChildClick = (key, childProps) => {
  //   const markerId = childProps.marker.get('id');
  //   const index = this.props.markers.findIndex(m => m.get('id') === markerId);
  //   if (this.props.onChildClick) {
  //     this.props.onChildClick(index);
  //   }
  // }

  onSearchInputChange = (e) => {
    const displayName = e.target.value;
    this.setState(() => ({ displayName }));
  };

  onStateChange = (e, center) => {
    const city_name = e.target.value;
    // const center = e.target.center;
    this.setState(() => ({ city_name, center }));
    this.props.startSetDispensaries(city_name)
    
  };

  searchDispensaries = () => {
    const cityName = this.state.city_name
    this.props.startSetDispensaries(cityName)
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(position, "Position")
        this.setState({center: pos})
      });
    } else { 
      this.setState({err: "Geolocation is not supported by this browser."}) 
    }
  }

  render(){

    // const {rowFrom, rowTo} = getRealFromTo(this.props.visibleRowFirst, this.props.visibleRowLast, this.props.maxVisibleRows);


      const dispensariesArr = this.props.dispensaries
      console.log(this.state.position, "Position")

      const places = dispensariesArr
      .map(place => {
        const { id } = place;
        return (
            <MapSmallCard
              key={id}
              text={place}
              hover={this.props.hoverKey === id}
              lat={place.latitude}
              lng={place.longitude}
            />
        );
      });

      return(
        <div>

            <div className="container-fluid">
          
              <Col md={12} className="no-margin">
                <div style={{ height: 300, width: '100%' }}>
                  { dispensariesArr.length === 0 ? (
                    <LoadingPage/>
                  ) : (
                    <GoogleMap
                      bootstrapURLKeys={{ key: 'AIzaSyCxbdS9sY_vALdSVincQAxA7gOPqr6XHLs' }}
                      defaultCenter={this.state.center}
                      center={this.state.center}
                      zoom={this.props.zoom}
                      onBoundsChange={this._onBoundsChange}
                      hoverDistance={K_HOVER_DISTANCE}
                    >
                      {places}
                    </GoogleMap>
                )}
                </div>
              </Col>           
        </div>
      </div>
    )
  }
};

const mapStateToProps = state => {
  return {
    user: state.user,
    // userProps: state.userProps, 
    dispensaries: itemSelector(state.dispensaries, state.filtersText),
  };
};

export default compose(connect(mapStateToProps, { startSetDispensaries }))(ExploreDispensaries);