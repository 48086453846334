import React, { Component } from 'react';
import moment from 'moment';
import { Form, Button, Col } from "react-bootstrap";
import firebase from 'firebase/compat/app';
import _ from 'lodash'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  


class PostForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: firebase.auth().currentUser.displayName,
      photoURL: firebase.auth().currentUser.photoURL,
      uid: firebase.auth().currentUser.uid,
      id: firebase.database().ref().push().key,
      title: props.list ? props.list.title : "",
      img_url: '',
      likes: 0,
      likeCounts: 0,
      createdAt: props.list ? moment(props.list.createdAt) : moment(),
      imagePath: null,
      picture: null,
      error: ""
    }
  }
  state = {
    title: '',
    createdAt: moment(),
    imagePath: '',
    img_url: '',
    error: ''
  }

  onTitleChange = (e) => {
    const title = e.target.value;
    this.setState(() => ({ title }));
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.title) {
      this.setState(() => ({ error: 'At least write a title' }))
    } else {
      this.setState(() => ({ error: '' }))

      if (this.state.picture === null || this.state.picture === '') {
      this.props.onSubmit({
        displayName: this.state.displayName,
        title: this.state.title,
        photoURL: this.state.photoURL,
        img_url: '',
        likes: this.state.likes,
        uid: this.state.uid,
        id: this.state.id,
        createdAt: this.state.createdAt.valueOf()
      });
      
    } else {
      const imageName = `${this.state.id}.jpg`;

      const { uid } = firebase.auth().currentUser;
      const folderName = `${uid}`;

  
    var metadata = {
      contentType: "image/jpeg"
    };

    const imageRef = firebase.storage().ref(`content/${folderName}/${imageName}`);

    imageRef.put(this.state.picture, metadata).then(() => {
        return imageRef.getDownloadURL();
      })
      .then(url => {
        this.props.onSubmit({
          displayName: this.state.displayName,
          title: this.state.title,
          photoURL: this.state.photoURL,
          img_url: url,
          likeCounts: this.state.likeCounts,
          likes: this.state.likes,
          uid: this.state.uid,
          id: this.state.id,
          createdAt: this.state.createdAt.valueOf(),
        });
      })
      .then(() => toast("Image successfully uploaded"))
      .catch(error => console.log(error))
    }
  }
  toast("Post successfully posted");
  }

  onKeyPress(e) {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
}
  

displayPicture(event) {
  
  let reader = new FileReader();
  let file = event.target.files[0];

  reader.onloadend = () => {
    this.setState({
      picture: file,
      img_url: reader.result
    });
  };
  
  reader.readAsDataURL(file);
  
}

  render() {
    const { input } = this.props;
    const { img_url } = this.state;

    const post_image = img_url ? (
      <div className="account-img-wrapper">
        <img src={img_url} className="account-user-img" alt="user avatar" draggable={false} />
      </div>
    ) : (
      <div style={{ display: 'flex', backgroundColor: '#FFF', borderRadius: 12 }}>
        <div style={{ width: 150, height: 50, paddingTop: 12, paddingLeft: 12 }}>
          <div>
          <label htmlFor="files">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div>
                <i className="fas fa-image" style={{fontSize: 34, color: '#adadaf'}}/>
              </div>
              <div style={{marginLeft: 8}}><p>Select image</p></div>
            </div>
          </label>
          <input id="files" onChange={event => { this.displayPicture(event) }} {...input} style={{visibility:'hidden'}} type="file"/>
          </div>
        </div>
      </div>
    )
    const { title } = this.state;
    const isInvalid = title === '';

    return (
      <div>
        {this.state.error && <p>{this.state.error}</p>}
        <form onSubmit={this.onSubmit} onKeyPress={this.onKeyPress}>
          
        <div style={{display: 'flex',flexDirection: 'column'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div>
                {post_image}
              </div>
            </div>

            <div style={{margin: 16}}>
              <input
                style={{ height: 45, width: '100%', borderRadius: 12, paddingLeft: 8, backgroundColor: '#e1e1e1' }}
                type="text"
                placeholder="Description"
                autoFocus
                value={this.state.title}
                onChange={this.onTitleChange} />
            </div>

          </div>
        </form>
        <div style={{marginLeft: 16, marginRight: 16, paddingBottom: 16}}>
          <Button 
            bsStyle="primary" 
            bsSize="large" 
            className="btn-blank request-invite_btn" 
            onClick={this.onSubmit}
            disabled={isInvalid}
            >Share post</Button>
        </div>
      </div>
    );
  }
}

export default PostForm;


const styles = {
  privateInfoContainer: {
    paddingTop: 20,
    // borderColor: '#CCCCCC',
    // borderTopWidth: 2,
  },
  privateInfoText:{
    color: '#9E9E9E',
    fontWeight: 'bold'
  },
  userAccountTextInput: {
    flexDirection: 'row',
  },
  searchFieldContainer: {
    padding: 10,
    margin: 10,
    backgroundColor: '#FFFFFF',
    height: 40,
    flex: 1,
    borderColor: '#CCCCCC',
    borderBottomWidth: 1,
  },
  leftTitleContainer: {
    paddingTop: 20,
    fontWeight: 'bold'
  },
  submitUpdatesButton: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: '#304FFE',
    padding: 10,
    margin: 10,
    backgroundColor: '#304FFE',
    height: 40,
  },
  submitButtonText: {
    color: '#ffffff',
    textAlign: 'center'
  },
}
