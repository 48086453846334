import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../styles/App.css";

class Friend extends Component {
  render() {
    const { photoURL, displayName, verified } = this.props;
    return (
      <Link to={`/u/${displayName}`}>
        <div className="list-card-style">
          <div className="list-card" style={{ boder: 'none', minHeight: 80}}>

            <div className="list-profile-avatar-wrapper">
              <img src={photoURL} className="list-profile-avatar" alt="profile img" />
            </div>

            <div className="list-detail-wrapper">
              <div>
                <p className="list-title">{displayName} {verified ? <span className="verified" /> : <span />}</p>
              </div>
            </div>

          </div>
        </div>
      </Link>
    );
  }
}

export default Friend;
