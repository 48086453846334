import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "../../styles/App.css";

class List extends Component {  
  render(){
    const { title, store_name, createdAt, photoURL, city, slug, displayName, id, img_url } = this.props
    return(
              <Link to={`/u/${displayName}`} key={id}>
                <div className="list-card-style list-card-height">

                    {img_url ? (
                      <div style={{ boder: 'none', minWidth: 200, width: "100%", mxHeight: 480, borderTopRightRadius: 22, borderTopLeftRadius: 22, overflow: 'hidden' }}>
                        <img src={img_url} alt="post" className="card-img-container"/>
                      </div>
                      ) : (
                        <div style={{ boder: 'none', minWidth: 200, width: "100%", mxHeight: 480, borderTopRightRadius: 22, borderTopLeftRadius: 22, overflow: 'hidden' }}>
                          <img src={photoURL} alt="post" className="card-img-container"/>
                        </div>
                      )}

                  <div className="list-card">
                    
                    <div className="list-profile-avatar-wrapper" style={{paddingTop: 16, paddingBottom: 16, paddingLeft: 16, paddingRight: 8}}>
                      <img src={photoURL} className="list-profile-avatar" alt="profile img" />
                    </div>

                    <div className="list-detail-wrapper">
                      <div>
                        <p className="list-user-name">{store_name ? store_name :displayName}</p>
                        <p className="list-title">{title}</p>
                        <p className="timestamp">{moment(createdAt).fromNow()}</p>
                      </div>
                    </div>          

                  </div>

                </div>

            </Link>
    )
  }
}

export default List;

