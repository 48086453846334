import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { updateFilters } from '../../../services/filters/actions';
import Checkbox from './../../Checkbox';

import './style.scss';

const productCategories = ['Sativa', 'Indica', 'Hybrid', 'Concentrate', 'Vapes', 'Edibles', 'Deals', 'Other'];

const Filter = props => {
  const selectedCheckboxes = new Set();

  const toggleCheckbox = label => {
    if (selectedCheckboxes.has(label)) {
      selectedCheckboxes.delete(label);
    } else {
      selectedCheckboxes.add(label);
    }

    props.updateFilters(Array.from(selectedCheckboxes));
  };

  const createCheckbox = label => (
    <Checkbox
      classes="filters-available-size"
      label={label}
      handleCheckboxChange={toggleCheckbox}
      key={label}
    />
  );

  const createCheckboxes = () => productCategories.map(createCheckbox);

  return (
    <div className="filters">
      <div style={{marginBottom: 16}}>
        {createCheckboxes()}
      </div>
    </div>
  );
};

Filter.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.array
};

export default connect(
  null,
  { updateFilters }
)(Filter);
