import React, { Component } from "react";
import firebase from 'firebase/compat/app';
import { connect } from "react-redux";

class FollowButtonDispensary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      following: false,
      followingArr: []
    };
    this._handleFollow = this._handleFollow.bind(this);
  }

  // componentDidMount() {

  //   this.mounted = true;
  //   if (this.mounted) {
  //     firebase.auth().onAuthStateChanged(user => {
  //       if (user) {
  //         firebase.database().ref().child(`following/${user.uid}/`).orderByChild("displayName").equalTo(displayName).on("value", (snapshot) => {
  //           if (snapshot.exists()) {
  //               this.setState({usernameError: 'Sorry username is taken =('})
  //             }else{
  //               this.setState({usernameError: 'Username is available!'})
  //             }
  //           });
  //       }
  //     })
  //   }      
  //   this.setState({usernameError: ''})
  // }
  
  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
          firebase.database().ref(`following/${user.uid}/`).once('value').then(snapshot => {
            let following = Object.keys(snapshot.val())
            this.setState({followingArr: following})
          })
        }
      })
    }
  }

  _handleFollow() {
    const publicProfileUserId = this.props.profileData.uid;
    const publicProfileDisplayName = this.props.profileData.displayName;

    const { displayName, photoURL, uid, verified } = this.props.userProps

    if (this.state.following === false) {
      this.setState({
        following: !this.state.following
      });

      const followBody = true;
      const userFollower = true

      const followerDataBody = {
        uid: uid,
        displayName: displayName,
        photoURL: photoURL,
        verified: verified
        
      } 

      const followingDataBody = {
        uid: this.props.profileData.uid,
        displayName: this.props.profileData.displayName,
        photoURL: this.props.profileData.photoURL,
        verified: this.props.profileData.verified
      } 


      let updates = {};
      updates[`/users/${uid}/following/${publicProfileUserId}`] = userFollower;
      updates[`/users/${publicProfileUserId}/followers/${uid}`] = userFollower;

      updates[`/followers/${publicProfileUserId}/${uid}`] = followBody;
      updates[`/following/${uid}/${publicProfileUserId}`] = followBody;

      updates[`/follower-data/${publicProfileUserId}/${uid}`] = followerDataBody;
      updates[`/following-data/${uid}/${publicProfileUserId}`] = followingDataBody;

      return firebase.database().ref().update(updates).then(() => {
        let publicProfileListsRef = firebase.database().ref(`/users/${publicProfileUserId}/posts`)
        publicProfileListsRef.on('value', snapshot => {
         firebase.database().ref(`/timeline/${uid}/`).update(snapshot.val())
        })
      });
      
    } else if (this.state.following === true) {
      
      this.setState({
        following: false
      });

      return firebase.database().ref(`/followers/${publicProfileUserId}/${uid}`).remove()
        .then(() => {
          firebase.database().ref(`/following-data/${uid}/${publicProfileUserId}`).remove();
          firebase.database().ref(`/follower-data/${publicProfileUserId}/${uid}`).remove();
          firebase.database().ref(`/following/${uid}/${publicProfileUserId}`).remove();
          firebase.database().ref(`/users/${uid}/following/${publicProfileUserId}`).remove();
          firebase.database().ref(`/users/${publicProfileUserId}/followers/${uid}`).remove();
        })
        .then(() => {
          const publicProfileUserId = this.props.profileData.uid;
          let userPosts = firebase.database().ref(`/timeline/${uid}/`);

          userPosts.on("value", snapshot => {
            let _userPostsValues = Object.values(snapshot.val());

            _userPostsValues.forEach(function(value, key) {
              let userPostToRemove = value.uid;

              if (userPostToRemove === publicProfileUserId) {
                let postIdToRemove = value.id;
                //Remove User lists from CurrentUser's timeline
                firebase.database().ref(`/timeline/${uid}/${postIdToRemove}`).remove();
              }
            });
          });
        });
    }
  }

  _followButton() {

    const publicProfileUserId = this.props.profileData.uid;
    // const uid = this.props.userProps.uid;
    var filteredFriend = this.state.followingArr.find(function(element) {
      return element === publicProfileUserId ;
    });

    return filteredFriend || this.state.following ? (
      <div className="unfollow-btn">
        <p className="unfollow-button-text">Unfollow</p>
      </div>
    ) : (
      <div className="follow-btn">
        <p className="follow-button-text">Follow</p>
      </div>
    );
  }

  render() {
    // console.log(this.props, "PROPS")
    // console.log(this.props.profileData.uid, "PROPS")
    return (
      <div>
        <div className="profile-user-stats-wrapper">
          <div onClick={this._handleFollow}>
            {this._followButton()}
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount(){
    firebase.auth().onAuthStateChanged(user => {
    firebase.database().ref(`following/${user.uid}/`).off()
    })
  }
}

export default (FollowButtonDispensary = connect(
  state => ({
    userProps: state.userProps
  }),
  { }
)(FollowButtonDispensary));
