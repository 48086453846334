import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from 'recompose';
import ListIndexFilters from "./ListIndexFilters";
import { startSetDispensaries } from  '../../actions/dispensaries'
import itemSelector from "./../../selectors/selector";
import _ from 'lodash';
import { Col } from 'react-bootstrap';
import LoadingPage from '../utilities/LoadingPage';
import  defaultPhotoURL from '../../assets/images/default_avatar.jpg';

class LocalDirectory extends Component {
  constructor(props){
    super(props)
    this.state = {
      position: {},
      displayName: '',
      city_name: '',
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      center: null
    }
  }

  componentDidMount(){
    // this.getLocation()
    const {city} = this.props.user
    const cityOption = city ? city : 'Tulsa'
    this.props.startSetDispensaries(cityOption)
  }


  onSearchInputChange = (e) => {
    const displayName = e.target.value;
    this.setState(() => ({ displayName }));
  };

  onStateChange = (e, center) => {
    const city_name = e.target.value;
    // const center = e.target.center;
    this.setState(() => ({ city_name, center }));
    this.props.startSetDispensaries(city_name)
    
  };

  searchDispensaries = () => {
    const cityName = this.state.city_name
    this.props.startSetDispensaries(cityName)
  }

  render(){

      const dispensariesArr = this.props.dispensaries
      const { city } = this.props.user
      return(
        <div>
            <div className="container-fluid no-margin">
                        

              <Col md={12} style={{ height: '100vh', overflow: 'hidden' }}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8}}>
                <div style={{justifyContent: 'flex-end'}}>
                  <h3 style={{fontWeight: 'bold', fontSize: 12, textTransform: 'uppercase', lineHeight: 0.5}}>{city} {dispensariesArr.length} Results</h3>
                </div>
              </div>
              <div style={{ width: '100%'}}>
                <ListIndexFilters />
              </div>



              <div style={{ width: '100%', height: '100vh' }}>
              <div style={{ width: '100%', height: '90%', overflow: 'scroll',  marginBottom: 80 }}>

              {
                dispensariesArr.length === 0 ? (
                  <LoadingPage/>
                ) : (
                  dispensariesArr.map((profile) => {
                    const profileImage = profile.photoURL ? (
                          <div>
                            <img src={profile.photoURL} alt="profile img" className="card-img-container" />
                          </div>
                    ) : (
                          <div>
                            <img src={defaultPhotoURL} alt="profile img" className="card-img-container" />
                          </div>
                    )
                    return(
                    <Col md={3} sm={6} xs={6}>
                      <Link to={`/dispensaries/${profile.city}/${profile.slug}/`}>
                        <div className="list-card-style">
                        
                        <div style={{height: 180, overflow: 'hidden', borderTopRightRadius: 22, borderTopLeftRadius: 22}}>
                          {profileImage}
                        </div>
                          
                        <div className="list-card" style={{flexDirection: 'row'}}>
                          <div style={{width: '100%', minHeight: 70}}>
                            <div style={{padding: 4}}>
                              <div className="flex-parent">
                                <p style={{fontSize: 14, fontWeight: '900'}} className="long-and-truncated">{profile.displayName}</p>
                              </div>
                              <div className="flex-parent">
                                <p className="long-and-truncated"> {profile.address}</p>
                              </div>
                              <p><i className="fas fa-map-marker map-city-icon"></i> {profile.city}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </Link>
                  </Col>
                  )}
                )
              )
            }
            
            </div>
            </div>

          </Col>            
        </div>
      </div>
    )
  }
};

const mapStateToProps = state => {
  return {
    user: state.user,
    userProps: state.userProps, 
    dispensaries: itemSelector(state.dispensaries, state.filtersText),
  };
};

export default compose(connect(mapStateToProps, { startSetDispensaries }))(LocalDirectory);