import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import icon_1 from "../../assets/images/icons/chill_icon.svg";
import icon_2 from "../../assets/images/icons/creative_icon.svg";
import icon_3 from "../../assets/images/icons/energetic_icon.svg";
import icon_4 from "../../assets/images/icons/fire_icon.svg";
import icon_5 from "../../assets/images/icons/happy_icon.svg";
import icon_6 from "../../assets/images/icons/relax_icon.svg";
import icon_7 from "../../assets/images/icons/sleepy_icon.svg";
import icon_8 from "../../assets/images/icons/tired_icon.svg";

import firebase from "firebase/compat/app";
import Layout from "../layout/Layout";
import { addProduct } from "../../services/cart/actions";
import PropTypes from "prop-types";
import FloatCart from "../FloatCart";
import CommentsView from "../comments/CommentsView";
import CommentsList from "../comments/CommentsList";

var cities = [
  { city_name: "SELECT WEIGHT" },
  { city_name: "1 g" },
  { city_name: "1/8" },
  { city_name: "1/4" },
  { city_name: "1/2" },
  { city_name: "1 oz" },
];
class PublicProfileProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productData: {},
      productsArray: [],
      city_name: "",
      weight_price: null,
    };
  }
  mounted = false;

  getBusinessProduct = async () => {
    try {
      const { uid } = this.props.user;
      const id = this.props.match.params.id;
      const db = firebase.firestore().collection("usersDB");
      let productArr = await db
        .doc(`${uid}`)
        .collection("product")
        .doc(`${id}`)
        .get();

      // let productTemp = [];

      // productArr.forEach((doc) => {
      //   let dispoData = doc.data();
      //   productTemp.push(dispoData);
      // });

      this.setState({ productData: productArr.data() });
      this.setState({ isLoading: false });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    const slug = this.props.match.params.slug;
    const city = this.props.match.params.city;
    const id = this.props.match.params.id;
    const { uid } = this.props.user;
    console.log(id, "id");

    this.mounted = true;
    this.getBusinessProduct();
  }

  onStateChange = (e, weights) => {
    const city_name = e.target.value;

    function weight_price() {
      switch (city_name) {
        case "1 g":
          return weights.one_gram;
        case "1/8":
          return weights.one_eight;
        case "1/4":
          return weights.one_quarter;
        case "1/2":
          return weights.one_half;
        case "1 oz":
          return weights.one_ounce;
        default:
          return;
      }
    }
    let a = weight_price(city_name);
    this.setState(() => ({ city_name, weight_price: a }));
  };

  render() {
    let {
      id,
      displayName,
      manufacturer,
      img_url,
      store_name,
      photoURL,
      city,
      slug,
      productName,
      weights,
      type,
      productNotes,
      productThc,
      sku,
      price,
      quantity,
      priceRange,
      uid,
      // tags,
    } = _.toPlainObject(this.state.productData);
    // const tagsArr = _.toArray(tags);
    price = this.state.weight_price;
    quantity = 1;

    const tag_icon = (tag) => {
      switch (tag) {
        case "Chill":
          return icon_1;
        case "Creative":
          return icon_2;
        case "Energetic":
          return icon_3;
        case "Fire":
          return icon_4;
        case "Happy":
          return icon_5;
        case "Calmed":
          return icon_6;
        case "Sleepy":
          return icon_7;
        case "Tired":
          return icon_8;
        default:
          return;
      }
    };

    let productObj = {
      // ...product,
      productName,
      price,
      quantity,
      id,
    };
    let dealObj = {
      // ...product,
      productName,
      price: priceRange,
      quantity,
      id,
    };
    return (
      <Layout>
        <div
          className="container-fluid"
          style={{ marginTop: 90, marginBottom: 90 }}
        >
          <div className="app-wrapper">
            <div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div style={{ overflow: "hidden", borderRadius: 32 }}>
                  <div style={{ borderRadius: 32, overflow: "hidden" }}>
                    <img
                      src={img_url}
                      alt="product"
                      className="img-responsive"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      className="list-profile-avatar-wrapper"
                      style={{ paddingRight: 8 }}
                    >
                      <a href={`/dispensaries/${city}/${slug}/`}>
                        <img
                          src={photoURL}
                          className="list-profile-avatar"
                          alt="profile img"
                        />
                      </a>
                    </div>

                    <div className="list-detail-wrapper">
                      <div>
                        <p className="list-user-name">
                          {store_name ? store_name : displayName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="list-card" style={{ flexDirection: "row" }}>
                  <div style={{ width: "100%" }}>
                    <div style={{ padding: 4 }}>
                      <div
                        className="badge badge-secondary"
                        style={{ marginTop: 8 }}
                      >
                        <p style={{ fontSize: 12, fontWeight: "bold" }}>
                          #{_.toUpper(type)}
                        </p>
                      </div>
                      <p style={{ fontSize: 30, fontWeight: "900" }}>
                        {productName}
                      </p>

                      <p>{productNotes}</p>
                      <div style={{ marginTop: 10 }}>
                        <strong>
                          <p>
                            <span style={{ color: "#999999" }}>THC:</span>{" "}
                            {productThc}{" "}
                            <span style={{ color: "#999999" }}>CBD: </span>{" "}
                            {productThc}
                          </p>
                        </strong>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* {tagsArr.map((tag) => (
                          <div style={{ marginTop: 10, marginRight: 8 }}>
                            <button className="btn btn-disable btn-lg">
                              <img
                                className="tag_icon_style"
                                src={tag_icon(tag)}
                                alt="tag"
                              />{" "}
                              {tag}
                            </button>
                          </div>
                        ))} */}
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <strong>
                          <p style={{ fontSize: 12 }}>MAKER</p>
                        </strong>
                        <div
                          className="badge badge-secondary"
                          style={{ marginTop: 8 }}
                        >
                          <strong>
                            <p>{manufacturer}</p>
                          </strong>
                        </div>
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <div style={{ marginTop: 10 }}>
                          <strong>
                            <p style={{ fontSize: 12 }}>PRICE</p>
                          </strong>
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <div style={{ marginTop: 10 }}>
                            <button className="btn btn-disable btn-lg">
                              <i className="fas fa-dollar-sign nav-bar-icon"></i>{" "}
                              {priceRange}
                            </button>
                          </div>
                        </div>

                        {type === "Deals" ? (
                          <div>
                            {this.props.authUser ? (
                              <div style={{ marginTop: 8 }}>
                                <button
                                  className="btn btn-primary btn-lg"
                                  style={{ backgroundColor: "#212121" }}
                                  onClick={() => this.props.addProduct(dealObj)}
                                  data-sku={sku}
                                >
                                  Add to bag
                                </button>
                              </div>
                            ) : (
                              <div />
                            )}
                          </div>
                        ) : (
                          <div>
                            <div
                              className="input-group input-group dropdown-menu-right"
                              style={{ marginTop: 10 }}
                            >
                              <div className="select-container">
                                <select
                                  className="select-css"
                                  value={this.state.city_name}
                                  onChange={(e) =>
                                    this.onStateChange(e, weights)
                                  }
                                >
                                  {_.toArray(cities).map((weight) => (
                                    <option
                                      key={weight.state_code}
                                      value={weight.city_name}
                                    >
                                      {weight.city_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div style={{ marginTop: 10, marginRight: 8 }}>
                                <button className="btn btn-disable btn-lg">
                                  <i className="fas fa-dollar-sign nav-bar-icon"></i>{" "}
                                  {price}
                                </button>
                              </div>

                              {/* {this.props.authUser ? (
                      <div style={{marginTop: 8}}>
                        <button className="btn btn-primary btn-lg" style={{backgroundColor: '#212121'}} onClick={() => this.props.addProduct(productObj)} data-sku={sku}>Add to bag</button>
                      </div>
                    ) : (
                      <div/>
                    )} */}
                            </div>
                          </div>
                        )}
                      </div>

                      {this.props.user.uid === uid ? (
                        <div style={{ marginTop: 10 }}>
                          <Link to={`/edit/${id}`}>
                            <button className="btn btn-disable btn-lg">
                              <i className="fas fa-edit nav-bar-icon"></i> edit
                            </button>
                          </Link>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>

                    <div style={{ marginTop: 10 }}>
                      {this.props.authUser ? (
                        <CommentsView id={this.props.match.params.id} />
                      ) : (
                        <div />
                      )}

                      <CommentsList
                        profileData={this.state.profileData}
                        id={this.props.match.params.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {this.props.authUser ? (
          <FloatCart dispensaryProps={objectState}/>
          ) : (
            <div/>
            )} */}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
  products: state.shelf.products,
});

PublicProfileProductDetail.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { addProduct })(
  PublicProfileProductDetail
);
