import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Col } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import {Helmet} from "react-helmet";
import Layout from '../layout/Layout';
import _ from "lodash";
import List from "./List";
import ProfileSidebar from './ProfileSidebar';
import no_list from '../../assets/images/no_list_profile@2x.png'

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Explore - Okie Weed Finder</title>
    <link rel="canonical" href="https://okieweedfinder.com/explore" />
  </Helmet>
)

class PublicProfile extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      profileData: {}
    };
    
  }
  state = {
    isLoading: false,
    profileData: {}
  };
  
  componentDidMount() {
    const username = this.props.match.params.uid
    this.mounted = true;

      if (this.mounted) {

        this.setState({ isLoading: false });
        
        firebase.database().ref().child("users").orderByChild("username").equalTo(username).once("value", snap  => {
          if (snap.val()) {

            this.setState({ isEmpty: false });

            let url_uid = Object.keys(snap.val())
            firebase.database().ref(`users/${url_uid}`).once("value", snapshot => {
              this.setState({profileData: snapshot.val()})
            })

          } else {
            this.setState({ isEmpty: true });
          }
            this.setState({ isLoading: false });
        })
      }
  }
	
  render() {
      let data = this.state.profileData;
      const { posts } = data;
      const username = this.props.match.params.uid
      const listsArr = _.toArray(posts);

    return (
      <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title> { `${username } | Okie Weed Finder` }</title>
        </Helmet>
        <Grid>
        <div style={{marginTop: 80, height: '100vh'}}>
          <Col md={3} sm={12} xs={12}>
              <ProfileSidebar profileData={this.state.profileData}/>
          </Col>
          
          <Col md={6} sm={12} xs={12}>

            {
              listsArr.length === 0 ? (
                <div style={{display: 'flex', marginBottom: 100, justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{textAlign: 'center'}}>
                    <img src={no_list} alt="no  list" style={{width: '100%'}}/>
                    <p><strong>No posts yet...</strong></p>
                  </div>
                </div>
              ) : (
                  _.reverse(listsArr).map((list) => {
                    return <List key={list.id} {...list} />;
                  })
                )
            }

          </Col>
          <Col md={3} smHidden xsHidden>
            {/* <h6>Trending</h6> */}
          </Col>
        </div>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  // profile: state.profile,
  user: state.user,
  userProps: state.userProps
});

export default compose(connect(mapStateToProps, undefined ))(PublicProfile);