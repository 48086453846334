import { combineReducers } from 'redux';
import shelfReducer from './shelf/reducer';
import cartReducer from './cart/reducer';
import totalReducer from './total/reducer';
import filtersReducer from './filters/reducer';
import sortReducer from './sort/reducer';
import profile from '../reducers/profile';
import filtersText from '../reducers/filtersText';
import session from '../reducers/session';
import authReducer from '../reducers/auth';
import user from '../reducers/user';
import userProps from '../reducers/userProps';
import products from '../reducers/products';
import notifications from '../reducers/notifications';
import recent_news from '../reducers/recent_news';
import posts from '../reducers/posts';
import dispensaries from '../reducers/dispensaries'
import doctors from '../reducers/doctors'
import growers from '../reducers/growers'
import processors from '../reducers/processors'
import testing_labs from '../reducers/testing_labs'
import cities from '../reducers/cities'
import reviews from '../reducers/reviews'

export default combineReducers({
  shelf: shelfReducer,
  cart: cartReducer,
  total: totalReducer,
  filters: filtersReducer,
  sort: sortReducer,
  sessionState: session,
  auth: authReducer,
  dispensaries,
  doctors,
  growers,
  processors,
  testing_labs,
  notifications,
  profile,
  posts,
  cities,
  filtersText,
  user,
  userProps,
  products,
  reviews,
  recent_news
});
