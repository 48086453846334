import React from 'react';
import white_logo from "../../assets/images/logo_white.svg"
import app_store from "../../assets/images/app_store.png"
import google_play from "../../assets/images/google_play_store.png"
import download_app from "../../assets/images/download_app@2x.png"
import { Button, Grid } from "react-bootstrap"
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

const DownloadAppFullWidthCTA = () => {
  return (
    <Grid fluid>
      <div className="download-app-cta-container">
        <div className="container">
          <center>
            <div>
              <div style={{marginTop: 64}}>
                <img src={white_logo} width={380} draggable="false"/>
              </div>
              <div>
                <img src={download_app} style={{width: '100%', maxWidth: 780}} draggable="false"/>
              </div>
              <div style={{marginBottom:32}}>
                <h2 style={{ color: '#FFFFFF'}}>
                  <strong>Dowload The App Now! </strong>
                </h2>
                  <p style={{fontSize: 16, color: '#FFFFFF'}}>We are a small Oklahoma startup building tools for our community</p>
                </div>
              <div style={{marginBottom: 32}}>
                <a href="https://apps.apple.com/us/app/okieweedfinder/id1601327134" target="_blank">
                  <img src={app_store} draggable="false" style={{width: '100%', maxWidth: 265}} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.okieweedfinder.android" target="_blank">
                  <img src={google_play} draggable="false" width="265" />
                </a>
              </div>
            </div>
          </center>
          </div>
      </div>
    </Grid>
  )
}

export default DownloadAppFullWidthCTA;