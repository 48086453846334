import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from 'firebase/compat/app';
import moment from "moment";
import { Link } from "react-router-dom";
import _ from 'lodash';

 class Review extends Component {
  
  startRating(){

  }
  render() {

    const { displayName, review, photoURL, createdAt, rating } = this.props
    
    var star_rating = (rating) => {
      switch (rating) {
        case 1:
          return <span><i className="fas fa-star" style={{marginLeft: "6px", color:'#f44336' }}></i></span>;
        case 2:
          return <span><i className="fas fa-star" style={{marginLeft: "6px", color:'#f44336' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#f44336' }}></i></span>;
        case 3:
          return <span><i className="fas fa-star" style={{marginLeft: "6px", color:'#FBC02D' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#FBC02D' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#FBC02D' }}></i></span>;
        case 4:
          return <span><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i></span>;
        case 5:
          return <span><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i><i className="fas fa-star" style={{marginLeft: "6px", color:'#388E3C' }}></i></span>;
        default:
          return <span> No Rating</span>; 
        }
      }

    return (
      <div className="list-card-style">
      <div className="list-card-comment-style">
      <div className="list-card">
        <Link to={`/u/${displayName}`}>
        <div className="list-profile-avatar-wrapper" style={{paddingLeft: 16}}>
            <img src={photoURL} className="comment-profile-avatar" alt="profile img" />
        </div>
        </Link>
        <div className="comment-detail-wrapper">
          <div>
          <Link to={`/u/${displayName}`}>
          <p className="comment-display-name">{displayName}</p>
          </Link>
          <div className="comment-body-text-container">
            <p className="comment-text">{star_rating(rating)}</p>
            <p className="comment-text">{review}</p>
            <p className="timestamp">{moment(createdAt).fromNow()}</p>
          </div>
          </div>
        </div>
            {this._removeCommentButton()}
        </div>
      </div>
      </div>
    )
  }
  _removeCommentButton() {
    const { uid } = this.props.userProps
    const reviewerUserUid = _.toString(uid);
    const { id, reviewId} = this.props
    return this.props.uid === reviewerUserUid ? (
      <div className="remove-comment-btn">
        <div style={{ flexDirection: "column" }}>
          <div>
          <button onClick={() => { firebase.database().ref(`reviews/${id}/${reviewId}`).remove() }}>
            <i className="fa fa-trash remove-btn-icon" aria-hidden="true"></i>
          </button>
          </div>
        </div>
      </div>
    ) : (<div className="remove-btn-none"></div>);
  }

}

const mapStateToProps = state => ({
  userProps: state.userProps
});


export default connect(mapStateToProps,undefined)(Review);
