import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as routes from "../../constants/routes";
import firebase from 'firebase/compat/app';
import { auth } from '../../firebase';
import { startAddPost } from "../../actions/posts"
import FloatCart from '../FloatCart'
import PostForm from "../posts/PostForm"
import logo from "../../assets/images/logo_white.svg";


class Navigation extends Component {
  onSubmit = (post) => {
    this.props.startAddPost(post);
  };
  render() {
    return (
      <div>{this.props.authUser ? <NavigationAuth startAddPost={this.props.startAddPost} onSubmit={this.onSubmit} user={this.props.user} /> : <NavigationNonAuth />}</div>
    )
  }
}

class NavigationAuth extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.state = {
      show: false,
      photoURL: null, 
      displayName: '',      
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user){
      this.setState({ photoURL: user.photoURL, displayName: user.displayName })
      } else {
        this.setState({ photoURL: null })
      }
    })
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleHide() {
    this.setState({ show: false });
  }
  onSubmit = (post) => {
    
    const uid = firebase.auth().currentUser.uid;
    const displayName = firebase.auth().currentUser.displayName;

    (function () {

      const followersRef = firebase.database().ref(`/followers/${uid}`);

      var followers_ = {};

      
      followersRef.on("value", snapshot => {
        var followers = Object.keys(snapshot.val());
        followers_ = snapshot.val();
        followers = Object.keys(followers_);
        var fanoutObj = {};
        fanoutObj[`users/${uid}/posts/` + post.id] = post;
        followers.forEach(key => (fanoutObj["/timeline/" + key + "/" + post.id] = post));
        fanoutObj["posts/" + post.id] = post;
        firebase.database().ref().update(fanoutObj);
      });
    })();
    this.setState({show: false})
  }

  render() {
    const { admin, city, slug, superadmin, displayName } = this.props.user
    return (
        <div>

      <nav className="navbar navbar-default">
        <div className="container-fluid">

          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          
          <div id="navbar" className="navbar-collapse collapse" style={{marginRight: 60}}>
          
            <ul className="nav navbar-nav navbar-right">
              <li><Link to={routes.EXPLORE} style={{fontWeight: 'bold'}}><i className="fas fa-search" style={{marginRight: "6px"}}></i></Link></li>
              <li><Link to={routes.DISPENSARIES} style={{fontWeight: 'bold', color: '#c1c1c1'}}>Dispensaries</Link></li>
              <li><Link to={routes.BLOG} style={{fontWeight: 'bold', color: '#c1c1c1'}}>Blog</Link></li>

              <li className="dropdown">
                <Link href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style={{fontWeight: 'bold', color: '#c1c1c1'}} >Medical Marijuana 101 <i className="fas fa-caret-down"></i></Link>
                <ul className="dropdown-menu">
                  <li><Link to={routes.MEDICAL_MARIJUANA}>Marijuana 101</Link></li>
                  <li><Link to={routes.AVAILABLE_FORMS}>Available Forms</Link></li>
                </ul>
              </li>
              <li><div className="btn add-new-post-btn" style={{marginTop: 12}} onClick={this.handleShow}><strong>New Post</strong> <i className="fas fa-pencil-alt" style={{marginLeft: "6px"}}></i></div></li>
              <li className="dropdown">
                <div className="dropdown profile-avatar-container">
                  <div className="dropdown-toggle nav-profile-img-wrapper" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    <img src={this.state.photoURL} className="nav-profile-img" alt="user avatar" />
                  </div>
                  <ul className="dropdown-menu">
                    <li><Link to={routes.DASHBOARD}>Dashboard</Link></li>
                    {admin ? (
                      <>
                        <li role="separator" className="divider"></li>
                        <li><Link to={`dispensaries/${city}/${slug}`}>Store</Link></li>
                        <li><Link to={routes.PRODUCT_NEW}>Add Product</Link></li>
                      </>
                    ) : (
                    <></>  
                    )}
                    {superadmin ? (
                      <>
                        <li role="separator" className="divider"></li>
                        <li><Link to={routes.BUSINESS_NEW}>Add Business</Link></li>
                      </>
                    ) : (
                    <></>  
                    )}
                    <li role="separator" className="divider"></li>
                    <li><Link to={`/u/${displayName}`}>Profile</Link></li>
                    <li role="separator" className="divider"></li>
                    <li className="dropdown-header">Settings</li>
                    <li><Link to={routes.ACCOUNT}>Account</Link></li>
                    <li><Link to="/" onClick={auth.doSignOut}>Sign Out</Link></li>
                  </ul>
                  <FloatCart profile={this.state.profileData}/>  
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <Modal {...this.props} show={this.state.show} onHide={this.handleHide}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
              Share new post!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{backgroundColor: '#f5f5f7', width: '100%'}}>
            <PostForm onSubmit={this.onSubmit} />
          </Modal.Body>
        </Modal>
        </div>
      </div>
    );
  }
}

class NavigationNonAuth extends Component {
  
 render(){
   return(
     <div style={{flexDirection:'row', justifyContent: 'center'}}>
     <div className="container-fluid">
       <nav className="navbar navbar-default">


          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div style={{marginLeft:16}}>
              <Link to="/">
                <img src={logo} className="app-logo-header" alt="okie weed finder" draggable="false" />
              </Link>
            </div>
          </div>
          
          <div id="navbar" className="navbar-collapse collapse">
            <ul className="nav navbar-nav navbar-right">
              <li><Link to={routes.EXPLORE} style={{fontWeight: 'bold'}}><i className="fas fa-search" style={{marginRight: "6px"}}></i></Link></li>
              <li><Link to={routes.DISPENSARIES} style={{fontWeight: 'bold', color: '#c1c1c1'}}>Dispensaries</Link></li>
              <li><Link to={routes.BLOG} style={{fontWeight: 'bold', color: '#c1c1c1'}}>Blog</Link></li>

              <li className="dropdown">
                <Link className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style={{fontWeight: 'bold', color: '#c1c1c1'}} >Medical Marijuana 101 <i className="fas fa-caret-down"></i></Link>
                <ul className="dropdown-menu">
                  <li><Link to={routes.MEDICAL_MARIJUANA}>Marijuana 101</Link></li>
                  <li><Link to={routes.AVAILABLE_FORMS}>Available Forms</Link></li>
                </ul>
              </li>
              
              <li><Link to={routes.SIGN_IN} style={{fontWeight: 'bold', color: '#c1c1c1'}}>Login</Link></li>
                            
                <Link to={routes.SIGN_UP}>
                  <Button className="btn-lg add-new-post-btn" style={{marginTop: 8, marginRight: 8}}><strong style={{color: '#FFF'}}>Sign Up</strong></Button>
                </Link>

            </ul>
          </div>
        </nav>
      </div>
     </div>
   )
 }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  userProps: state.userProps,
  user: state.user
});

export default connect(mapStateToProps, undefined)(Navigation);