import database from "../firebase/firebase";
import firebase from 'firebase/compat/app';
import _ from 'lodash';

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const SET_LISTS = "SET_LISTS";
export const FETCH_TIMELINE_POSTS = "FETCH_TIMELINE_POSTS";
export const FETCH_PROFILE_LISTS = "FETCH_PROFILE_LISTS";
export const FETCH_MORE_LISTS = "FETCH_MORE_LISTS";
export const ADD_POST = "ADD_POST";
export const REMOVE_POST = "REMOVE_POST";
export const EDIT_LIST = 'EDIT_LIST';
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";


/**
 |--------------------------------------------------
 | Fetch lists for signed in user
 |--------------------------------------------------
 */


// SET LISTS
export const setPosts = (posts) => ({
  type: FETCH_TIMELINE_POSTS,
  posts
});

export const fetchTimeline = (user) => {
  return (dispatch, getState) => {
    // const user = getState().auth.user;
    return database.ref(`timeline/${user.uid}`).once("value").then(snapshot => {
      const posts = [];

      snapshot.forEach(childSnapshot => {
        posts.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        });
      });
      dispatch(setPosts( _.reverse(posts)));
    })
  };
}

/**
 |--------------------------------------------------
 | Fetch lists for signed in user
 |--------------------------------------------------
 */


// GETS FRIEND OR PUBLIC PROFILE BY PASSING USERS UID // USED by publicProfile
export function fetchUserProfileLists(uid) {
  return dispatch => {
    firebase.database().ref(`users/${uid}/`).on("value", snapshot => {
      let payload = snapshot.val();
      dispatch({
        type: FETCH_PROFILE_LISTS,
        payload
      });
    });
  };
}



// ADD_POST
export const addPost = post => ({
  type: ADD_POST,
  post
});

export const startAddPost = (postData = {}) => {
  const { currentUser } = firebase.auth();

  return dispatch => {
    const newPostKey = firebase.database().ref().child("posts").push().key;

    const {
      description = "",
      img_url = "",
      title = "",
      createdAt = 0,
      likes = 0,
      likeCounts = 0,
      photoURL = "",
      type = "u",
      displayName = firebase.auth().currentUser.displayName,
      uid = firebase.auth().currentUser.uid,
      id = newPostKey
    } = postData;

    const post = { photoURL, likeCounts, description, img_url, type, displayName, title, id, likes, uid, createdAt };

    let updates = {};

    updates[`/posts/${newPostKey}`] = post;
    updates[`/users/${currentUser.uid}/posts/${newPostKey}`] = post;
    updates[`/timeline/${currentUser.uid}/${newPostKey}`] = post;

    return database.ref().update(updates).then(ref => {
        dispatch(
          addPost({
            id: ref,
            ...post
          })
        );
      });
  };
};

// ADD_ITEM
export const addItem = (id, item) => ({
  type: "ADD_ITEM",
  item,
  id
});

export const startAddItem = (id, itemData = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch, getState) => {
    const newItemId = firebase.database().ref(`/users/${currentUser.uid}/lists/`).child("items").push().key;

    const {
      content = "",
      itemId = newItemId,
      createdAt = 0,
      listId = id
    } = itemData;

    const item = { content, itemId, listId, createdAt };

    database
      .ref(`users/${currentUser.uid}/lists/${id}/items`)
      .update(item)
      .then(ref => {
        dispatch(
          addItem({
            id: ref.key,
            ...item
          })
        );
      });
  };
};

// REMOVE_POST
export const removePost = ({ id } = {}) => ({
  type: REMOVE_POST,
  id
});

export const startRemovePost = ({ id } = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch) => {
    return database.ref(`users/${currentUser.uid}/posts/${id}`).remove().then(() => {
      dispatch(removePost({ id }));
    }).then(() => {
      const followersRef = database.ref(`followers/${currentUser.uid}`)
      followersRef.on("value", snapshot => {
        const followers = Object.keys(snapshot.val())
        followers.forEach(key => database.ref(`/timeline/${key}/${id}`).remove().then(() => {
          dispatch(removePost({ id }));
        }))
      })
    })

  }
}

// EDIT_LIST
export const editList = (id, updates) => ({
  type: EDIT_LIST,
  id,
  updates
});

export const startEditList = (id, updates) => {
  return (dispatch,  getState) => {
    const user = getState().auth.user;
    return database.ref(`followers/${user.uid}`).once("value", snapshot => {
      // Read followers keys and turn into hash
      const followers = Object.keys(snapshot.val())
      // Create object to send updated to
      let updates_ = {}
      // Loop through followers and send updates
      followers.forEach(key => updates_[`/timeline/${key}/${id}`] = updates)
      // Sendd updates to Users own Lists
      updates_[`users/${user.displayName}/lists/${id}`] = updates
      // Send updates to Lists node
      updates_[`/lists/${id}`] = updates
      
      // updates_[`/timeline/${user.uid}/${id}`] = updates
      return database.ref().update(updates_).then(() => {
        dispatch(editList(id, updates));
      })

    })
  
  };

};
