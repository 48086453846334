import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Col, FormGroup, FormControl, Button } from "react-bootstrap";
// import UserCard from "./UserCard";
import Layout from "../layout/Layout";
import firebase from "firebase/compat/app";
import _ from "lodash";
import { Helmet } from "react-helmet";
import LoadingPage from "../utilities/LoadingPage";
import SidebarContentAuth from "../dashboard/SidebarContentAuth";
import moment from "moment";
import ReactPlayer from "react-player";
const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Explore - okieweedfinder</title>
    <link rel="canonical" href="https://okieweedfinder.com/explore" />
  </Helmet>
);

class Discovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      user_search: {},
      counter: 50,
    };
  }

  getCommunityPosts = async () => {
    try {
      const db = firebase.firestore().collection("postsDB");
      let communityArr = await db.doc("oklahoma").collection("posts").get();

      let productsArrayTemp = [];

      communityArr.forEach((doc) => {
        let dispoData = doc.data();
        productsArrayTemp.push(dispoData);
      });

      // this.setState({ profileProducts: productsArrayTemp });
      this.setState({ isLoading: false });

      this.setState({ posts: productsArrayTemp });
    } catch (error) {
      console.log(error, "error");
    }
  };
  componentDidMount() {
    this.getCommunityPosts();
  }
  // findUser = () => {
  //   const displayName = this.state.displayName;

  //   if (displayName) {
  //     firebase
  //       .database()
  //       .ref()
  //       .child("users")
  //       .orderByChild("username")
  //       .startAt(displayName)
  //       .on("value", (snapshot) => {
  //         if (snapshot.exists()) {
  //           let user_search = snapshot.val();
  //           this.setState({ user_search });
  //           this.setState({ isEmpty: false });
  //         } else {
  //           this.setState({ usernameError: "Username is available!" });
  //         }
  //       });
  //   }
  // };

  // onDisplayNameChange = (event) => {
  //   const displayName = event.target.value.toLowerCase().trim();
  //   displayName.replace(/\s/g, "");
  //   this.setState({ displayName });
  //   if (displayName === "") {
  //     this.setState({ user_search: {} });
  //   }
  // };

  render() {
    // const user_search_arr = _.toArray(this.state.user_search);
    const postsArr = _.reverse(_.toArray(this.state.posts));
    return (
      <Layout>
        <HeaderTags />
        <Grid fluid style={{ marginTop: 32 }}>
          <div>
            <Col md={2} lg={2} smHidden xsHidden>
              <SidebarContentAuth />
            </Col>
            <Col md={10} sm={12} xs={12} style={{ marginTop: 12 }}>
              <h1>Explore</h1>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <div style={{ width: "100%", marginTop: 12 }}>
                  <FormGroup bsSize="lg">
                    <FormControl
                      style={{ width: "100%", backgroundColor: "#e1e1e1" }}
                      type="text"
                      placeholder={"Search users"}
                      value={this.state.displayName}
                      onChange={this.onDisplayNameChange}
                      onKeyUp={this.findUser}
                    />
                  </FormGroup>
                </div>
              </div> */}
            </Col>
          </div>
          {/* <div
            style={{
              position: "absolute",
              width: "100%",
              backgroundColor: "#FFFFFF",
              borderRadius: 12,
              zIndex: 3,
            }}
          >
            {user_search_arr.map((list) => {
              return <UserCard key={list.id} {...list} />;
            })}
          </div> */}
          {postsArr.length === 0 ? (
            <LoadingPage />
          ) : (
            postsArr.map((profile) => (
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                {profile.video ? (
                  <a
                    href={`/${profile.displayName}/${profile.id}`}
                    key={profile.id}
                  >
                    <div className="list-card-style list-card-height">
                      {profile.img_url ? (
                        <div
                          style={{
                            boder: "none",
                            minWidth: 200,
                            width: "100%",
                            maxHeight: 480,
                            borderTopRightRadius: 22,
                            borderTopLeftRadius: 22,
                            overflow: "hidden",
                          }}
                        >
                          <ReactPlayer
                            playing={false}
                            // onReady={() => (profile[0] ? true : false)}
                            paused={true}
                            className="react-player"
                            url={profile.video_url}
                            width="100%"
                            height="100%"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            boder: "none",
                            width: "100%",
                            height: 480,
                            borderTopRightRadius: 22,
                            borderTopLeftRadius: 22,
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={profile.photoURL}
                            alt="post"
                            className="card-img-container"
                          />
                        </div>
                      )}

                      <div className="list-card">
                        <div
                          className="list-profile-avatar-wrapper"
                          style={{
                            paddingTop: 16,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 8,
                          }}
                        >
                          <img
                            src={profile.photoURL}
                            className="list-profile-avatar"
                            alt="profile img"
                          />
                        </div>

                        <div className="list-detail-wrapper">
                          <div>
                            <p className="list-user-name">
                              {profile.displayName}
                            </p>
                            <p className="list-title">{profile.title}</p>
                            <p className="timestamp">
                              {moment(profile.createdAt).fromNow()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ) : (
                  <a
                    href={`/u/${profile.displayName}/p/${profile.id}`}
                    key={profile.id}
                  >
                    <div className="list-card-style list-card-height">
                      {profile.img_url ? (
                        <div
                          style={{
                            boder: "none",
                            minWidth: 200,
                            width: "100%",
                            maxHeight: 480,
                            borderTopRightRadius: 22,
                            borderTopLeftRadius: 22,
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={profile.img_url}
                            alt="post"
                            className="card-img-container"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            boder: "none",
                            width: "100%",
                            height: 480,
                            borderTopRightRadius: 22,
                            borderTopLeftRadius: 22,
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={profile.photoURL}
                            alt="post"
                            className="card-img-container"
                          />
                        </div>
                      )}

                      <div className="list-card">
                        <div
                          className="list-profile-avatar-wrapper"
                          style={{
                            paddingTop: 16,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 8,
                          }}
                        >
                          <img
                            src={profile.photoURL}
                            className="list-profile-avatar"
                            alt="profile img"
                          />
                        </div>

                        <div className="list-detail-wrapper">
                          <div>
                            <p className="list-user-name">
                              {profile.displayName}
                            </p>
                            <p className="list-title">{profile.title}</p>
                            <p className="timestamp">
                              {moment(profile.createdAt).fromNow()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
              </div>
            ))
          )}
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps, undefined)(Discovery);
