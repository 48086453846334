import React, { Component } from 'react';
import _ from 'lodash'
import { Link } from "react-router-dom";
import { Grid, Col } from 'react-bootstrap'
import LoadingPage from '../utilities/LoadingPage'
class FeaturedProfiles extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount(){  
    this.setState({ isLoading: false });
  }
  render(){
    const profiles = _.toArray(this.props)
    const { isLoading } = this.state;
    
    return(
      <div>
        {isLoading && <LoadingPage/>}

      <Grid  style={{marginTop: 32}}>
        <Col md={12} className="container">
          <center>
            <h1 style={{ fontSize: 18,  fontWeight: '900', textTransform: 'uppercase', lineHeight: 0.5, color: '#FFFFFF'}}> #Trending Dispensaries</h1>
          </center>
        </Col>

      <Col md={12} style={{ marginTop: 32, marginBottom: 64 }}>
        {profiles.map((profile, index) => (
            <Col md={4} sm={12} xs={12} key={index}>
              <Link to={`/dispensaries/${profile.city}/${profile.slug}/`}>
                <div className="list-card-style">
                <div style={{display: 'flex', flexDirection: 'row'}}>

                  <div style={{overflow: 'hidden', borderRadiusTopleft: 16, borderRadiusTopRight: 16, position: 'relative', width: 80, height: 80}}>
                    <div style={{borderRadius: 16, overflow: 'hidden'}}>
                      <img src={profile.photoURL} alt="profile avatar" width={'100%'} />
                    </div>
                  </div>

                  <div className="list-card" style={{flexDirection: 'row'}}>
                    <div style={{width: '100%'}}>
                        <div className="flex-parent">
                          <p style={{fontSize: 20, fontWeight: '900'}} className="long-and-truncated">{profile.displayName}</p>
                        </div>
                        <p><i className="fas fa-map-marker map-city-icon"></i> {profile.city}</p>
                    </div>
                  </div>

                </div>

                </div>
              </Link>
            </Col>
          )
        )}
      </Col>

      </Grid> 
    </div>
    )
  }
}


export default FeaturedProfiles;