import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Layout from '../layout/Layout';
import firebase from 'firebase/compat/app';
import { auth, db } from '../../firebase';
import { Grid, Col, Row, FormGroup, FormControl, Button, Checkbox } from "react-bootstrap";
import * as routes from '../../constants/routes';
const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/tierly-712dc.appspot.com/o/profile_images%2Fdefault_avatar.jpg?alt=media&token=2f96b498-bedf-410e-8558-08c3983ac0ee';


const BusinessSignup = () => 
  <Link to={routes.SIGN_UP_BUSINESS}>
    <div className="sign-up-cta">
      <div>
        <i style={{fontSize: 18, fontWeight: 900, color: '#FFFFFF'}} className="fas fa-star"></i>
      </div>
      <div style={{marginLeft: 8}}>
        <p style={{fontWeight: 900, fontSize: 18, color: '#FFFFFF'}}>Business Sign up here!</p>
      </div>
    </div>
  </Link>


const SignUpPage = ({ history }) =>
    <Layout>
    <Grid style={{marginTop: 90, padding: 12, height: '100vh'}}>
      
        <Col md={3} className="login-modal"></Col>
        
        <Col md={6}>
          <div className="list-card-style" style={{padding: 16}}>
            <h3 className="login-text">Create account</h3>
            <SignUpForm history={history} />
          </div>

          <BusinessSignup/>
          
        </Col>
      
      
      <Col md={3} className="login-modal"></Col>
      

      
      <Col md={3} className="login-modal"></Col>

    </Grid>
  </Layout>

const INITIAL_STATE = {
  name: '',
  password: '',
  photoURL: '',
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ...INITIAL_STATE,
      displayName: '',
      usernames: [],
      usernameError: '',
      cityError: '',
      emailError: '',
      overageErr: '',
      passwordError: '',
      overage: false,
      error: '',
      email: '',
      city: '',
    };
  }
  onSubmit = (event) => {
    const {
      email,
      name,
      displayName,
      password,
      photoURL,
      city
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doCreateUserWithEmailAndPassword(email, password).then(authUser => {
      
      db.doCreateUser(authUser.uid, displayName.toLowerCase(), name, email, city, photoURL)
      .then(() => {

        firebase.auth().currentUser.updateProfile({
          displayName,
          photoURL: defaultImage
        })
        
        const uid = firebase.auth().currentUser.uid

        firebase.database().ref(`users/${uid}`).set({
          admin: false,
          bio: '',
          username: displayName,
          displayName,
          email,
          following: 0,
          followers: 0,
          uid,
          orders: 0,
          phonenumber: '',
          photoURL: defaultImage,
          products: 0,
          name: '',
          website: '',
          slug: displayName,
          city: '',
          type: 'patient',
          listing_premium: false,
          registered_patient: false,
          reviews: '',
          verified: false,
          tokens: {
            like_post_notifications: false
          },
        })
        
        
      }).then(() => {
        const uid = firebase.auth().currentUser.uid
        const followerBodySetup = true;

        let updates = {}

        updates[`following/${uid}/` + uid] = followerBodySetup
        updates[`followers/${uid}/` + uid] = followerBodySetup
        updates[`usernames/${displayName}/`] = uid

        return firebase.database().ref().update(updates)
      }).then(() => {

        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(routes.TIMELINE);

      }).catch(error => { this.setState({ error: error.message });})
    }).catch(error => { this.setState({ error: error.message });})

    event.preventDefault();
  }


  onEmailChange = (event) => {
    const email = event.target.value;
    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      this.setState({emailError: 'Please provide valid email'})
    } else {

      this.setState({emailError: ''})
    }
    this.setState({email})
  } 
  
  onCityChange = (event) => {
    const city = event.target.value;
    if (city && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(city)) {
      this.setState({cityError: 'Please provide valid city'})
    } else {

      this.setState({cityError: ''})
    }
    this.setState({city})
  } 

  displayNameChecker = () => {

    const displayName = this.state.displayName
    
    if (displayName){
      firebase.database().ref().child("users").orderByChild("displayName").equalTo(displayName).on("value", (snapshot) => {
        if (snapshot.exists()) {
  
            this.setState({usernameError: 'Sorry username is taken =('})
  
          }else{
            this.setState({usernameError: 'Username is available!'})
          }
        });
    }
    this.setState({usernameError: ''})
  }

  displayNameAvailable = () => {    
    this.setState({usernameError: ''})
  }

  onDisplayNameChange = (event) => {
    const displayName = event.target.value.toLowerCase().trim()
    displayName.replace(/\s/g, '')
    this.displayNameChecker(displayName)
   if (displayName && displayName.length < 3) {
      this.setState({usernameError: 'Minimum of 3 characters'})
    } else if (displayName && displayName.length > 22) {
      this.setState({usernameError: 'Maximum of 22 characters'})
    } else if (displayName && !/^[a-zA-Z0-9_]*$/i.test(displayName)) {
      this.setState({usernameError: 'Please provide username with no special characters or spaces!'})
    } else {
      this.setState({usernameError: ''})
      this.setState({displayName})
    }
    this.setState({displayName})
  }

  onPasswordChange = (event) => {
    const password = event.target.value;
    if (password && password.length <= 6) {
      this.setState({passwordError: 'Minimum 6 characters'})
    } else if (password && password.length > 6){
      this.setState({passwordError: ''})
    }
    this.setState({password})
  }

   // Product Hidden
   onOverAgeChange = () => {
    if(this.state.overage === false){
      this.setState(() => ({ overageErr: 'Sorry, must be 21 or older'}))
    }
    this.setState(() => ({ overage: true, overageErr: ''}))
  }
    

  render() {
    const { email, displayName, password, error, usernameError, city, cityError, overage } = this.state;

    const isInvalid = displayName === '' || password === '' || usernameError !== '' || city === '' || overage !== true;

    return (
      <FormGroup bsSize="large">
        <form onSubmit={this.onSubmit}>

          <FormGroup bsSize="large">
            <FormControl
              style={{backgroundColor: '#EEE'}}
              value={displayName}
              onChange={this.onDisplayNameChange}
              // onChangeText={(displayName) => this.setState({displayName: displayName.replace(/\s/g, '')})}
              onBlur={this.displayNameChecker}
              type="text"
              placeholder="Username"
            />
            {this.state.usernameError && <p style={{marginTop:4}}>{this.state.usernameError}</p>}
          </FormGroup>

          
            <FormGroup bsSize="large">
              <FormControl
                style={{backgroundColor: '#EEE'}}
                value={email}
                onChange={this.onEmailChange}
                onBlur={this.displayNameAvailable}
                type="text"
                placeholder="Email Address"
              />
              {error && <p>{error}</p>}
              {this.state.emailError && <p>{this.state.emailError}</p>}
              {error ? (<p>{error.message}</p>) : (<p></p>)}
              {this.state.error && <p>{this.state.emailError}</p>}
            </FormGroup>

            <FormGroup bsSize="large">
              <FormControl
                style={{backgroundColor: '#EEE'}}
                value={city}
                onChange={this.onCityChange}
                type="text"
                placeholder="City"
              />

              {error && <p>{error}</p>}
              {this.state.cityError && <p>{this.state.cityError}</p>}
              {error ? (<p>{error.message}</p>) : (<p></p>)}
              {this.state.error && <p>{this.state.cityError}</p>}
            </FormGroup>
          

          <FormGroup bsSize="large">
            <FormControl
              style={{backgroundColor: '#EEE'}}
              value={password}
              onChange={this.onPasswordChange}
              type="password"
              placeholder="Password"
              secure
            />
            {this.state.passwordError && <p>{this.state.passwordError}</p>}
          </FormGroup>

          <div style={{marginBottom: 16}}>
            By clicking Sign Up, you agree to our <Link to={routes.TERMS} style={{color: '#32bf25'}}>Terms of Service</Link>, <Link to={routes.PRIVACY} style={{color: '#32bf25'}}>Privacy Policy</Link> and Cookies Policy. You may receive SMS Notifications from us and can opt out any time.
          </div>
          
          <div>
            <div style={{ marginBottom: 16, marginTop: 16, display:'flex', flexDirection: 'row',}}>
              <div className="product_tag_container">
                <div>
                  <Checkbox 
                    onClick={() => this.onOverAgeChange()}/></div>
                    <div><p>I'm over 21 years old (Required)</p></div>
              </div>
            </div>
            {this.state.overageErr && <p style={{marginTop:4}}>{this.state.overageErr}</p>}
          </div>

          <Button bsStyle="primary" bsSize="large" className="btn-blank request-invite_btn" disabled={isInvalid} type="submit">
            Sign Up
          </Button>
          {this.state.error && <p>{this.state.error}</p>}

        </form>
      </FormGroup>
    );
  }
}

const SignUpLink = () =>
  <p>
    Don't have an account?
    {' '}
    <Link to={routes.SIGN_UP}>Request Access</Link>
  </p>

export default withRouter(SignUpPage);

export {
  SignUpForm,
  SignUpLink,
};