const K_SIZE = 200;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  // width: K_SIZE,
  height: 60,
  // left: -K_SIZE / 2,
  // top: -K_SIZE / 2,
  backgroundColor: '#FFFFFF',
  shadowColor: "#000",
  shadowOffset: {
	  width: 4,
	  height: 6,
  },
  shadowOpacity: 0.50,
  shadowRadius: 8.30,
  elevation: 13,
  color: '#212121',
  cursor: 'pointer',
  borderRadius: 8,
  zIndex: 100
};



const smallCard = {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  padding: 4,
  alignItems: 'center'
};

export {greatPlaceStyle, smallCard, K_SIZE};
